




















import { Component, Prop, Vue } from "vue-property-decorator";
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { IDNamePairModel } from '@/models/Common';
import BackButton from '@/components/BackButton.vue';

@Component({
    components: { BackButton }
})
export default class SupportTicketSection extends Vue {
    @Prop() ticketReasonList: IDNamePairModel<number>[];

    TicketReason: number = null;
    TicketMessage: string = null;

    get CanSubmit() {
        return this.TicketReason != null && this.TicketMessage != null && this.TicketMessage.length > 0;
    }

    SubmitTicket(): void {
        Ajax.POST("/Help/SubmitSupportTicket",
            { TicketReason: this.TicketReason, TicketMessage: this.TicketMessage },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.$modal.show('messageModal', { Title: 'Ticket Submitted', Message: "Your ticket has been submitted and will be reviewed shortly.", CanClickToClose: false, DisplayOkayButton: true });

                    this.TicketReason = null;
                    this.TicketMessage = null;
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }
}
