import Vue from 'vue'
import Vuex from 'vuex'
import { ConfigDataModel, IDNamePairModel, LoginDataModel, LoginResponseModel, PersonModel, ProductAlertModel, ProfileModel, UserDataModel } from '@/models/Common';
import { ethnicityList, raceList, stateList } from '@/models/Config';
import { ProductTypeEnum } from '@/models/Enums';
import { CommunicationPreferencesModel } from '../models/Preferences';
import { TestEntryConfigModel, TestEntryDataModel } from '../models/TestEntry';
import { VaccineCardModel } from '../models/VaccineCards';

Vue.use(Vuex)

enum LocalStorageKeys {
    AuthToken = "AuthToken",
}

export class AppStateModel {
    UserData: UserDataModel;
    TestEntryData: TestEntryDataModel;
    AuthToken: string;
    ReCaptchaKey: string;
    StateList: string[];
    EthnicityList: IDNamePairModel<string>[];
    RaceList: IDNamePairModel<string>[];
    DataSymbolBarcodeReaderKey: string;
    DefaultEmailAddress: string;
    DefaultSMSNumber: string;
    SessionWarningTime: number;
    SessionResetTime: number;
    SupportPhoneNumber: string;
    SupportEmailAddress: string;
    ShowRefreshModal: boolean;
    ProcessingErrorMessage: string;
    LoadingStack: number;
    ReadiPassURL: string;
}

export default new Vuex.Store({
    state: {
        UserData: null,
        TestEntryData: null,
        AuthToken: window.localStorage.getItem(LocalStorageKeys.AuthToken), //Initial state grab from local storage
        DataSymbolBarcodeReaderKey: null,
        ReCaptchaKey: null,
        StateList: stateList,
        EthnicityList: ethnicityList,
        RaceList: raceList,
        SessionWarningTime: null,
        SessionResetTime: null,
        SupportPhoneNumber: null,
        SupportEmailAddress: null,
        ShowRefreshModal: false, //Default to false for showing pwa refresh modal
        ProcessingErrorMessage: "There was an issue processing your request.",
        LoadingStack: 0,
        ReadiPassURL: null
    } as AppStateModel,

    mutations: {
        SetIsLoading(state: AppStateModel, isLoading: boolean) {
            if (isLoading) {
                state.LoadingStack++;
            } else {
                state.LoadingStack--;
            }

            if (state.LoadingStack < 0) {
                state.LoadingStack = 0;
            }
        },
        SetShowRefreshModal(state: AppStateModel, showRefreshModal: boolean) {
            state.ShowRefreshModal = showRefreshModal;
        },
        SetConfig(state: AppStateModel, configData: ConfigDataModel) {
            state.DataSymbolBarcodeReaderKey = configData.DataSymbolBarcodeReaderKey;
            state.ReCaptchaKey = configData.ReCaptchaKey;
            state.SessionWarningTime = configData.SessionWarningTimeout;
            state.SessionResetTime = configData.SessionTerminateTimeout;
            state.SupportPhoneNumber = configData.SupportPhoneNumber;
            state.SupportEmailAddress = configData.SupportEmailAddress;
            state.DefaultEmailAddress = configData.DefaultEmailAddress;
            state.DefaultSMSNumber = configData.DefaultSMSNumber;
            state.ReadiPassURL = configData.ReadiPassURL;
        },
        ClearPatientLinkData(state: AppStateModel) {
            state.UserData.DependentFirstName = null;
        },
        Login(state: AppStateModel, loginData: LoginDataModel): void {
            state.UserData = new UserDataModel(loginData.LoginData);
            state.AuthToken = loginData.AuthToken;
            window.localStorage.setItem(LocalStorageKeys.AuthToken, loginData.AuthToken);
        },
        Logout(state: AppStateModel): void {
            state.UserData = null;
            state.AuthToken = null;
            window.localStorage.removeItem(LocalStorageKeys.AuthToken);
        },
        RefreshAuthToken(state: AppStateModel, authToken: string): void {
            state.AuthToken = authToken;
            window.localStorage.setItem(LocalStorageKeys.AuthToken, authToken);
        },
        LoadTestEntryData(state: AppStateModel, configData: TestEntryConfigModel) {
            state.TestEntryData = new TestEntryDataModel(configData);
        },
        ClearTestEntryData(state: AppStateModel): void {
            state.TestEntryData = null;
        },
        ClearTOS(state: AppStateModel): void {
            state.UserData.TermsOfServiceList = [];
        },
        SetCommunicationPreferences(state: AppStateModel, communicationPrefs: CommunicationPreferencesModel): void {
            state.UserData.CommunicationPreferences = communicationPrefs;
        },
        SetCellPhone(state: AppStateModel, cellPhoneNumber: string): void {
            state.UserData.MembershipPerson.Person.CellPhone = cellPhoneNumber;
        },
        SaveProfile(state: AppStateModel, profile: ProfileModel): void {
            if (state.UserData != null && state.UserData.ProfileList != null) {
                let profileIndex = state.UserData.ProfileList.findIndex(p => { return p.Person.PersonID == profile.Person.PersonID; });
                if (profileIndex == -1) {
                    state.UserData.ProfileList.push(profile);
                } else {
                    state.UserData.ProfileList.splice(profileIndex, 1, profile);
                }
            }
        },
        RemoveProfile(state: AppStateModel, personID: number): void {
            if (state.UserData != null && state.UserData.ProfileList != null) {
                let profileIndex = state.UserData.ProfileList.findIndex(p => { return p.Person.PersonID == personID; });
                if (profileIndex != -1) {
                    state.UserData.ProfileList.splice(profileIndex, 1);
                }
            }
        },
        ReduceProductAlertCount(state: AppStateModel, productAlertUpdate: ProductAlertModel): void {
            let productAlertMeta = state.UserData.ProductAlerts.find(pa => { return pa.ProductType == productAlertUpdate.ProductType; });

            productAlertMeta.Count -= productAlertUpdate.Count;

            if (productAlertMeta.Count < 0) {
                productAlertMeta.Count = 0;
            }
        },
        LoadVaccineCards(state: AppStateModel, vaccineCardList: VaccineCardModel[]): void {
            //Set the initial list of vaccine cards
            state.UserData.VaccineCardList = vaccineCardList;
            state.UserData.HasLoadedVaccineCardList = true;
        },
        RemoveVaccineCard(state: AppStateModel, vaccineCardID: number): void {
            let vaccineCardIndex = state.UserData.VaccineCardList.findIndex(vc => { return vc.VaccineCardID == vaccineCardID; });

            if (vaccineCardIndex > -1) {
                state.UserData.VaccineCardList.splice(vaccineCardIndex, 1);
            }
        },
        SaveVaccineCard(state: AppStateModel, vaccineCard: VaccineCardModel): void {
            let vaccineCardIndex = state.UserData.VaccineCardList.findIndex(vc => { return vc.VaccineCardID == vaccineCard.VaccineCardID; });
            if (vaccineCardIndex == -1) {
                state.UserData.VaccineCardList.unshift(vaccineCard);
            } else {
                state.UserData.VaccineCardList.splice(vaccineCardIndex, 1, vaccineCard);
            }
        },
    },

    getters: {
        IsLoading(state: AppStateModel): boolean {
            return state.LoadingStack > 0;
        },
        IsSignedIn(state: AppStateModel): boolean {
            return state.AuthToken != null && state.UserData != null;
        },
        GenerateAuthHeaders(state: AppStateModel): Headers {
            if (state.AuthToken != null) {
                let authHeaders = new Headers();
                authHeaders.append('Authorization', 'Bearer ' + state.AuthToken);

                return authHeaders;
            } else {
                return null;
            }
        },
        AllEnabledProducts(state: AppStateModel): ProductTypeEnum[] {
            let enabledProductList: ProductTypeEnum[] = [];
            if (state.UserData != null && state.UserData.ClientList != null) {
                state.UserData.ClientList.forEach(c => {
                    c.EnabledProducts.forEach(p => {
                        if (enabledProductList.indexOf(p) == -1) {
                            enabledProductList.push(p);
                        }
                    });
                });
            }
            
            return enabledProductList;
        },
        PatientNameByID(state: AppStateModel) {
            return (patientID: number): string => {
                if (patientID == null || state.UserData == null) {
                    return "";
                } else {
                    let patient = (state.UserData.PatientList).find((patient) => { return patient.PatientID == patientID; });
                    return patient == null ? "" : `${patient.Person.FirstName} ${patient.Person.LastName}`;
                }
            }
        },
        EthnicityValuesList(state: AppStateModel): string[] {
            return state.EthnicityList.map((ethnicity) => { return ethnicity.ID; });
        },
        RaceValuesList(state: AppStateModel): string[] {
            return state.RaceList.map((race) => { return race.ID; });
        },
    },
});