

















import { Component, Prop, Vue } from "vue-property-decorator";
import { DSBRScannerSettings, dbrLicenseKeyConfig } from "@/models/Config.ts";
import { ScannerModeEnum, ScanStatusEnum } from '@/models/Enums.ts';

@Component
export default class ScannerOverlay extends Vue {
    @Prop() ScannerMode: ScannerModeEnum;
    @Prop() Continuous: boolean;
    @Prop() ScanStatus: ScanStatusEnum;

    Scanner: any = null;
    IsLoading: boolean = true;
    ScannerStatusEnum = ScanStatusEnum;

    created() {
        
    }

    mounted() {
        (async () => {
            let scannerSettings = DSBRScannerSettings;
            scannerSettings.scanner.key = this.$store.state.DataSymbolBarcodeReaderKey;
            scannerSettings.viewport.id = "datasymbol-barcode-viewport";
            scannerSettings.barcode.barcodeTypes = this.ScannerMode == ScannerModeEnum.License ? ['PDF417'] : this.ScannerMode == ScannerModeEnum.Barcode ? ['Code128', 'Code39'] : this.ScannerMode == ScannerModeEnum.QRCode ? ['QRCode'] : [];

            window.DSScanner.addEventListener('onError', (err: any) => { console.log(err.name + ' - ' + err.message); alert("There was an error trying to access your device camera. Please contact support for further assistance. " + err.name + ' - ' + err.message); });

            window.DSScanner.addEventListener('onBarcode', (barcodeResult: any) => {
                if (this.Continuous == true) {
                    //Only observe status when in continuous mode
                    if (this.ScanStatus != ScanStatusEnum.Ready) { return; }
                }

                if (this.ScannerMode == ScannerModeEnum.License) {
                    this.ParseLicenseBarcode(window.DSScanner.bin2String(barcodeResult[0]));
                } else if (this.ScannerMode == ScannerModeEnum.Barcode) {
                    this.ParseBarcode(window.DSScanner.bin2String(barcodeResult[0]));
                } else if (this.ScannerMode == ScannerModeEnum.QRCode) {
                    this.ParseQRCode(window.DSScanner.bin2String(barcodeResult[0]));
                }
            });

            window.DSScanner.addEventListener('onScannerReady', () => {
                window.DSScanner.StartScanner();
                this.IsLoading = false;
            });

            window.DSScanner.Create(scannerSettings);
        })();
    }

    beforeDestroy() {
        window.DSScanner.Destroy();
    }

    ParseLicenseBarcode(txt: string): void {
        let lines = txt.split('\n');
        let abbrs = Object.keys(dbrLicenseKeyConfig);
        let map = {};
        lines.forEach((line, i) => {
            let abbr;
            let content;
            if (i === 1) {
                abbr = 'DAQ';
                content = line.substring(line.indexOf(abbr) + 3);
            } else {
                abbr = line.substring(0, 3);
                content = line.substring(3).trim();
            }
            if (abbrs.includes(abbr)) {
                (map as any)[abbr] = {
                    description: (dbrLicenseKeyConfig as any)[abbr],
                    content: content
                };
            }
        });

        if (!this.Continuous) {
            window.DSScanner.Destroy();
        }

        window.setTimeout(() => { this.$emit('LicenseScan', map); }, 100); //To fix mobile chrome bug
    }

    ParseBarcode(barcode: string): void {
        if (!this.Continuous) {
            window.DSScanner.Destroy();
        }

        window.setTimeout(() => { this.$emit('BarcodeScan', barcode); }, 100); //To fix mobile chrome bug
    }

    ParseQRCode(qrCode: string): void {
        if (!this.Continuous) {
            window.DSScanner.Destroy();
        }

        window.setTimeout(() => { this.$emit('QRCodeScan', qrCode); }, 100); //To fix mobile chrome bug
    }

    Cancel() {
        window.DSScanner.Destroy();

        window.setTimeout(() => { this.$emit('ScanCancel'); }, 100); //To fix mobile chrome bug
    }
}
