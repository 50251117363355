







//Imports
import { Component, Prop, Vue } from "vue-property-decorator";
import { DemoVerificationTypeEnum } from "@/models/Enums";
import { DemoVerificationModel } from "@/models/TestEntry";
import { GetVerificationToDisplay } from "@/utils/GlobalFunctions";

//TODO: Brian - After Vue 3 upgrade, replace with the LabX version of this whole component

@Component
export default class DemoVerificationDisplay extends Vue {
    @Prop() private Type!: DemoVerificationTypeEnum;
    @Prop() private VerificationList!: DemoVerificationModel[];
    @Prop() private FieldValue!: string;

    get VerificationToDisplay() {
        return GetVerificationToDisplay(this.Type, this.VerificationList, this.FieldValue);
    }
}
