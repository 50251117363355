


























// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required, sameAs } from 'vuelidate/lib/validators';

//Will not be used anywhere else
enum PasswordStrengthEnum {
    WEAK = 1,
    FAIR = 2,
    STRONG = 3,
    VERYSTRONG = 4
}

//Will not be used anywhere else
class PasswordStrengthPropertiesModel {
    Type: PasswordStrengthEnum;
    Min: number;
    Max: number;
    DisplayText: string;
    CssClass: string;
}

@Component
export default class ToggleInput extends Vue {
    @Prop() password!: string;

    ConfirmPassword: string = "";
    PasswordStrengthList: PasswordStrengthPropertiesModel[] = [];

    created() {
        this.PasswordStrengthList = [];
        this.PasswordStrengthList.push({ Type: PasswordStrengthEnum.WEAK, Min: 1, Max: 4, DisplayText: "Weak", CssClass: "weak" } as PasswordStrengthPropertiesModel);
        this.PasswordStrengthList.push({ Type: PasswordStrengthEnum.FAIR, Min: 5, Max: 6, DisplayText: "Fair", CssClass: "fair" } as PasswordStrengthPropertiesModel);
        this.PasswordStrengthList.push({ Type: PasswordStrengthEnum.STRONG, Min: 7, Max: 9, DisplayText: "Strong", CssClass: "strong" } as PasswordStrengthPropertiesModel);
        this.PasswordStrengthList.push({ Type: PasswordStrengthEnum.VERYSTRONG, Min: 10, Max: 10, DisplayText: "Very Strong", CssClass: "veryStrong" } as PasswordStrengthPropertiesModel);
    }

    @Validations()
    validations() {
        return {
            password: {
                required,
                minLength: () => this.PasswordHasMinLength === true,
                lowerCase: () => this.PasswordHasLowerCase === true,
                upperCase: () => this.PasswordHasUpperCase === true,
                numberSpecialChar: () => this.PasswordHasNumberSpecialChar === true
            },
            ConfirmPassword: { required, sameAsPassword: sameAs('password') },
        };
    }

    get PasswordStrength(): number {
        let strength = 0;

        if (this.password == null || this.password.length < 6) {
            return strength;
        }

        strength += 1; //Meets min requirement
        strength += this.password.length >= 16 ? 1 : 0; //1 Point for 16+ chars
        strength += /[A-Z]/.test(this.password) ? 2 : 0; //2 Points for using an uppercase letter
        strength += /[a-z]/.test(this.password) ? 2 : 0; //2 Points for using an uppercase letter
        strength += /\d/.test(this.password) ? 2 : 0; //2 Points for using a number
        strength += /[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\{\]\}\\\|\;\:\'\"\,\<\.\>\/\?\`\~]/.test(this.password) ? 2 : 0; //2 Points for using a special character

        return strength;
    }

    get CanEvaluateStrength(): boolean {
        return this.PasswordStrength > 0;
    }

    get PasswordStrengthPercent(): string {
        return (this.PasswordStrength * 10) + "%";
    }

    get PasswordStrengthProperties (): PasswordStrengthPropertiesModel {
        if (this.PasswordStrength == 0) {
            return null;
        } else {
            return this.PasswordStrengthList.find((x) => { return this.PasswordStrength >= x.Min && this.PasswordStrength <= x.Max; });
        }
    }

    get PasswordStrengthCSSClass (): string {
        if (this.PasswordStrengthProperties == null) {
            return "";
        } else {
            return this.PasswordStrengthProperties.CssClass;
        }
    }

    get DisplayPasswordStrengthText(): string {
        if (this.PasswordStrengthProperties == null) {
            return "";
        } else {
            return this.PasswordStrengthProperties.DisplayText;
        }
    }

    get PasswordHasNumberSpecialChar(): boolean {
        return /\d/.test(this.password) || /[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\{\]\}\\\|\;\:\'\"\,\<\.\>\/\?\`\~]/.test(this.password);
    }

    get PasswordHasLowerCase(): boolean {
        return this.password != null && /([a-z])/.test(this.password);
    }

    get PasswordHasUpperCase(): boolean {
        return /([A-Z])/.test(this.password);
    }

    get PasswordHasMinLength(): boolean {
        return this.password != null && this.password.length >= 6;
    }

    get IsValid() {
        return this.$v.$anyError;
    }

    @Watch('$v.$anyError')
    UpdateIsValid() {
        this.$emit("update:isValid", !this.$v.$anyError);
    }

    UpdateValue(event: any) {
        this.$emit("update:password", event.target.value);
        this.$forceUpdate();
    }
}
