






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class MessageModal extends Vue {
    Title: string = null;
    Message: string = null;
    Image: string = null;
    IsSupportMessage: boolean = false;
    CanClickToClose: boolean = false;
    IsLargeModal: boolean = false;
    DisplayOkayButton: boolean = false;

    get HasMessage() {
        return this.Message != null && this.Message.length > 0;
    }

    get HasImage() {
        return this.Image != null && this.Image.length > 0;
    }

    private OpenSupportModal() {
        this.Close();
        this.$modal.show('supportModal');
    }

    Close() {
        this.$modal.hide('messageModal');
        this.Title = null;
        this.Message = null;
        this.Image = null;
        this.IsSupportMessage = false;
        this.CanClickToClose = false;
        this.IsLargeModal = false;
        this.DisplayOkayButton = false;
    }

    beforeOpen(event: any) {
        this.Title = event.params.Title;
        this.Message = event.params.Message;
        this.Image = event.params.Image;
        this.IsSupportMessage = event.params.IsSupportMessage;
        this.CanClickToClose = event.params.CanClickToClose;
        this.IsLargeModal = event.params.IsLargeModal;
        this.DisplayOkayButton = event.params.DisplayOkayButton;
    }
}
