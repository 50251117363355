

















import { Component, Prop, Vue } from "vue-property-decorator";
import { DOMBinder, DOM_Event, DOM_Element } from "@/utils/DOMBinder";

@Component
export default class InactivityTimer extends Vue {
    @Prop() WarningTime: number;
    @Prop() ResetTime: number;

    private CheckLastActiveTimerPoll: number = 1000; //Every second
    private LastActiveTime: number = Date.now();
    private CountDownTimerID: number = null;         //Reference to countdown timer - Used only for updating the countdown time for display
    CountDownTime: number = 0;            //Displayed countdown time (in seconds) - Only for UI
    private IsTimerActive: boolean = false;
    private IsShowingModal: boolean = false;

    created() {
        DOMBinder.BindListener(DOM_Event.MouseMove, DOM_Element.Document, this.UpdateLastActiveTime);
        DOMBinder.BindListener(DOM_Event.MouseDown, DOM_Element.Document, this.UpdateLastActiveTime);
        DOMBinder.BindListener(DOM_Event.KeyUp, DOM_Element.Document, this.UpdateLastActiveTime);
        DOMBinder.BindListener(DOM_Event.KeyDown, DOM_Element.Document, this.UpdateLastActiveTime);
        DOMBinder.BindListener(DOM_Event.DOMMouseScroll, DOM_Element.Document, this.UpdateLastActiveTime);
        DOMBinder.BindListener(DOM_Event.TouchMove, DOM_Element.Document, this.UpdateLastActiveTime);
        DOMBinder.BindListener(DOM_Event.MSPointerMove, DOM_Element.Document, this.UpdateLastActiveTime);

        this.CountDownTime = Math.ceil((this.ResetTime - this.WarningTime) / 1000);

        //Start the timer for checking the last active time. This timer will always be active, but will only do anything if the IsTimerActive is set to true
        window.setInterval(this.CheckLastActive, this.CheckLastActiveTimerPoll);
    }

    public StartTimer = () => {
        this.IsTimerActive = true;
    }

    public StopTimer = () => {
        if (this.IsTimerActive) {
            if (this.IsShowingModal) {
                this.$modal.hide("timeoutModal");
                this.StopCountDownTimer();
                this.IsShowingModal = false;
            }
            this.IsTimerActive = false;
        }
    }

    public ContinueSession = () => {
        this.LastActiveTime = Date.now(); //Manually update last active time
        this.$modal.hide("timeoutModal");
        this.StopCountDownTimer();
        this.IsShowingModal = false;
    }

    public ResetPage = () => {
        this.StopTimer(); //This is called to ensure there is no chance for resetting the page again from the timer before the code in main has a chance to stop the timer
        this.$emit('Logout', "Your session has expired and you have been logged out.");
    }

    //Should only be used from the user clicking the log off button on timeout modal. It logs them off without the message about inactivity.
    public ManualLogOff = () => {
        this.StopTimer(); //This is called to ensure there is no chance for resetting the page again from the timer before the code in main has a chance to stop the timer
        this.$emit('Logout');
    }

    private CheckLastActive = () => {
        if (this.IsTimerActive) {
            let timeElapsed = Date.now() - this.LastActiveTime;

            //Compare the last active time to now and see if we should trigger any changes
            if (timeElapsed >= this.ResetTime) {
                this.ResetPage();
            } else if (timeElapsed >= this.WarningTime && !this.IsShowingModal) {
                this.DisplayTimeoutModal(timeElapsed);
            }
        }
    }

    private UpdateLastActiveTime = () => {
        //Only update the last active time if inactivity modal isn't open. If we did, the user would never time out if they are active while the modal is open
        if (!this.IsShowingModal) {
            this.LastActiveTime = Date.now();
        }
    }

    private DisplayTimeoutModal = (timeElapsed: number) => {
        this.StartCountDownTimer(timeElapsed);
        this.$modal.show("timeoutModal");
        this.IsShowingModal = true;
    }

    private StartCountDownTimer = (timeElapsed: number) => {
        window.clearTimeout(this.CountDownTimerID);

        //Since the warning modal can appear in between the warning and reset time (if the tab was sleeping) we should calculate the time remaining for the most accurate countdown
        this.CountDownTime = Math.ceil((this.ResetTime - timeElapsed) / 1000);

        this.CountDownTimerID = window.setTimeout(this.UpdateCountDownTime, 1000);
    }

    private StopCountDownTimer = () => {
        window.clearTimeout(this.CountDownTimerID);
        this.CountDownTimerID = null;
    }

    private UpdateCountDownTime = () => {
        this.CountDownTime = this.CountDownTime - 1;
        document.getElementById("countDownSpan").innerHTML = this.CountDownTime.toString(); //TODO: Brian - Had to do this because CountDownTime wasn't being reactive in the template

        //This is just an additional check to reset the page in case the timer for checking last active is slightly out of sync with this countdown timer
        if (this.CountDownTime == 0) {
            this.ResetPage();
        }

        this.CountDownTimerID = window.setTimeout(this.UpdateCountDownTime, 1000);
    }
}
