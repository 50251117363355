

















// @ is an alias to /src
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    components: { }
})
export default class ProfileHeader extends Vue {
    

    get ShowMenu() {
        return this.$route.meta == null || this.$route.meta.hideMenu == null || this.$route.meta.hideMenu == false;
    }

    get ReduceProfileMargin() {
        return this.$route.meta != null && this.$route.meta.reduceProfileMargin != null && this.$route.meta.reduceProfileMargin == true;
    }

    GoToDashboard() {
        if (this.ShowMenu && this.$router.currentRoute.name != "Dashboard") {
            this.$router.push({ name: "Dashboard" });
        }
    }

    Logout() {
        this.$emit('Logout');
    }
}
