






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import Browser from '@/utils/Browser';
import { ErrorReasonEnum, MessageSeverity } from '@/models/Enums'
import { LoginDataModel, LoginResponseModel } from '@/models/Common';
import FormMessage from '@/components/FormMessage.vue';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';

@Component({
    components: { FormMessage, ForgotPasswordModal }
})
export default class Login extends Vue {
    @Prop() message: string;
    Username: string = "";
    Password: string = "";

    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;
    DisplaySupportLink: boolean = false;

    created() {
        if (this.message != null && this.message.length > 0) {
            this.SetFormMessage(this.message, MessageSeverity.LOW);
            this.$router.replace({ path: this.$router.currentRoute.hash.substring(1) });
        }
    }

    @Validations()
    validations() {
        return {
            Username: { required },
            Password: { required },
        };
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        this.DisplaySupportLink = showSupportLink;
        Browser.SmoothScroll(0);
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
        this.DisplaySupportLink = false;
    }

    OpenForgotPasswordModal(): void {
        this.$modal.show('forgotPasswordModal', { Email: this.Username });
    }

    Submit() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        this.Username = this.Username.trim();

        Ajax.POST("/App/Login",
            { Username: this.Username, Password: this.Password, PatientGuid: this.$router.currentRoute.query.p },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let loginData: LoginDataModel = { AuthToken: result.AuthToken, LoginData: result.Data as LoginResponseModel } as LoginDataModel;

                    this.$emit('Login', loginData);
                } else {
                    let errorCode = result.Data as ErrorReasonEnum;

                    switch (errorCode) {
                        case ErrorReasonEnum.InvalidEmail:
                        case ErrorReasonEnum.AccountDoesNotExist:
                        case ErrorReasonEnum.InvalidCredentials:
                            this.SetFormMessage("You have supplied invalid credentials. Please try again.", MessageSeverity.HIGH);
                            break;
                        case ErrorReasonEnum.AccountLocked:
                            this.SetFormMessage("Your account has been locked. Please use Forgot Password below to recover your account.", MessageSeverity.HIGH);
                            break;
                        default:
                            this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                            break;
                    }
                }
            },
            () => {
                this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
            });
    }
}
