























import { Component, Vue } from "vue-property-decorator";
import { ClientMetaDataModel, UserDataModel } from "@/models/Common";
import { TestEntryDataModel } from "@/models/TestEntry";
import { AppStateModel } from "@/store";
import { StringHasValue } from "@/utils/GlobalFunctions";

@Component
export default class ContactSupport extends Vue {
    SupportList: ClientMetaDataModel[] = [];

    created() {
        let userData = (this.$store.state.UserData as UserDataModel);
        let testEntryData = (this.$store.state.TestEntryData as TestEntryDataModel);

        if (testEntryData != null && (StringHasValue(testEntryData.SupportEmailAddress) || StringHasValue(testEntryData.SupportPhoneNumber))) {
            this.SupportList = [{ Name: `${testEntryData.LocationName} (${testEntryData.ClientName})`, SupportEmailAddress: testEntryData.SupportEmailAddress, SupportPhoneNumber: testEntryData.SupportPhoneNumber } as ClientMetaDataModel];
        } else if (userData != null && userData.ClientList != null && userData.ClientList.length > 0) {
            this.SupportList = userData.ClientList.sort((clientA, clientB) => { return clientA.LatestEncounterDate > clientB.LatestEncounterDate ? -1 : clientA.LatestEncounterDate < clientB.LatestEncounterDate ? 1 : 0; });
        } else {
            let appState = this.$store.state as AppStateModel;
            this.SupportList = [{ Name: "FrontRunnerHC", SupportEmailAddress: appState.SupportEmailAddress, SupportPhoneNumber: appState.SupportPhoneNumber } as ClientMetaDataModel];
        }
    }
}
