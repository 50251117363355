

















import { Component, Vue } from "vue-property-decorator";

@Component
export default class ResendEmailCodeModal extends Vue {

    Close() {
        this.$modal.hide('resendEmailCodeModal');
    }

    ResendCode() {
        this.$emit("ResendCode");
        this.Close();
    }
}
