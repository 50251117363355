








































// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { CommunicationTypeEnum, ErrorReasonEnum } from '@/models/Enums';
import ToggleInput from '@/components/inputs/ToggleInput.vue';
import PhoneNumberInput from '@/components/inputs/PhoneNumberInput.vue';

@Component({
    components: { PhoneNumberInput, ToggleInput }
})
export default class CommPreference extends Vue {
    @Prop() communicationType!: CommunicationTypeEnum;
    @Prop() preferenceValue!: boolean;
    @Prop() preferenceName!: string;
    @Prop() outReachValue!: string;

    IsInConfirmation: boolean = false;
    IsInOutReachEntry: boolean = false;
    IsInVerification: boolean = false;
    TempOutReachValue: string = null;
    VerificationCode: string = null;

    //Emits SetPreference - preferenceValue, outReachValue
    created() {
        
    }

    //Disabled while filling out the "sub sections"
    get IsDisabled(): boolean {
        return this.IsInConfirmation || this.IsInOutReachEntry || this.IsInVerification;
    }

    get DisplayOutreachName(): string {
        return this.IsSMS ? "Cell Phone Number" : "Email";
    }

    get IsOutReachValid(): boolean {
        return this.IsSMS ? this.TempOutReachValue != null && this.TempOutReachValue.length >= 10 : true;
    }

    get IsVerificationCodeValid(): boolean {
        return this.VerificationCode != null && this.VerificationCode.length == 5;
    }

    get IsSMS(): boolean {
        return this.communicationType == CommunicationTypeEnum.SMS;
    }

    get HasExistingOutreach(): boolean {
        //Returns true if there is an outreach value already set (will be valid if it is set as outReachValue)
        return this.outReachValue != null && this.outReachValue.length > 0;
    }

    TogglePreference() {
        //Don't toggle if we are in the middle of a process
        if (this.IsDisabled) {
            return;
        }

        //Reset user entry values
        this.TempOutReachValue = null;
        this.VerificationCode = null;

        if (this.preferenceValue) {
            this.IsInConfirmation = true;
        } else {
            if (this.outReachValue != null && this.outReachValue.length > 0) {
                this.SavePreference(true);
            } else {
                this.IsInOutReachEntry = true;
            }
        }
    }

    EnterOutReachEntry(): void {
        this.TempOutReachValue = null;
        this.VerificationCode = null;
        this.IsInOutReachEntry = true;
    }

    SavePreference(preferenceValue: boolean) {
        let endpoint = this.communicationType == CommunicationTypeEnum.Email ? "/App/SaveEmailPreference" :
            this.communicationType == CommunicationTypeEnum.SMS ? "/App/SaveSMSPreference" : null;

        let request = {
            EnableNotifications: preferenceValue,
            OutreachValue: this.TempOutReachValue,
            VerificationCode: this.VerificationCode
        };
        
        Ajax.POST(endpoint,
            request,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.$emit("SetPreference", preferenceValue, this.TempOutReachValue);
                    this.IsInConfirmation = false;
                    this.IsInVerification = false;
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    RequestVerificationCode() {
        Ajax.POST("/App/RequestSMSVerificationCode",
            this.TempOutReachValue,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.IsInOutReachEntry = false;
                    this.IsInVerification = true;
                } else {
                    let errorCode = result.Data as ErrorReasonEnum;
                    
                    if (errorCode == ErrorReasonEnum.CommunicationBlocked) {
                        let outreachNumber: string = this.$store.state.DefaultSMSNumber;
                        let formattedOutreachNumber: string = this.$options.filters.FormatPhoneNumber(this.$store.state.DefaultSMSNumber);

                        this.$modal.show('messageModal', { Title: 'Error', Message: `This number is blocked from recieving messages from PatientXchange. Text Unstop to <a href="tel:${outreachNumber}">${formattedOutreachNumber}</a> and try again.`, CanClickToClose: false, DisplayOkayButton: true });
                    } else {
                        this.$modal.show('messageModal', { Title: 'Error', Message: "We were not able to reach the number you provided. Please check it for accuracy.", IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                    }
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }
}
