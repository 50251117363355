import Vue from "vue";
import { PersonModel } from "../models/Common";
import { VaccineManufacturerEnum, VaccineTypeEnum } from "../models/Enums";

/*
 * Filters are defined here and imported in the App vue file which will attach all filters listed below to Vue.
 * These can be referenced globally through the application.
*/
Vue.filter("StringHasValue",
    (data: string) => {
        return data != null && data.length > 0;
    }
);

Vue.filter("DisplayCOBOrderName",
    (cobOrder: number) => {
        let sequence = ["Primary", "Secondary", "Tertiary", "Quaternary", "Quinary", "Senary", "Septenary", "Octonary", "Nonary", "Denary"];

        return cobOrder >= 0 && cobOrder <= 9 ? sequence[cobOrder] : "";
    }
);

Vue.filter("FormatOptionalFieldForDisplay",
    (value: string): string => {
        return value != null && value.length > 0 ? value : 'N/A';
    }
);

//This should ONLY be used when the datetime or datetimeoffset is recorded without the proper time component so we can extract only the date
Vue.filter("FormatDateForDisplay",
    //Expected input format: yyyy-mm-ddThh:mm:ss -OR- yyyy-mm-dd
    (date: string): string => {
        if (date != null && date.toString().length > 0) {
            var dateWithoutTime = date.toString().split("T")[0];
            var dateParts = dateWithoutTime.split("-");
            return dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];
        }
        return "";
    }
);

Vue.filter("FormatDateForLocalDateDisplay",
    //Expected input format: yyyy-mm-ddThh:mm:ss -OR- yyyy-mm-dd
    (date: string): string => {
        if (date != null && date.toString().length > 0) {
            return (new Date(date)).toLocaleString([], { day: "numeric", month: "numeric", year: "numeric" } as any);
        }
        return "";
    }
);

Vue.filter("FormatDateForLocalDateTimeDisplay",
    //Expected input format: yyyy-mm-ddThh:mm:ss
    (date: string): string => {
        if (date != null && date.toString().length > 0) {
            return (new Date(date)).toLocaleString([], { dateStyle: "short", timeStyle: "short", hour12: true } as any);
        }
        return "";
    }
);

Vue.filter("FormatDateForLocalDateTimeDisplayWithTimezone",
    //Expected input format: mm-dd-yyyyThh:mm
    (date: string): string => {
        if (date != null && date.toString().length > 0) {
            return (new Date(date)).toLocaleString([], { month: "numeric", day: "numeric", year: "2-digit", hour: "numeric", minute: "numeric", timeZoneName: "short", hour12: true } as any);
        }
        return "";
    }
);

Vue.filter("FormatTimeForDisplay",
    //Expected input format: Thh:mm:ss
    (date: string): string => {
        if (date != null && date.toString().length > 0) {
            return (new Date(date)).toLocaleString([], { hour: "numeric", minute: "numeric", hour12: true } as any);
        }
        return "";
    }
);


Vue.filter("FormatPatientNameForDisplay",
    (patient: PersonModel) => {
        return patient.FirstName + " " + (patient.MiddleName != null ? patient.MiddleName : "") + " " + patient.LastName;
    }
);

Vue.filter("FormatPatientDOBForDisplay",
    (patient: PersonModel) => {
        return `${patient.DOB_Month}/${patient.DOB_Day}/${patient.DOB_Year}`;
    }
);

Vue.filter("FormatPatientAddressForDisplay",
    (patient: PersonModel) => {
        return patient.Address + ", " + patient.City + ", " + patient.State + " " + patient.Zip;
    }
);

Vue.filter("FormatGenderForDisplay",
    (genderCode: string) => {
        if (genderCode == null || genderCode.length == 0) {
            return "N/A";
        }

        switch (genderCode.toLowerCase()) {
            case "m":
                return "Male";
            case "f":
                return "Female";
            case "u":
                return "Unknown";
            default:
                return "N/A";
        }
    }
);

Vue.filter("FormatVaccineTypeForDisplay",
    (vaccineType: VaccineTypeEnum) => {
        switch (vaccineType) {
            case VaccineTypeEnum.COVID19:
                return "COVID-19 Vaccine";
            default:
                return "N/A";
        }
    }
);

Vue.filter("FormatVaccineManufacturerForDisplay",
    (vaccineManufacturer: VaccineManufacturerEnum) => {
        switch (vaccineManufacturer) {
            case VaccineManufacturerEnum.AstraZeneca:
                return "AstraZeneca";
            case VaccineManufacturerEnum.BioNTech:
                return "BioNTech";
            case VaccineManufacturerEnum.GlaxoSmithKline:
                return "GlaxoSmithKline";
            case VaccineManufacturerEnum.JohnsonAndJohnson:
                return "Johnson & Johnson";
            case VaccineManufacturerEnum.Merck:
                return "Merck";
            case VaccineManufacturerEnum.Moderna:
                return "Moderna";
            case VaccineManufacturerEnum.Novavax:
                return "Novavax";
            case VaccineManufacturerEnum.Pfizer:
                return "Pfizer";
            case VaccineManufacturerEnum.Sanofi:
                return "Sanofi";
            case VaccineManufacturerEnum.Other:
                return "Other";
            default:
                return "N/A";
        }
    }
);

Vue.filter("FormatPhoneNumber",
    (phoneString: string) => {
        //Filter only numbers from the input
        let cleaned = ('' + phoneString).replace(/\D/g, '');

        //Check if the input is of correct
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            //Remove the matched extension code
            //Change this to format for any country code.
            let intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }

        return null;
    }
);

Vue.filter("FormatNumberToOrdinal",
    (i: number) => {
        return i >= 0 && i <= 20 ? ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th"][i] : "";
    }
);