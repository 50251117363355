//Define custom validation functions to be used with Vuelidate
import { email, helpers } from 'vuelidate/lib/validators';
import { TestEntryResultModel } from '../models/TestEntry';

export function phoneNumber(value: any): boolean {
    return value == null || value.length == 0 || /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value);
}

export function emailCaseInsensitive(val: string): boolean {
    return (email as any)(val != null ? val.toLowerCase() : val);
}

export function inList(value: string, list: string[]): boolean {
    //Returns true if null is provided so that 'required' and 'inList' can remain as separate validations
    return value == null || list.findIndex((item) => { return item.toLowerCase() == value.toLowerCase(); }) > -1;
}

export function validTestResults(vals: TestEntryResultModel[]): boolean {
    return vals == null || vals.length == 0 || vals.findIndex((val) => { return val.Result == null }) == -1;
}

export function specialCharBlacklist(value: string): boolean {
    return value == null || !/[\^\~\\\&\|]/.test(value);
}

export const startsWith = (param: string) => helpers.withParams(
    { type: 'startsWith', prefix: param },
    (value: string) => {
        return value == null || param == null || value.toLowerCase().indexOf(param.toLowerCase()) == 0;
    }
);