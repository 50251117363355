


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { PersonModel } from '@/models/Common';
import { ConsentConfigModel, TestRecordModel } from '@/models/TestEntry';
import { ConsentDataTypeEnum, TestModeEnum } from '@/models/Enums';

@Component({
    components: {  }
})
export default class TestEntryComplete extends Vue {
    @Prop() testRecord: TestRecordModel;
    ConsentConfigList: ConsentConfigModel[] = [];

    created() {
        if (this.$store.state.TestEntryData == null || this.testRecord == null) {
            this.$router.replace({ path: '/testentrylanding' });
            return;
        }

        this.ConsentConfigList = this.$store.state.TestEntryData.ConsentConfigList as ConsentConfigModel[];
    }

    NewPrefilledTest() {
        let newTest: TestRecordModel = JSON.parse(JSON.stringify(this.testRecord));
        let storedGuardian: PersonModel = null;
        let storedGuarantor: PersonModel = null;

        newTest.ID = null;
        newTest.CreateDate = null;

        newTest.Patient.FirstName = null;
        newTest.Patient.MiddleName = null;
        newTest.Patient.DOB_Month = null;
        newTest.Patient.DOB_Day = null;
        newTest.Patient.DOB_Year = null;
        newTest.Patient.Gender = null;

        if (newTest.Guardian != null) {
            storedGuardian = newTest.Guardian;
            newTest.Guardian = null;
        }

        if (newTest.Guarantor != null) {
            storedGuarantor = newTest.Guarantor;
            newTest.Guarantor = null;
        }

        newTest.Signature = null;

        //Clear out consent and test results
        newTest.ConsentAgreementList = [];
        newTest.TestResults = [];

        this.$router.push({ name: "Registration", params: { testRecord: newTest, storedGuardian: storedGuardian, storedGuarantor: storedGuarantor } as any });
    }

    get IsQuickTest() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.QuickTest;
    }

    get IsQuickTestPrecoded() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.QuickTestPrecoded;
    }

    get IsQuickTestRapidResult() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.QuickTestRapidResult;
    }

    get IsPatientIntake() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.PatientIntake;
    }

    get IsPatientIntakeQueue() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.PatientIntakeQueue;
    }

    get IsPatientManagement() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.PatientManagement;
    }

    get PatientConnectOutreachMessage(): string {
        return "You will receive " +
            (this.HasAcceptedEmailConsent ? "an email " : "") +
            (this.HasAcceptedEmailConsent && this.HasAcceptedSMSConsent ? "and " : "") +
            (this.HasAcceptedSMSConsent ? "a text message " : "") +
            "to view your results in PatientXchange once they are processed.";
    }

    get HasAcceptedEmailConsent(): boolean {
        if (this.testRecord == null) {
            return false;
        }

        let emailConsent = this.ConsentConfigList.find((cc) => { return cc.ConsentDataType == ConsentDataTypeEnum.Email; });

        if (emailConsent == null) {
            return false;
        }

        let emailAgreement = this.testRecord.ConsentAgreementList.find((ca) => { return ca.ConsentConfigID == emailConsent.ConsentConfigID; });

        if (emailAgreement == null || !emailAgreement.Accepted) {
            return false;
        } else {
            return true;
        }
    }

    get HasAcceptedSMSConsent(): boolean {
        if (this.testRecord == null) {
            return false;
        }

        let smsConsent = this.ConsentConfigList.find((cc) => { return cc.ConsentDataType == ConsentDataTypeEnum.SMS; });

        if (smsConsent == null) {
            return false;
        }

        let smsAgreement = this.testRecord.ConsentAgreementList.find((ca) => { return ca.ConsentConfigID == smsConsent.ConsentConfigID; });

        if (smsAgreement == null || !smsAgreement.Accepted) {
            return false;
        } else {
            return true;
        }
    }
}
