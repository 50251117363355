













import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NotFound extends Vue {
    BackToHome() {
        this.$router.push({ name: "Dashboard" }); //Send to dashboard, from there they will get bounced to login if they are not logged in
        window.location.reload(true);
    }
}
