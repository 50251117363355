












import { Component, Vue } from "vue-property-decorator";
import { PatientModel } from '@/models/Common';
import { SelectionMode } from '@/models/Enums';
import ProfileManagement from "@/components/ProfileManagement.vue";

@Component({
    components: { ProfileManagement }
})
export default class ProfileSelectionModal extends Vue {
    ProfileSelectionMode: SelectionMode = SelectionMode.Selection;

    get IsProfileSelectionMode() {
        return this.ProfileSelectionMode == SelectionMode.Selection;
    }

    ProfileSelect(profile: PatientModel) {
        this.$emit('ProfileSelect', profile);
        this.Close();
    }

    Close() {
        this.$modal.hide('profileSelectionModal');
    }

    beforeOpen(event: any) {
        
    }
}
