import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store';
import { ProductTypeEnum } from '../models/Enums'
import Login from '../views/Login.vue'
import NotFound from '../views/NotFound.vue'
import Error from '../views/Error.vue'
import Unauthorized from '../views/Unauthorized.vue'
import Dashboard from '../views/Dashboard.vue'
import Account from '../views/Account.vue'
import Help from '../views/Help.vue'
import PasswordReset from '../views/PasswordReset.vue'
import TOS from '../views/TOS.vue'
import NewAccount from '../views/NewAccount.vue';
import VerifyPatient from '../views/VerifyPatient.vue';
import TestEntryLanding from '../views/TestEntryLanding.vue';
import TestResults from '../views/TestResults/TestResults.vue';
import VaccineCards from '../views/VaccineCards/VaccineCards.vue';
import VaccineCardDetails from '../views/VaccineCards/VaccineCardDetails.vue';
import VaccineCardQRCode from '../views/VaccineCards/VaccineCardQRCode.vue';
import NewVaccineCard from '../views/VaccineCards/NewVaccineCard.vue';
import TestEntry from '../views/TestEntry.vue';
import TestEntryComplete from '../views/TestEntryComplete.vue';
import TestEntryVerify from '../views/TestEntryVerify.vue';
import { UserDataModel } from '../models/Common';
import { LoginNavigationRouting } from '../utils/GlobalFunctions';


Vue.use(VueRouter)

//meta can contain the following: requiresAuth, requiresProduct, hideMenu, dashboardDisplay: { image }

export const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        props: route => ({ message: route.query.message }),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/newaccount',
        name: 'New Account',
        component: NewAccount,
        props: true, //TODO: Brian - Can remove now?
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/verifypatient',
        name: 'Verify Patient',
        component: VerifyPatient,
        meta: {
            requiresAuth: true,
            hideMenu: true
        },
    },
    {
        path: '/passwordreset',
        name: 'Password Reset',
        component: PasswordReset,
        props: route => ({ resetCode: route.query.code }),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/tos',
        name: 'Terms of Service',
        component: TOS,
        meta: {
            requiresAuth: true,
            hideMenu: true
        },
    },



    //TODO: Brian - Should I split this into separate paths? Using an alias screws up navigation by name away from this page. If I do split it, change navigation on TestEntryLanding to use names instead of paths
    {
        path: '/testentrylanding',
        alias: ['/quicktest', '/quicktestprecoded', '/quicktestrapidresult', '/patientintake', '/patientintakequeue', '/patientmanagement'],
        name: '',
        component: TestEntryLanding,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/testentrylanding/verify',
        alias: ['/quicktest/verify', '/quicktestprecoded/verify', '/quicktestrapidresult/verify', '/patientintake/verify', '/patientintakequeue/verify', '/patientmanagement/verify'],
        name: '',
        component: TestEntryVerify,
        props: route => ({ testid: route.query.testid, activationcode: route.query.activationcode, testguid: route.query.testguid }),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/testentry/:urlParams?',
        name: 'Registration',
        props: true,
        component: TestEntry,
        meta: {
            requiresAuth: false,
            reduceProfileMargin:true
        },
    },
    {
        path: '/testentrycomplete',
        name: 'Test Entry Complete',
        props: true,
        component: TestEntryComplete,
        meta: {
            requiresAuth: false,
        },
    },



    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/testresults',
        name: 'Test Results',
        component: TestResults,
        props: true,
        meta: {
            requiresAuth: true,
            dashboardDisplay: {
                image: require('@/assets/TestResults.svg')
            }
        },
    },
    {
        path: '/testresults/labresult/:labResultID?/:qrCode?',
        name: 'Test Results',
        component: TestResults,
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/vaccinecards/new',
        name: 'New Vaccine Card',
        component: NewVaccineCard,
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/vaccinecards/',
        name: 'Vaccine Cards',
        component: VaccineCards,
        props: true,
        meta: {
            requiresAuth: true,
            dashboardDisplay: {
                image: require('@/assets/VaccineCard.svg')
            }
        },
    },
    {
        path: '/vaccinecards/:vaccineCardID',
        name: 'Vaccine Card Details',
        component: VaccineCardDetails,
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/vaccinecards/:vaccineCardID/:qrCode',
        name: 'Vaccine QR Code',
        component: VaccineCardQRCode,
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myaccount/:selectedSection?',
        name: 'My Account',
        component: Account,
        props: true,
        meta: {
            requiresAuth: true,
            dashboardDisplay: {
                image: require('@/assets/Account.svg')
            }
        },
    },
    {
        path: '/help/:selectedSection?',
        name: 'Help',
        component: Help,
        props: true,
        meta: {
            requiresAuth: true,
            dashboardDisplay: {
                image: require('@/assets/Help.svg')
            }
        },
    },
    {
        path: "/unauthorized",
        name: "Unauthorized",
        component: Unauthorized,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/error",
        name: "Error",
        component: Error,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "*",
        name: "Page Not Found",
        component: NotFound,
        meta: {
            requiresAuth: false,
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

//Routing Guards
router.beforeEach((to, from, next) => {
    if (to.path == '/' && store.getters.IsSignedIn) {
        //Useful for when users hit back button. If this additional routing logic wasn't in place, users could hit the back button on TOS and land on dashboard
        let userData = (store.state.UserData as UserDataModel)
        LoginNavigationRouting(router, userData);
    } else if (to.matched.some(route => route.meta.requiresAuth) && !store.getters.IsSignedIn) {
        let toQuery = to.query.p != null && to.query.p.length > 0 ? { p: to.query.p } : { };
        next({ path: '/', query: toQuery, hash: to.fullPath }); //Accessing post-login pages when no authenticated. Use hash to save where the user wants to go (deep link)
    } else if ((to.matched.some(route => route.meta.requiresProduct != null)) && !to.matched.some(route => (store.getters.AllEnabledProducts as ProductTypeEnum[]).some(product => product == route.meta.requiresProduct))) {
        next({ path: '/unauthorized' }); //Accessing pages without proper product enabled
    } else {
        next();
    }
});

export default router;