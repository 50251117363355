







// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ToggleInput extends Vue {
    @Prop() value!: boolean;
    @Prop() disabled!: boolean;

    UpdateValue(event: any) {
        if (!this.disabled) {
            this.$emit("input", !this.value);
        }
    }
}
