







import { Component, Prop, Vue } from "vue-property-decorator";
import { MessageSeverity } from '@/models/Enums';

@Component
export default class FormMessage extends Vue {
    @Prop() displaySupportLink: boolean;
    @Prop() severity: MessageSeverity;

    get SeverityCSS(): string {
        switch (this.severity) {
            case MessageSeverity.POSITIVE:
                return "alertMessage_positive";
            case MessageSeverity.QUESTION:
                return "alertMessage_question";
            case MessageSeverity.LOW:
                return "alertMessage_low";
            case MessageSeverity.MEDIUM:
                return "alertMessage_medium";
            case MessageSeverity.HIGH:
                return "alertMessage_high";
        }
    }

    private OpenSupportModal() {
        this.$modal.show('supportModal');
    }
}
