





































import { Component, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { requiredIf, maxLength, minLength, numeric } from 'vuelidate/lib/validators';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import Browser from '@/utils/Browser';
import { LoginDataModel, LoginResponseModel } from '@/models/Common';
import FormMessage from "@/components/FormMessage.vue";
import NumberInput from '@/components/inputs/NumberInput.vue';
import { ErrorReasonEnum, MessageSeverity } from '@/models/Enums';
import { LinkAccountModel } from '@/models/LinkAccount';
import { IsDOBValid } from '@/utils/GlobalFunctions';

@Component({
    components: { FormMessage, NumberInput }
})
export default class VerifyPatient extends Vue {
    PatientFirstName: string;
    LinkAccountData: LinkAccountModel = new LinkAccountModel();
    HasTriedDOBVerification: boolean = false;

    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;
    DisplaySupportLink: boolean = false;

    created() {
        this.PatientFirstName = this.$store.state.UserData.DependentFirstName as string;
        this.LinkAccountData.PatientGuid = this.$router.currentRoute.query.p as string;

        //If user somehow gets to this page manually or by mistake and we don't have these fields, bounce them out
        if (this.PatientFirstName == null || this.LinkAccountData.PatientGuid == null) {
            this.$emit('LoginNavigation');
        }
    }

    @Validations()
    validations() {
        return {
            LinkAccountData: {
                DOBValid: (val: LinkAccountModel) => ((this.HasTriedDOBVerification && !this.HasEnteredDOB) || IsDOBValid(val.DOB_Month, val.DOB_Day, val.DOB_Year)) === true,
                DOB_Month: { requiredIf: requiredIf(() => { return !this.HasTriedDOBVerification; }) },
                DOB_Day: { requiredIf: requiredIf(() => { return !this.HasTriedDOBVerification; }) },
                DOB_Year: { requiredIf: requiredIf(() => { return !this.HasTriedDOBVerification; }) },
                Zip: { maxLength: maxLength(5), minLength: minLength(5), numeric: numeric },
                Lastname: {  },
            }
        };
    }

    get HasEnteredDOB() {
        return (this.LinkAccountData.DOB_Month != null && this.LinkAccountData.DOB_Month != "") ||
            (this.LinkAccountData.DOB_Day != null && this.LinkAccountData.DOB_Day != "") ||
            (this.LinkAccountData.DOB_Year != null && this.LinkAccountData.DOB_Year != "");
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    get DisplayPatientNamePossessive() {
        if (this.PatientFirstName == null || this.PatientFirstName.length == 0) {
            return "";
        } else {
            let endsWithS = this.PatientFirstName.substring(this.PatientFirstName.length - 1).toLowerCase() == "s";
            return this.PatientFirstName + (endsWithS ? "'" : "'s");
        }
    }

    SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        this.DisplaySupportLink = showSupportLink;
        Browser.SmoothScroll(0);
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
        this.DisplaySupportLink = false;
    }

    Verify() {
        this.ClearFormMessage();

        this.$v.$touch();

        //Backspacing to remove DOB fields leaves "", so we need to null these out
        if (!this.HasEnteredDOB) {
            this.LinkAccountData.DOB_Day = null;
            this.LinkAccountData.DOB_Month = null;
            this.LinkAccountData.DOB_Year = null;
        }

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        Ajax.POST("/App/LinkAccount",
            this.LinkAccountData,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let loginData: LoginDataModel = { AuthToken: result.AuthToken, LoginData: result.Data as LoginResponseModel } as LoginDataModel;
                    //Update login data with newly linked patient data
                    this.$emit('Login', loginData);
                } else {
                    let errorCode = result.Data as ErrorReasonEnum;

                    switch (errorCode) {
                        case ErrorReasonEnum.InvalidDOB:
                            this.SetFormMessage("The date of birth you provided does not match our records. Please try again or use last name and zip code instead.", MessageSeverity.HIGH, true);
                            this.HasTriedDOBVerification = true;
                            break;
                        case ErrorReasonEnum.InvalidLinkingZipName:
                            this.SetFormMessage("The last name or zip code you provided does not match our records.", MessageSeverity.HIGH, true);
                            break;
                        default:
                            this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                            break;
                    }
                }
            },
            () => {
                this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    Skip() {
        //Navigate to next page, skipping verification
        this.$store.commit("ClearPatientLinkData");
        this.$emit('LoginNavigation');
    }
}
