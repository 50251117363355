















// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: { }
})
export default class BubbleWizard extends Vue {
    @Prop() maxSteps!: number;
    @Prop() currentStep!: number;

    created() {

    }
}
