import router from '../router';
import store from '../store';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';

/*
 * The AuthenticationManager handles auth token related data used for authenticating
 * against the server. It will also ensure the auth token is never stale.
 * 
 * After being set, the token will remain active until the auth data is wiped.
 */
export class AuthenticationManagerModel {
    private KeepAliveTimerID: number;
    private TokenLifeTime: number; //The lifetime of a token. Tokens expire past this time
    private KeepAliveTimeIncrement: number; //Time in milliseconds to attempt to refresh token (half of whatever the token life time is)
    private LastTokenRefreshTime: number;
    private UnauthorizedAndInactivityLogoutFunction: Function;
    private IsOnline: boolean;

    constructor(tokenLifeTime: number) {
        this.TokenLifeTime = tokenLifeTime;
        this.KeepAliveTimeIncrement = Math.ceil(this.TokenLifeTime / 2); //In case of a fraction, round up.
        this.KeepAliveTimerID = null;
        this.LastTokenRefreshTime = 0;
        this.IsOnline = navigator != null ? navigator.onLine : true; //Use initial status from navigator and default to true if browser does not have navigator
        this.UnauthorizedAndInactivityLogoutFunction = () => { store.commit('Logout'); router.push({ name: "Login" }); };

        //Even though online state could mean network connectivity but no internet connectivity, we are still putting the app in an online state and allowing the error handling to take care of not being able to connect to the server.
        window.addEventListener("load", () => {
            window.addEventListener("online", () => { this.IsOnline = true; });
            window.addEventListener("offline", () => { this.IsOnline = false; });
        });
    }

    public StartTokenKeepAlive = (): void => {
        this.LastTokenRefreshTime = Date.now();

        //Reset the keep alive timer
        window.clearTimeout(this.KeepAliveTimerID);
        this.KeepAliveTimerID = window.setTimeout(this.KeepAlive, 1000);
    }

    //Used only for GloballySharedResources to wipe our auth info
    public EndTokenKeepAlive = (): void => {
        window.clearTimeout(this.KeepAliveTimerID);
    }

    //Keep alive will repeatedly attempt to refresh token until WipeAuthData is called OR the last token refresh time exceeds the token expiration time
    private KeepAlive = (): void => {
        let timeElapsed = Date.now() - this.LastTokenRefreshTime;

        //Check if token is already expired (this would be the case if the app was frozen as background task, in which case timers were paused)
        if (timeElapsed >= this.TokenLifeTime) {
            this.UnauthorizedAndInactivityLogoutFunction();
            return;
        } else if (timeElapsed >= this.KeepAliveTimeIncrement && this.IsOnline) {
            //Execute KeepAlive request
            Ajax.POST("/App/KeepAlive",
                null,
                (r: AjaxResponse) => {
                    //Swap with the new auth token and reset timer
                    this.StartTokenKeepAlive();
                    store.commit('RefreshAuthToken', r.AuthToken);
                },
                () => {
                    this.KeepAliveTimerID = window.setTimeout(this.KeepAlive, 1000); //Keep trying
                },
                store.getters.GenerateAuthHeaders,
                false);
        } else {
            //If we are offline or we don't need to update the token, just keep the timer going
            this.KeepAliveTimerID = window.setTimeout(this.KeepAlive, 1000);
        }
    }
}