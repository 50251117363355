export enum ModalButtonTypeEnum {
    Cancel = 1,
    Button = 2,
    Remove = 3
}

export class ModalButtonModel {
    Type: ModalButtonTypeEnum;
    Text: string;
    Action: Function;
    ClosesModal: boolean;
}