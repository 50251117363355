
















import { Component, Vue } from "vue-property-decorator";
import SignaturePad from 'signature_pad';

@Component
export default class SignatureOverlay extends Vue {
    SigPad: SignaturePad = null;

    created() {

    }

    mounted() {
        this.SigPad = new SignaturePad(this.$refs.SigPad as HTMLCanvasElement);

        window.addEventListener("resize", this.ResizeCanvas);
        this.ResizeCanvas();
    }

    beforeDestroy() {
        //Unbinds all event handlers
        this.SigPad.off();

        window.removeEventListener("resize", this.ResizeCanvas);
    }

    ResizeCanvas() {
        let canvas = this.$refs.SigPad as HTMLCanvasElement;
        let ratio = Math.max(window.devicePixelRatio || 1, 1);

        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);

        this.SigPad.clear(); //Otherwise isEmpty() might return incorrect value
    }

    Clear() {
        //Clears the canvas
        this.SigPad.clear();
    }

    Cancel() {
        window.setTimeout(() => { this.$emit('SignatureCancel'); }, 100); //To fix mobile chrome bug
    }

    SaveSignature() {
        if (!this.SigPad.isEmpty()) {
            let dataURL = this.SigPad.toDataURL();
            this.$emit('SignatureCapture', dataURL);
        }
    }
}
