







// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FeatureButton extends Vue {
    @Prop() label!: string;
    @Prop() buttonImage!: boolean;
    @Prop() optional: boolean;
    @Prop() disabled: boolean;
}
