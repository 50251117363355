import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';

import '@/globalNamespaces';
import "@/utils/Filters"; //Importing Filters will attatch all defined filters in the filter file
import "@/utils/GlobalFunctions"; //Importing GlobalFunctions will attatch all defined functions to Vue
import "@/utils/CustomDirectives"; //Importing CustomDirectives will attatch all defined directives to Vue

Vue.config.productionTip = false;

//Include form validation
Vue.use(Vuelidate);

//Include modals
Vue.use(VModal);

//Tooltips
Vue.use(VTooltip);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');