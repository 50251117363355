































import { Component, Vue } from "vue-property-decorator";
import { LegalAgreementModel } from '@/models/TOS';
import BackButton from '@/components/BackButton.vue';
import { GetClientByID } from '@/utils/GlobalFunctions';

@Component({
    components: { BackButton }
})
export default class TOSModal extends Vue {
    TOSList: LegalAgreementModel[] = [];
    SelectedClientID: number = null;
    IsTOSSelected: boolean = false;
    CanClickToClose: boolean = false;
    DisplayOkayButton: boolean = false;

    get HasTOSList() {
        return this.TOSList != null && this.TOSList.length > 0;
    }

    get SelectedTOS(): LegalAgreementModel {
        return this.TOSList.find(tos => { return tos.ClientID == this.SelectedClientID; });
    }

    get SelectedTOSText(): string {
        return this.SelectedTOS == null ? "" : this.SelectedTOS.Text;
    }

    DisplayClientName(clientID: number): string {
        if (clientID == null) {
            return "PatientXchange"
        } else {
            let client = GetClientByID(this, clientID);
            return client == null ? "" : client.Name;
        }
    }

    SelectTOS(clientID: number) {
        this.SelectedClientID = clientID;
        this.IsTOSSelected = true;
    }

    ClearTOSSelection() {
        this.SelectedClientID = null;
        this.IsTOSSelected = false;
    }

    Close() {
        this.$modal.hide('tosModal');
        this.ClearTOSSelection();
        this.TOSList = null;
        this.CanClickToClose = false;
        this.DisplayOkayButton = false;
    }

    beforeOpen(event: any) {
        this.SelectedClientID = null;
        this.IsTOSSelected = false;
        this.TOSList = event.params.TOSList;
        this.CanClickToClose = event.params.CanClickToClose;
        this.DisplayOkayButton = event.params.DisplayOkayButton;
    }
}
