import Vue from "vue"
import VueRouter from "vue-router";
import { ClientMetaDataModel, IDNamePairModel, LoginDataModel, LoginResponseModel, PersonModel, UserDataModel } from '../models/Common';
import { AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { DemoVerificationOperatorEnum, DemoVerificationTypeEnum, GenderEnum } from "../models/Enums";
import { DemoVerificationModel, InsuranceInfoModel } from "../models/TestEntry";
import { VaccineDoseModel } from "../models/VaccineCards";
import { AppStateModel } from "../store";
import { Ajax } from '@frhc/scripts/TS/Ajax';

Vue.mixin({
    methods: {
        SkipToNumberInput(fieldRefName: string, index: number) {
            let nextField = this.$refs[fieldRefName] as any; //NumberInput Component
            nextField = index != null && nextField != null && nextField.length > index ? nextField[index] : nextField; //Account for arrays of refs

            if (nextField != null && (nextField.value == null || nextField.value.length == 0)) {
                nextField.$el.focus();
            }
        }
    }
});

export function StringHasValue(data: string) {
    return data != null && data.length > 0;
}

//Use deep link navigation anytime we want to navigate to a page that is more than one path deep of our current route
export function DeepLinkNavigation(router: VueRouter, path: string): void {
    let navArray = path.charAt(0) == '/' ? path.substr(1).split("/") : path.split("/");

    navArray.forEach((routeString: string, i: number, array: string[]) => {
        let pathStr: string = "/";

        if (i > 0) {
            //Include paths before this
            pathStr += (array.slice(0, i).join("/")) + "/";
        }

        //Add current level in route
        pathStr += routeString;

        //Decide whether to include query string (only include at the end)
        if (i == (array.length - 1)) {
            router.push({ path: pathStr, query: GetQueryParamsObject(path) });
        } else {
            router.push({ path: pathStr });
        }
    });
}

export function LoginNavigationRouting(router: VueRouter, userData: UserDataModel) {
    let tosList = userData.TermsOfServiceList;
    let hasAccountToLink = userData.DependentFirstName != null && router.currentRoute.query.p != null;

    if (hasAccountToLink) { //Check if there is an account to link after logging in
        router.push({ name: "Verify Patient", query: router.currentRoute.query, hash: router.currentRoute.hash });
    } else if (tosList != null && tosList.length > 0) { //Check if there is a TOS that needs to be accepted
        router.push({ name: "Terms of Service", hash: router.currentRoute.hash });
    } else if (router.currentRoute.hash != null && router.currentRoute.hash.length > 1) { //Check if there is a path in hash to deep-link
        DeepLinkNavigation(router, router.currentRoute.hash.substring(1));
    } else { //Navigate to dashboard
        router.push({ name: "Dashboard" });
    }
}

//Returns the URL Query string as a query object for router.
function GetQueryParamsObject(path: string): any {
    let queryArray = path.split("?");
    let queryObject = null as any;

    if (queryArray.length > 1) {
        queryObject = {} as any;

        queryArray[1].split("&").forEach((param) => {
            let paramArray = param.split("=");
            queryObject[paramArray[0]] = paramArray[1];
        });
    }

    return queryObject;
}

export function GetClientByID(self: any, clientID: number): ClientMetaDataModel {
    if (clientID == 0 || clientID == null) {
        return null;
    }

    return (self.$store.state as AppStateModel).UserData.ClientList.find(c => { return c.ID == clientID; });
}

//This method should ONLY be used to update login data as a result of client config changes.
//Our login and refresh page functions have additional steps outside the scope of this method
export function RefreshLoginData(self: any) {
    Ajax.POST("/App/RefreshLoginData",
        null,
        (result: AjaxResponse) => {
            let loginData: LoginDataModel = { AuthToken: result.AuthToken, LoginData: result.Data as LoginResponseModel } as LoginDataModel;

            self.$store.commit('Login', loginData);
        },
        () => { },
        self.$store.getters.GenerateAuthHeaders,
        false
    );
}

export function IsDOBValid(month: string, day: string, year: string) {
    let dobString = `${month}/${day}/${year}`;
    let dobDate = new Date(dobString);

    return !isNaN(dobDate.getTime()) && dobString == ((dobDate.getMonth() + 1) + "/" + dobDate.getDate() + "/" + dobDate.getFullYear()) && dobDate.getTime() < (new Date).getTime();
}

//Important Note: insuranceData should always be an empty POJO with ONLY the properties you wish to change filled in. This will avoid the taxing operation of shipping the entire model up
export function UpdateInsuranceData(insuranceList: InsuranceInfoModel[], index: number, newIndex: number, insuranceData: InsuranceInfoModel) {
    if (index == insuranceList.length) {
        //Add new insurance
        insuranceList.push(new InsuranceInfoModel());
    } else if (newIndex != null) {
        //Change COB Order
        let tempInsurance = insuranceList.splice(index, 1)[0];
        insuranceList.splice(newIndex, 0, tempInsurance);
    } else if (insuranceData == null) {
        //Remove insurance
        insuranceList.splice(index, 1);
    } else {
        //Update data
        for (var key in insuranceData) {
            let insToUpdate = insuranceList[index];
            if (insuranceData.hasOwnProperty(key) && insToUpdate.hasOwnProperty(key)) {
                (insToUpdate as any)[key] = (insuranceData as any)[key];
            }
        }
    }
}

export function FillPatientWithLicenseData(personModel: PersonModel, licenseData: any, fillGender: boolean = false) {
    if (licenseData.DAC != null) {
        personModel.FirstName = licenseData.DAC.content;
    } else if (licenseData.DCT != null) {
        //This version of the first name field can contain middle name
        if (licenseData.DCT.content != null && (licenseData.DCT.content as string).indexOf(",") > -1) {
            let nameArray: string[] = (licenseData.DCT.content as string).split(",");
            personModel.FirstName = CleanLicenseField(nameArray[0]);
            personModel.MiddleName = CleanLicenseField(nameArray[1]);
        } else {
            personModel.FirstName = CleanLicenseField(licenseData.DCT.content);
        }
    }

    //Check if it's null first so we don't overwrite what the lines above may have added to middle name
    if (licenseData.DAD != null) {
        personModel.MiddleName = CleanLicenseField(licenseData.DAD.content);
    }

    personModel.LastName = licenseData.DCS.content;

    personModel.Address = licenseData.DAG.content +
        (licenseData.DAH != null && CleanLicenseField(licenseData.DAH.content) != null ? ", " + licenseData.DAH.content : "");
    personModel.City = licenseData.DAI.content;
    personModel.State = licenseData.DAJ.content;
    personModel.Zip = licenseData.DAK.content != null ? licenseData.DAK.content.substr(0, 5) : "";

    if (licenseData.DBB.content != null && licenseData.DBB.content.length > 0) {
        //Set DOB fields and strip off any leading 0's
        personModel.DOB_Month = parseInt(licenseData.DBB.content.substring(0, 2)).toString();
        personModel.DOB_Day = parseInt(licenseData.DBB.content.substring(2, 4)).toString();
        personModel.DOB_Year = parseInt(licenseData.DBB.content.substring(4, 8)).toString();
    }

    if (fillGender) {
        let gender = CleanLicenseField(licenseData.DBC.content);
        let genderResult = gender == "1" ? GenderEnum.Male : gender == "2" ? GenderEnum.Female : null;
        personModel.Gender = genderResult;
    }
}

export function CleanLicenseField(value: string): string {
    return (value != null &&
        value.length > 0 &&
        value.toLowerCase() != "none" &&
        value.toLowerCase() != "null" &&
        value.toLowerCase() != "empty" ? value : null);
}

export function CreateDictionaryFromEnum(anEnum: any, displayFunction: Function = null): IDNamePairModel<number>[] {
    //TODO: Brian - Make filter logic better so it can accomodate other value types. The hasOwnProperty is true for both key and value
    return Object.keys(anEnum).filter(key => { return isNaN(key as any) && anEnum.hasOwnProperty(key); }).map(key => ({ ID: anEnum[key], Name: displayFunction != null ? displayFunction(anEnum[key]) : key } as IDNamePairModel<number>));
}

export function SortVaccineDoseList(vaccineDoseList: VaccineDoseModel[]): void {
    vaccineDoseList.sort((a, b) => {
        let aDate = `${a.Vaccination_Year}${a.Vaccination_Month.toString().padStart(2, "0")}${a.Vaccination_Day.toString().padStart(2, "0")}`;
        let bDate = `${b.Vaccination_Year}${b.Vaccination_Month.toString().padStart(2, "0")}${b.Vaccination_Day.toString().padStart(2, "0")}`;

        if (aDate === bDate) {
            return 0;
        } else if (aDate > bDate) {
            return -1;
        } else {
            return 1;
        }
    });
}

export function GetVerificationToDisplay(type: DemoVerificationTypeEnum, verificationList: DemoVerificationModel[], fieldValue: string): DemoVerificationModel {
    let applicableVerification = verificationList?.find(v => v.VerificationField == type);

    if (applicableVerification == null || !(fieldValue?.length > 0)) {
        return null;
    }

    switch (applicableVerification.VerificationField) {
        case DemoVerificationTypeEnum.Gender:
            return applicableVerification.Value != fieldValue ? applicableVerification : null;
        case DemoVerificationTypeEnum.DOB:
            let age = CalculateAge(fieldValue);

            if (applicableVerification.Operator == DemoVerificationOperatorEnum.GreaterThanEqualTo && age < parseInt(applicableVerification.Value)) {
                return applicableVerification;
            } else if (applicableVerification.Operator == DemoVerificationOperatorEnum.LessThanEqualTo && age > parseInt(applicableVerification.Value)) {
                return applicableVerification;
            } else {
                return null;
            }
        default:
            return null;
    }
}

export function CalculateAge(dob: string) {
    if (dob == null || dob.length == 0) {
        return null;
    }

    let today = new Date();
    let birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}