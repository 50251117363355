














import { Component, Vue } from "vue-property-decorator";
    import InactivityTimer from "@/components/modals/InactivityTimer.vue";
    import LoadingTransition from "@/components/LoadingTransition.vue";
    import NetworkBanner from "@/components/NetworkBanner.vue";
    import ProfileHeader from "@/components/ProfileHeader.vue";
    import MessageModal from "@/components/modals/MessageModal.vue";
    import InteractiveModal from "@/components/modals/InteractiveModal.vue";
    import RefreshAppModal from "@/components/modals/RefreshAppModal.vue";
    import SupportModal from "@/components/modals/SupportModal.vue";
    import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
    import { AuthenticationManagerModel } from "@/models/Authentication";
    import { LoginDataModel, LoginResponseModel, UserDataModel } from '@/models/Common';
    import { ConfigDataModel, RefreshLoginDataRequestModel } from '@/models/Common';
    import { ApplicationInsights } from '@microsoft/applicationinsights-web';
    import Footer from '@/components/Footer.vue';
    import { LoginNavigationRouting } from "@/utils/GlobalFunctions";

    @Component({
        components: { ProfileHeader, Footer, LoadingTransition, InactivityTimer, InteractiveModal, MessageModal, NetworkBanner, RefreshAppModal, SupportModal }
    })
    export default class App extends Vue {
        AuthenticationManager: AuthenticationManagerModel = null;

        created() {
            this.$store.commit('SetIsLoading', true);

            //Custom event dispatched by our register sw js to alert the user an update is available. The modal popup will be delayed until after login
            document.addEventListener("PWAUpdateAvailable", () => {
                //Since the check for this is delayed, figure out if we are currently logged in first
                if (this.$store.getters.IsSignedIn) {
                    this.$modal.show('appRefreshModal');
                } else {
                    this.$store.commit('SetShowRefreshModal', true);
                }
            });

            //Bind appropriate functions to Ajax
            Ajax.AddGlobalStartFunction(() => { this.$store.commit('SetIsLoading', true); });
            Ajax.AddGlobalFinallyFunction(() => { this.$store.commit('SetIsLoading', false); });
            Ajax.SetUnauthorizedFunction(() => { this.$store.commit('SetIsLoading', false); this.Logout("Please log in again."); });

            let configResult: AjaxResponse = null;
            let loginResult: AjaxResponse = null;

            let configPromise = Ajax.POST("/App/FetchConfig",
                null,
                (result: AjaxResponse) => {
                    configResult = result;
                },
                () => { },
                null,
                false
            );

            let loginDataPromise = this.$store.state.AuthToken == null ? null : //Set promise to null if there is no auth token to check login with
                Ajax.POST("/App/RefreshLoginData",
                    { PatientGuid: this.$router.currentRoute.query.p } as RefreshLoginDataRequestModel, //In case user is hitting the site to link account with a PatientGuid when they are already logged in
                    (result: AjaxResponse) => {
                        if (this.$router.currentRoute.path != "" && this.$router.currentRoute.path != "/" && (this.$router.currentRoute.hash == null || this.$router.currentRoute.hash.length == 0)) {
                            let toQuery = this.$router.currentRoute.query.p != null && this.$router.currentRoute.query.p.length > 0 ? { p: this.$router.currentRoute.query.p } : {};
                            this.$router.replace({ path: '/', query: toQuery, hash: this.$router.currentRoute.fullPath });
                        }

                        loginResult = result;
                    },
                    () => { },
                    this.$store.getters.GenerateAuthHeaders,
                    false
                );

            //Wait for all promises to resolve
            Promise.all([configPromise, loginDataPromise]).then(() => {
                //Config
                if (configResult != null && configResult.WasSuccess) {
                    let configData = configResult.Data as ConfigDataModel;
                    this.$store.commit('SetConfig', configData);
                    this.InitAppInsights(configData.InsightsInstrumentationKey);
                    this.InitDSBR();
                } else {
                    this.$router.push({ name: "Error" });
                }

                this.AuthenticationManager = new AuthenticationManagerModel(this.$store.state.SessionResetTime);

                //Login
                if (loginResult != null && loginResult.WasSuccess) {
                    let loginData: LoginDataModel = { AuthToken: loginResult.AuthToken, LoginData: loginResult.Data as LoginResponseModel } as LoginDataModel;

                    this.Login(loginData);
                } else {
                    this.$store.commit('Logout');
                }
            }).finally(() => {
                //Done loading the app
                this.$store.commit('SetIsLoading', false);
            });
        }

        InitAppInsights(instrumentationKey: string) {
            //Register app insights
            let appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,
                    enableAutoRouteTracking: true
                }
            });
            appInsights.loadAppInsights();
            appInsights.trackPageView(); //Manually call trackPageView to establish the current user/session/pageview
        }

        InitDSBR() {
            if (window.DSScanner == null) {
                //Define global var so DSBR knows where the wasm file is
                window.DEF_WASM_PATH = '/dsbr/datasymbol-sdk.wasm';

                //Loads DSBR helper script
                let dsbrScript = document.createElement('script');
                dsbrScript.setAttribute('src', '/dsbr/datasymbol-sdk-hlp.min.js');
                document.head.appendChild(dsbrScript);
            }
        }

        Login(loginResponse: LoginDataModel) {
            //Store the data
            this.$store.commit('Login', loginResponse);

            this.LoginNavigation();

            //Start the timer for keeping token alive
            this.AuthenticationManager.StartTokenKeepAlive();

            //Start inactivity timer
            (this.$refs.inactivityTimer as any).StartTimer();

            if (this.$store.state.ShowRefreshModal) {
                this.$modal.show('appRefreshModal');
                this.$store.commit('SetShowRefreshModal', false);
            }
        }

        LoginNavigation() {
            let userData = (this.$store.state.UserData as UserDataModel);
            LoginNavigationRouting(this.$router, userData);
        }

        Logout(logoutMessage: string = null) {
            this.$store.commit('Logout');
            this.$router.push({ name: 'Login', query: logoutMessage != null ? { message: logoutMessage } as any : null });

            //Stop the timer for keeping token alive
            this.AuthenticationManager.EndTokenKeepAlive();

            //Stop inactivity timer
            (this.$refs.inactivityTimer as any).StopTimer();
        }
    }