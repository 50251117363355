











import { Component, Vue } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { UserDataModel } from '@/models/Common';
import { LegalAgreementModel } from '@/models/TOS';

@Component({
    components: { }
})
export default class TOS extends Vue {
    TOSList: LegalAgreementModel[] = [];
    CurrentTOSIndex: number = 0;

    created() {
        this.TOSList = this.$store.state.UserData.TermsOfServiceList as LegalAgreementModel[];

        //If the TOS was blank (user navigated here manually), bump them out of this page
        if (this.TOSList == null || this.TOSList.length == 0) {
            this.$emit('LoginNavigation');
        }
    }

    get TOSCount() {
        return this.TOSList.length;
    }

    get DisplayClientName(): string {
        let currentTOSClientID = this.TOSList[this.CurrentTOSIndex].ClientID;

        //Null Client ID is PX's TOS.
        if (currentTOSClientID != null) {
            let client = (this.$store.state.UserData as UserDataModel).ClientList.find((c) => { return c.ID == currentTOSClientID; });
            return client != null ? client.Name : "";
        } else {
            return "PatientXchange";
        }
        
    }

    get DisplayTOS(): string {
        return this.TOSList[this.CurrentTOSIndex].Text;
    }

    AcceptTOS(): void {
        if (this.CurrentTOSIndex < this.TOSCount - 1) {
            this.CurrentTOSIndex++;
        } else {
            Ajax.POST("/App/AcceptAllTOS",
                null,
                (result: AjaxResponse) => {
                    if (result.WasSuccess) {
                        this.$store.commit('ClearTOS');
                        this.$emit('LoginNavigation');
                    } else {
                        this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                    }
                },
                () => {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                },
                this.$store.getters.GenerateAuthHeaders
            );
        }
    }
}
