import { render, staticRenderFns } from "./VaccineSelectionModal.vue?vue&type=template&id=10970e86&"
import script from "./VaccineSelectionModal.vue?vue&type=script&lang=ts&"
export * from "./VaccineSelectionModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports