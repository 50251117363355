import { AnswerTypeEnum, AOEValidationEnum, ConsentDataTypeEnum, DemoVerificationOperatorEnum, DemoVerificationTypeEnum, FeatureEnum, TestEntryErrorEnum, TestModeEnum, TestResultEnum } from '@/models/Enums';
import { PersonModel } from './Common';

export class TestEntryConfigModel {
    AOEQuestionList: AOEQuestionModel[];
    BarcodeRequirement: BarcodeRequirementModel;
    ConsentConfigList: ConsentConfigModel[];
    FeatureList: FeatureEnum[];
    PaymentTypeList: PaymentTypeConfigModel[];
    TestResultList: TestEntryResultModel[];
    DemoVerifications: DemoVerificationModel[];
    TestMode: TestModeEnum;
    ClientName: string;
    LocationName: string;
    SupportEmailAddress: string;
    SupportPhoneNumber: string;
    DefaultPaymentTypeConfigID: number;
    LabxchangeClientID: number;
}

export class TestEntryDataModel {
    BarcodeRequirement: BarcodeRequirementModel;
    FeatureList: FeatureEnum[];
    PaymentTypeList: PaymentTypeConfigModel[];
    AOEQuestionList: AOEQuestionModel[];
    ConsentConfigList: ConsentConfigModel[];
    DemoVerifications: DemoVerificationModel[];
    SupportEmailAddress: string;
    SupportPhoneNumber: string;
    DefaultPaymentTypeConfigID: number;

    TestMode: TestModeEnum;
    ClientName: string;
    LocationName: string;
    LabxchangeClientID: number;

    constructor(configData: TestEntryConfigModel) {
        this.BarcodeRequirement = configData.BarcodeRequirement;
        this.FeatureList = configData.FeatureList;
        this.PaymentTypeList = configData.PaymentTypeList;
        this.AOEQuestionList = configData.AOEQuestionList;
        this.ConsentConfigList = configData.ConsentConfigList;
        this.DemoVerifications = configData.DemoVerifications;
        this.SupportEmailAddress = configData.SupportEmailAddress;
        this.SupportPhoneNumber = configData.SupportPhoneNumber;
        this.DefaultPaymentTypeConfigID = configData.DefaultPaymentTypeConfigID;

        this.TestMode = configData.TestMode;
        this.ClientName = configData.ClientName;
        this.LocationName = configData.LocationName;
        this.LabxchangeClientID = configData.LabxchangeClientID;
    }
}

export class TestRecordModel {
    ID: string;
    ActivationCode: string;
    TestGuid: string;
    CreateDate: string;

    Patient: PersonModel;
    Guarantor: PersonModel;
    Guardian: PersonModel;

    InsuranceList: InsuranceInfoModel[];
    PaymentTypeConfigID: number;
    ConsentSignatureImage: string;
    DriverLicenseFrontImage: string;
    DriverLicenseBackImage: string;
    PaymentAdditionalInput: string;

    AOEAnswerList: AOEAnswerModel[];
    ConsentAgreementList: ConsentAgreementModel[];
    Signature: string;

    TestResults: TestEntryResultModel[];

    SaveProfiles: boolean = false;

    constructor(id: string, activationCode: string, testGuid: string, testResultList: TestEntryResultModel[]) {
        this.ID = id;
        this.ActivationCode = activationCode;
        this.TestGuid = testGuid;
        this.CreateDate = null;

        this.Patient = new PersonModel();
        this.Guarantor = null;
        this.Guardian = null;

        this.PaymentTypeConfigID = null;
        this.ConsentSignatureImage = null;
        this.DriverLicenseFrontImage = null;
        this.DriverLicenseBackImage = null;
        this.PaymentAdditionalInput = null;
        this.InsuranceList = [];

        this.AOEAnswerList = [];
        this.ConsentAgreementList = [];
        this.Signature = null;

        this.TestResults = testResultList;

        this.SaveProfiles = false;
    }
}

export class PaymentTypeConfigModel {
    PaymentTypeConfigID: number;
    Code: string;
    HyperlinkName: string
    HyperlinkURL: string
    HyperlinkDescription: string
    Description: string;
    Disclaimer: string;
    RunInsuranceDiscovery: boolean;
    RunDemographics: boolean;
    RunEligibility: boolean;
    ShowInsuranceSection: boolean;
    ShowLicenseSection: boolean;
    ShowAdditionalInput: boolean;
    AdditionalInputLabel: string;
    AdditionalInputRequired: boolean;
    ImageCaptureLabel: string;

    constructor() {
        this.PaymentTypeConfigID = null;
        this.Code = null;
        this.HyperlinkName = null;
        this.HyperlinkURL = null;
        this.HyperlinkDescription = null;
        this.Description = null;
        this.Disclaimer = null;
        this.RunInsuranceDiscovery = false;
        this.RunDemographics = false;
        this.RunEligibility = false;
        this.ShowInsuranceSection = false;
        this.ShowLicenseSection = false;
        this.ShowAdditionalInput = false;
        this.AdditionalInputLabel = null;
        this.AdditionalInputRequired = false;
        this.ImageCaptureLabel = null;
    }
}

export class InsuranceInfoModel {
    ProfileInsuranceID: number;
    InsuranceName: string;
    InsuranceMemberID: string;
    InsuranceCardFrontImageFileStorageID: number;
    InsuranceCardBackImageFileStorageID: number;
    InsuranceCardFrontImage: string;
    InsuranceCardBackImage: string;
    InsuranceMasterID: number;
    InsuranceTradingPartnerID: number; //If present, the insurance is mapped
    ShowRemovalConfirmation: boolean;

    constructor() {
        this.ProfileInsuranceID = null
        this.InsuranceName = null;
        this.InsuranceMemberID = null;
        this.InsuranceCardFrontImage = null;
        this.InsuranceCardBackImage = null;
        this.InsuranceMasterID = null;
        this.InsuranceTradingPartnerID = null;
        this.ShowRemovalConfirmation = false;
        this.InsuranceCardFrontImageFileStorageID = null;
        this.InsuranceCardBackImageFileStorageID = null;
    }
}

export class ConsentConfigModel {
    ConsentConfigID: number;
    Text: string;
    RequiresHandSignature: boolean;
    Required: boolean;
    ConsentFormLinkText: string;
    ConsentFormID: number; //ID of PDF
    ConsentDataType: ConsentDataTypeEnum;

    constructor() {
        this.ConsentConfigID = null;
        this.Text = null;
        this.RequiresHandSignature = false;
        this.Required = false;
        this.ConsentFormLinkText = null;
        this.ConsentFormID = null;
        this.ConsentDataType = ConsentDataTypeEnum.None;
    }
}

export class ConsentAgreementModel {
    ConsentConfigID: number;
    Text: string; //Only needed/used in result display
    ConsentDataType: ConsentDataTypeEnum;
    Accepted: boolean;
}

export class AOEAnswerModel {
    QuestionID: number;
    Answer: string;
}

export class AOESelectListOptionModel {
    ID: number;
    Value: string;

    constructor() {
        this.ID = null;
        this.Value = "";
    }
}

export class AOEQuestionModel {
    QuestionID: number;
    QuestionCode: string;
    AnswerType: AnswerTypeEnum;
    QuestionText: string;
    ValidationList: AOEValidationEnum[];
    SelectListOptions: AOESelectListOptionModel[]; //Only if AnswerType is SelectList
    ParentQuestionID: number;
    ParentQuestionAnswer: string;
    NumericalMin: number;
    NumericalMax: number;
    EnforceNumericalLimits: boolean;
    CustomWarningMessage: string;

    constructor() {
        this.QuestionID = null;
        this.QuestionCode = "";
        this.AnswerType = null;
        this.QuestionText = "";
        this.SelectListOptions = [];
        this.ParentQuestionID = null;
        this.ParentQuestionAnswer = null;
        this.NumericalMin = null;
        this.NumericalMax = null;
        this.EnforceNumericalLimits = false;
        this.CustomWarningMessage = null;
    }
}

export class TestEntryResultModel {
    TestTypeID: number;
    TestTypeName: string;
    Result: TestResultEnum;
}

export class TestEntryErrorResponseModel {
    ErrorReason: TestEntryErrorEnum;
    FieldName: string;
}

export class BarcodeRequirementModel {
    StartsWith: string;
    MinimumLength: number;
    MaximumLength: number;
    Description: string;
}

export class InsuranceMasterModel {
    InsuranceMasterID?: number;
    InsuranceName: string;
    TradingPartnerID?: number; //If populated, insurance is mapped
}

export class DemoVerificationModel {
    DemoVerificationID: number;
    ClientID: number;
    Name: string;
    VerificationField: DemoVerificationTypeEnum;
    CustomWarningMessage: string;
    Value: string;
    Operator: DemoVerificationOperatorEnum;
    Enforced: boolean;

    constructor() {
        this.DemoVerificationID = null;
        this.ClientID = null;
        this.Name = null;
        this.VerificationField = null;
        this.CustomWarningMessage = null;
        this.Value = null;
        this.Operator = null;
        this.Enforced = false;
    }
}

export class TestEntryPrefill {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    cellPhone: string;
    paymentCustom: string;
}