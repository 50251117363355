



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccountSectionEnum } from '@/models/Enums';
import AboutSection from '@/components/account/AboutSection.vue';
import CommunicationSection from '@/components/account/CommunicationSection.vue';
import FeaturesSection from '@/components/account/FeaturesSection.vue';
import PatientProfiles from '@/components/account/PatientProfiles.vue';
import SecuritySection from '@/components/account/SecuritySection.vue';

@Component({
    components: { AboutSection, CommunicationSection, FeaturesSection, PatientProfiles, SecuritySection }
})
export default class Account extends Vue {
    @Prop() selectedSection: AccountSectionEnum;

    SectionEnum: any = AccountSectionEnum;

    private ChangeSection(section: AccountSectionEnum) {
        this.$router.push({ path: '/myaccount/' + (section != null ? section : '') });
    }
}
