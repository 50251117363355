




















































    import { Component, Prop, Vue } from "vue-property-decorator";
    import { Validations } from 'vuelidate-property-decorators';
    import { alphaNum, required,between, maxLength, minLength, numeric } from 'vuelidate/lib/validators';
    import { emailCaseInsensitive, inList, phoneNumber, specialCharBlacklist } from '@/utils/CustomValidations';
    import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
    import { PatientModel, PersonModel, ProductAlertModel, ProfileModel } from '@/models/Common';
    import { GenderEnum, MessageSeverity, SelectionMode } from '@/models/Enums';
    import FormMessage from '@/components/FormMessage.vue';
    import PersonSection from '@/components/PersonSection.vue';
    import RemovalWarningModal from "@/components/modals/RemovalWarningModal.vue";
    import InsuranceListSection from '@/components/testentry/InsuranceListSection.vue';
    import { IsDOBValid, UpdateInsuranceData } from '@/utils/GlobalFunctions';
    import Checkbox from "@/components/inputs/Checkbox.vue";
    import { CreateFormData } from '@frhc/scripts/TS/Utilities';


import { InsuranceInfoModel } from "../models/TestEntry";

    @Component({
        components: { FormMessage, PersonSection, RemovalWarningModal, InsuranceListSection, Checkbox }
    })
    export default class ProfileManagement extends Vue {
        @Prop() canSelect!: boolean;

        ProfileList: ProfileModel[] = [];
        Mode: SelectionMode = null;
        TempProfile: ProfileModel = null;
        InsuranceList: InsuranceInfoModel[] = [];
        EmailConsent: boolean = false;
        SMSConsent: boolean = false;

        FormMessage: string = null;
        FormMessageSeverity: MessageSeverity = null;
        DisplaySupportLink: boolean = false;
        GenderList: string[] = Object.keys(GenderEnum).map((key) => { return (GenderEnum as any)[key] as string; });

        UpdateInsuranceData: Function = UpdateInsuranceData;

        created() {
            this.ClearFormMessage();

            if (this.$store.state.UserData != null && this.$store.state.UserData.ProfileList != null) {
                this.ProfileList = JSON.parse(JSON.stringify(this.$store.state.UserData.ProfileList)) as ProfileModel[];
                this.Mode = SelectionMode.Selection;
                this.$emit('ModeChanged', this.Mode);
            }
        }

        @Validations()
        validations() {
            return {
                TempProfile: {
                    Person: {
                        FirstName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                        MiddleName: { specialCharBlacklist, maxLength: maxLength(50) },
                        LastName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                        Address: { specialCharBlacklist, maxLength: maxLength(75) },
                        City: { specialCharBlacklist, maxLength: maxLength(75) },
                        State: { inList: ((field: string) => { return inList(field, this.$store.state.StateList); }) },
                        Zip: { maxLength: maxLength(5), minLength: minLength(5), numeric: numeric },
                        DOBValid: (val: PersonModel) => IsDOBValid(val.DOB_Month, val.DOB_Day, val.DOB_Year) === true,
                        DOB_Month: { required, between: between(1, 12) },
                        DOB_Day: { required, between: between(1, 31) },
                        DOB_Year: { required, between: between(1900, new Date().getFullYear()) },
                        Gender: { inList: ((field: string) => { return inList(field, this.GenderList); }) },
                        Ethnicity: { inList: ((field: string) => { return inList(field, this.$store.getters.EthnicityValuesList); }) },
                        Race: { inList: ((field: string) => { return inList(field, this.$store.getters.RaceValuesList); }) },
                        MedicalRecordNumber: { alphaNum },
                        CellPhone: { phoneNumber, maxLength: maxLength(20) },
                        EmailAddress: { specialCharBlacklist, email: emailCaseInsensitive, maxLength: maxLength(120) },
                    },
                    InsuranceList: {
                        $each: {
                            InsuranceName: { specialCharBlacklist, required },
                            InsuranceMemberID: { specialCharBlacklist, required },
                        }
                    }
                },              
            };
        }

        get IsProfileSelectionMode() {
            return this.Mode == SelectionMode.Selection;
        }

        get IsProfileEditMode() {
            return this.Mode == SelectionMode.Edit;
        }

        get HasFormMessage() {
            return this.FormMessage != null && this.FormMessage.length > 0;
        }

        SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
            this.FormMessage = message;
            this.FormMessageSeverity = severity;
            this.DisplaySupportLink = showSupportLink;
        }

        ClearFormMessage(): void {
            this.FormMessage = null;
            this.FormMessageSeverity = null;
            this.DisplaySupportLink = false;
        }

        CancelProfileEdit() {
            this.Mode = SelectionMode.Selection;
            this.$emit('ModeChanged', this.Mode);
            this.TempProfile = null;
            this.$v.$reset();
        }

        SelectProfile(profile: ProfileModel) {
            if (profile.InsuranceList.some(ins => { return (ins.InsuranceCardBackImageFileStorageID != null && ins.InsuranceCardBackImage == null) || (ins.InsuranceCardFrontImageFileStorageID != null && ins.InsuranceCardFrontImage == null); })) {
                this.FetchInsuranceImages(profile, (profile:ProfileModel) => { this.$emit('ProfileSelect', profile); }, false);
            } else {
                this.$emit('ProfileSelect', profile);
            }
        }

        NewProfile() {
            this.ClearFormMessage();
            this.TempProfile = new ProfileModel();
            this.Mode = SelectionMode.Edit;
            this.$emit('ModeChanged', this.Mode);
        }

        EditProfile(profile: ProfileModel) {
            this.ClearFormMessage();
            if (profile.InsuranceList.some(ins => { return (ins.InsuranceCardBackImageFileStorageID != null && ins.InsuranceCardBackImage == null) || (ins.InsuranceCardFrontImageFileStorageID != null && ins.InsuranceCardFrontImage == null); })) {
                this.FetchInsuranceImages(profile, this.EditProfileCallback);
            } else {
                this.EditProfileCallback(profile);
            }
        }

        EditProfileCallback(profile: ProfileModel) {
            this.TempProfile = JSON.parse(JSON.stringify(profile));

            this.Mode = SelectionMode.Edit;
            this.$emit('ModeChanged', this.Mode);

            if (this.TempProfile.InsuranceList.length == 0) {
                this.TempProfile.InsuranceList.push(new InsuranceInfoModel());
            } else {
                for (var i = 0; i < this.TempProfile.InsuranceList.length; i++) {
                    Vue.set(this.TempProfile.InsuranceList[i], 'ShowRemovalConfirmation', false);
                }
            }
        }

        OpenRemoveProfileModal() {
            this.$modal.show('removalWarningModal', {
                Title: 'Please Confirm', Message: 'Are you sure you want to remove <span class="bold"> ' + this.$options.filters.FormatPatientNameForDisplay(this.TempProfile.Person) + ' </span> from the list?', Callback: () => { this.RemoveProfile(); }
            });
        }

        FetchInsuranceImages(profile: ProfileModel, callbackFunction:Function, globalAjax = true){
            return Ajax.POST("/App/FetchProfileInsuranceImages",
                profile,
                (result: AjaxResponse) => {
                    if (result.WasSuccess) {
                        if (result.Data.InsuranceList != null) {
                            (result.Data as ProfileModel).InsuranceList.forEach((ins, index) => {
                                profile.InsuranceList[index].InsuranceCardFrontImage = ins.InsuranceCardFrontImage;
                                profile.InsuranceList[index].InsuranceCardBackImage = ins.InsuranceCardBackImage;
                            });
                        }
                        callbackFunction(profile);
                    } else {
                        this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                    }
                },
                () => {
                    this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                },
                this.$store.getters.GenerateAuthHeaders, globalAjax
            );
        }

        RemoveProfile() {
            Ajax.POST("/App/RemoveProfile",
                this.TempProfile.Person.PersonID,
                (result: AjaxResponse) => {
                    if (result.WasSuccess) {
                        let profileIndex = this.ProfileList.findIndex(p => { return p.Person.PersonID == this.TempProfile.Person.PersonID; });

                        this.ProfileList.splice(profileIndex, 1);
                        this.$store.commit("RemoveProfile", this.TempProfile.Person.PersonID);
                        this.CancelProfileEdit();
                    } else {
                        this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                    }
                },
                () => {
                    this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                },
                this.$store.getters.GenerateAuthHeaders
            );
        }

        SaveProfile() {
            this.ClearFormMessage();

            this.$v.$touch();

            if (this.$v.$anyError) {
                this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
                return;
            }

            let uploadForm = CreateFormData(this.TempProfile, ["InsuranceCardFrontImage", "InsuranceCardBackImage"]);

            Ajax.POST_MULTIPART("/App/SaveProfile",
                uploadForm,
                (result: AjaxResponse) => {
                    if (result.WasSuccess) {

                        this.TempProfile.Person.PersonID = result.Data.Person.PersonID as number;

                        if (result.Data.InsuranceList != null) {
                            (result.Data as ProfileModel).InsuranceList.forEach((ins, index) => {
                                this.TempProfile.InsuranceList[index].ProfileInsuranceID = ins.ProfileInsuranceID;
                                this.TempProfile.InsuranceList[index].InsuranceCardFrontImageFileStorageID = ins.InsuranceCardFrontImageFileStorageID;
                                this.TempProfile.InsuranceList[index].InsuranceCardBackImageFileStorageID = ins.InsuranceCardBackImageFileStorageID;
                            });
                        }

                        this.$store.commit("SaveProfile", this.TempProfile);

                        let profileIndex = this.ProfileList.findIndex(p => { return p.Person.PersonID == this.TempProfile.Person.PersonID; });
                        if (profileIndex == -1) {
                            this.ProfileList.push(JSON.parse(JSON.stringify(this.TempProfile)));
                        } else {
                            this.ProfileList.splice(profileIndex, 1, JSON.parse(JSON.stringify(this.TempProfile)));
                        }

                        this.CancelProfileEdit();
                    } else {
                        this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                    }
                },
                () => {
                    this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                },
                this.$store.getters.GenerateAuthHeaders
            );
        }

        
    }
