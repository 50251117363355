














// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class NumberInput extends Vue {
    @Prop() value!: string;
    @Prop() maxLength!: number;
    @Prop() max: number;
    @Prop() min: number;
    @Prop() placeholder: string;
    @Prop() isInvalid: boolean;
    @Prop() isDisabled: string;
    @Prop() displayInputWarning!: boolean
    @Prop() toFixed!: number
    @Prop() customWarningMessage!: string

    IsToolTipDisplaying: boolean = false
    InputWarningMessage: string = null;

    UpdateValue(event: any) {
        this.InputWarningMessage = null;
        this.IsToolTipDisplaying = false;
        let newValue = event.target.value;

        if (this.toFixed != null && newValue != null && newValue.indexOf(".") > -1 && (newValue.split('.')[1].length > this.toFixed))
            newValue = newValue.substring(0, newValue.indexOf(".") + this.toFixed + 1);

        if (this.max != null && parseFloat(newValue) > this.max) {
            if (this.displayInputWarning) {
                this.InputWarningMessage = this.customWarningMessage != null && this.customWarningMessage.length > 0 ? this.customWarningMessage : "Maximum value: " + this.max;
            } else {
                newValue = this.max;
            }
        }

        if (this.min != null && parseFloat(newValue) < this.min) {
            if (this.displayInputWarning) {
                this.InputWarningMessage = this.customWarningMessage != null && this.customWarningMessage.length > 0 ? this.customWarningMessage : "Minimum value: " + this.min;
            } else {
                newValue = this.min;
            }
        }

        if (String(newValue).length <= this.maxLength) {
            const number = parseFloat(newValue);

            this.$emit("input", isNaN(number) ? newValue : number);
            this.$emit("change"); //Useful for tacking on other callbacks
        }

        //Signal to parent, the field is at max length, in case we want to field skip
        if (String(newValue).length == this.maxLength) {
            this.$emit("inputComplete");
        }

        this.$forceUpdate();
    }
}
