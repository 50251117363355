






































import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingTransition from "@/components/LoadingTransition.vue";
import { Validations } from 'vuelidate-property-decorators';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import Browser from '@/utils/Browser';
import { MessageSeverity, TestActivationExceptionEnum, TestModeEnum } from '@/models/Enums'
import FormMessage from '@/components/FormMessage.vue';
import { TestEntryConfigModel, TestRecordModel } from '../models/TestEntry';

enum ManualInputModeEnum {
    ActivationType = 1,
    GuidType = 2
}

@Component({
    components: { FormMessage, LoadingTransition }
})
export default class TestEntryVerify extends Vue {
    @Prop() testid: string;
    @Prop() activationcode: string;
    @Prop() testguid: string;

    ManualInputMode: ManualInputModeEnum = null;
    TestID: string = "";
    ActivationCode: string = "";
    TestGuid: string = "";

    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;
    ManualInputModeEnum: any = ManualInputModeEnum;

    @Validations()
    validations() {
        return {
            ManualInputMode: { required },
            TestID: { requiredIf: requiredIf(() => { return this.ManualInputMode == ManualInputModeEnum.ActivationType; }) },
            ActivationCode: { requiredIf: requiredIf(() => { return this.ManualInputMode == ManualInputModeEnum.ActivationType; }) },
            TestGuid: { requiredIf: requiredIf(() => { return this.ManualInputMode == ManualInputModeEnum.GuidType; }) },
        };
    }

    created() {
        //Reset test entry data
        this.$store.commit("ClearTestEntryData");
        
        if (this.testid != null && this.activationcode != null) {
            this.FetchActivationCodeTestConfig(this.testid, this.activationcode);
        } else if (this.testguid != null) {
            this.FetchGuidTestConfig(this.testguid);
        } else if (this.$router.currentRoute.path.split("/")[1].toLowerCase() == "testentrylanding") {
            //No deep link
        } else {
            //Failed deep link
            this.SetFormMessage("The link you used is no longer valid. You can try entering the information manually below.", MessageSeverity.HIGH);
        }
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    SetFormMessage(message: string, severity: MessageSeverity): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        Browser.SmoothScroll(0);
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
    }

    GetURLQueryString() {
        return window.location.search != null && window.location.search.length > 0 ? window.location.search : null;
    }

    Submit() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        if (this.ManualInputMode == ManualInputModeEnum.ActivationType) {
            this.FetchActivationCodeTestConfig(this.TestID, this.ActivationCode);
        } else {
            this.FetchGuidTestConfig(this.TestGuid);
        }
    }

    FetchGuidTestConfig(requestTestGuid: string) {
        Ajax.POST("/LabTest/FetchPatientIntakeTestConfig",
            requestTestGuid,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let testEntryConfig = result.Data as TestEntryConfigModel;
                    let testRecord = new TestRecordModel(null, null, requestTestGuid, testEntryConfig.TestResultList);

                    this.$store.commit("LoadTestEntryData", testEntryConfig);

                    this.$router.push({ name: "Registration", params: { testRecord: testRecord, storedGuardian: null, storedGuarantor: null, urlParams: this.GetURLQueryString() } as any });
                } else {
                    this.SetFormMessage("There was an issue processing your request. Please try again.", MessageSeverity.HIGH);
                }
            },
            () => {
                this.SetFormMessage("There was an issue processing your request. Please try again.", MessageSeverity.HIGH);
            });
    }

    FetchActivationCodeTestConfig(requestTestID: string, requestActivationCode: string) {
        Ajax.POST("/LabTest/FetchTestConfig",
            { TestID: requestTestID, ActivationCode: requestActivationCode },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let testEntryConfig = result.Data as TestEntryConfigModel;
                    let testRecord = new TestRecordModel(requestTestID, requestActivationCode, null, testEntryConfig.TestResultList);

                    this.$store.commit("LoadTestEntryData", testEntryConfig);

                    this.$router.push({ name: "Registration", params: { testRecord: testRecord, storedGuardian: null, storedGuarantor: null, urlParams: this.GetURLQueryString() } as any });
                } else {
                    let error = result.Data as TestActivationExceptionEnum;

                    switch (error) {
                        case TestActivationExceptionEnum.InvalidTestID:
                            this.SetFormMessage("The test id and activation code you have entered are incorrect. Please try again or contact support using the information provided in your test kit.", MessageSeverity.HIGH);
                            break;
                        case TestActivationExceptionEnum.AlreadySubmitted:
                            this.SetFormMessage("This test has already been submitted. If you believe this to be an error, please contact support using the information provided in your test kit.", MessageSeverity.HIGH);
                            break;
                        default:
                            this.SetFormMessage("There was an issue processing your request. Please try again or contact support using the information provided in your test kit.", MessageSeverity.HIGH);
                            break;
                    }
                }
            },
            () => {
                this.SetFormMessage("There was an issue processing your request. Please try again or contact support using the information provided in your test kit.", MessageSeverity.HIGH);
            });
    }
}
