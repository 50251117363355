import { register } from 'register-service-worker'

register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
        console.log('App is being served from cache by a service worker.');
    },
    registered() {
        console.log('Service worker has been registered.');
    },
    cached() {
        console.log('Content has been cached for offline use.');
    },
    updatefound() {
        console.log('New content is downloading.');
    },
    updated(reg: ServiceWorkerRegistration) {
        console.log('New content is available; please refresh.');

        //Dispatch event to be picked up by Vue to display notification to user that the app needs to be refreshed
        document.dispatchEvent(new Event("PWAUpdateAvailable"));
    },
    offline() {
        console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
        console.error('Error during service worker registration:', error);
    }
});