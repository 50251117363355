














































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { alphaNum, required, requiredIf, between, maxLength, minLength, numeric } from 'vuelidate/lib/validators';
import { AOEQuestionModel, AOEAnswerModel, ConsentAgreementModel, ConsentConfigModel, DemoVerificationModel, TestRecordModel, TestEntryErrorResponseModel, TestEntryPrefill, PaymentTypeConfigModel, TestEntryResultModel, InsuranceInfoModel } from '@/models/TestEntry';
import NumberInput from '@/components/inputs/NumberInput.vue';
import Signature from '@/components/testentry/Signature.vue';
import CameraOverlay from '@/components/modals/CameraOverlay.vue';
import SignatureOverlay from '@/components/modals/SignatureOverlay.vue';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { CreateFormData } from '@frhc/scripts/TS/Utilities';
import Browser from '@/utils/Browser';
import { PersonModel, ProfileModel } from '@/models/Common';
import { AnswerTypeEnum, AOEValidationEnum, CardSideEnum, ConsentDataTypeEnum, DemoVerificationTypeEnum, FeatureEnum, GenderEnum, MessageSeverity, ScannerModeEnum, TestEntryErrorEnum, TestModeEnum } from '@/models/Enums';
import FormMessage from '@/components/FormMessage.vue';
import ImageZoomDisplay from '@/components/ImageZoomDisplay.vue';
import { emailCaseInsensitive, inList, phoneNumber, specialCharBlacklist, startsWith, validTestResults } from '@/utils/CustomValidations';
import moment from 'moment';
import ScannerOverlay from '@/components/modals/ScannerOverlay.vue';
import FeatureButton from '@/components/testentry/FeatureButton.vue';
import AOEQuestionSection from '@/components/testentry/AOEQuestionSection.vue';
import PatientConsentSection from '@/components/testentry/PatientConsentSection.vue';
import InsuranceListSection from '@/components/testentry/InsuranceListSection.vue';
import TestResultUploadModal from '@/components/modals/TestResultUploadModal.vue';
import ProfileSelectionModal from '@/components/modals/ProfileSelectionModal.vue';
import PersonSection from '@/components/PersonSection.vue';
import { GetVerificationToDisplay, IsDOBValid, FillPatientWithLicenseData, UpdateInsuranceData, StringHasValue } from '@/utils/GlobalFunctions';
import Tooltip from '@/components/Tooltip.vue';
import VueRecaptcha from 'vue-recaptcha';
import Checkbox from '@/components/inputs/Checkbox.vue';


enum DataFillPersonTypeEnum {
    Patient = 1,
    Guardian = 2,
    Guarantor = 3
}

@Component({
    components: { AOEQuestionSection, InsuranceListSection, PatientConsentSection, FormMessage, PersonSection, ProfileSelectionModal, Signature, CameraOverlay, NumberInput, ImageZoomDisplay, ScannerOverlay, FeatureButton, SignatureOverlay, TestResultUploadModal, Tooltip, VueRecaptcha, Checkbox }
})
export default class TestEntry extends Vue {
    @Prop() testRecord: TestRecordModel;
    @Prop() storedGuardian: PersonModel;
    @Prop() storedGuarantor: PersonModel;
    @Prop() urlParams: string; //Stored in case user refreshes the page when coming in through a deep-link

    TestRecord: TestRecordModel = null;
    AOEQuestionList: AOEQuestionModel[] = [];
    ConsentConfigList: ConsentConfigModel[] = [];
    ReCaptchaVerified: boolean = false;

    HighestLevelInsuranceDataFillType: DataFillPersonTypeEnum = null;
    HighestLevelConsentDataFillType: DataFillPersonTypeEnum = null;

    Gender: any = GenderEnum;
    GenderList: string[] = Object.keys(GenderEnum).map((key) => { return (GenderEnum as any)[key] as string; });

    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;
    MessageSeverityEnum: any = MessageSeverity;
    DisplaySupportLink: boolean = false;

    InLicenseCameraMode: boolean = false;
    CardSide: CardSideEnum = null;

    IsGuarantorSameAsGuardian: boolean = false;
    Prefill: TestEntryPrefill;

    InLicenseScanMode: boolean = false;
    InPreCodedBarcodeScanMode: boolean = false;
    DataFillPersonType: DataFillPersonTypeEnum = null;
    DataFillPersonTypeEnum: any = DataFillPersonTypeEnum;
    InSignatureMode: boolean = false;
    ScannerMode = ScannerModeEnum;

    UpdateInsuranceData: Function = UpdateInsuranceData;

    created() {
        let urlParamString = this.urlParams != null && this.urlParams.length > 0 ? this.urlParams : "";

        if (this.$store.state.TestEntryData == null || this.testRecord == null) {
            this.$router.replace({ path: '/testentrylanding' + urlParamString });
            return;
        }

        urlParamString = urlParamString.charAt(0) == "?" ? urlParamString.substring(1) : urlParamString;
        const params = new URLSearchParams(decodeURI(urlParamString));
        this.Prefill = Object.fromEntries(params.entries()) as any as TestEntryPrefill;

        this.AOEQuestionList = this.$store.state.TestEntryData.AOEQuestionList as AOEQuestionModel[];
        this.ConsentConfigList = this.$store.state.TestEntryData.ConsentConfigList as ConsentConfigModel[];

        this.NewTest();
        this.PrefillTest(this.Prefill);
    }

    NewTest() {
        this.IsGuarantorSameAsGuardian = false;
        this.TestRecord = JSON.parse(JSON.stringify(this.testRecord));

        if (this.TestRecord.InsuranceList.length == 0) {
            this.TestRecord.InsuranceList.push(new InsuranceInfoModel());
        } else {
            for (var i = 0; i < this.TestRecord.InsuranceList.length; i++) {
                Vue.set(this.TestRecord.InsuranceList[i], 'ShowRemovalConfirmation', false);
            }
        }

        //Only prefill AOE question answers if there is no prefilled test, or the prefilled test didn't have aoe answer list
        if (this.TestRecord.AOEAnswerList == null || this.TestRecord.AOEAnswerList.length == 0) {
            this.ResetAOEAnswerArray();
        } else {
            //If aoe answers are being prefilled, it is from a previously entered test. Data santitization is not needed
        }

        //Only default the payment option if this is not a prefilled test
        if (this.TestRecord.PaymentTypeConfigID == null) {
            this.TestRecord.PaymentTypeConfigID = this.$store.state.TestEntryData.PaymentTypeList.length == 1 ? this.$store.state.TestEntryData.PaymentTypeList[0].PaymentTypeConfigID : this.$store.state.TestEntryData.DefaultPaymentTypeConfigID ?? null; //Default payment type if only 1 is available
        }

        //Always reset consent
        this.ResetConsentArray();

        //Reset errors (since TestRecord resets, the fields will be highlighted red without a reset)
        this.$v.$reset();
    }

    PrefillTest(prefill: TestEntryPrefill) {
        this.TestRecord.Patient.FirstName = prefill.firstName;
        this.TestRecord.Patient.LastName = prefill.lastName;
        this.TestRecord.Patient.Gender = prefill.gender;
        this.TestRecord.Patient.Address = prefill.address;
        this.TestRecord.Patient.City = prefill.city;
        this.TestRecord.Patient.State = prefill.state;
        this.TestRecord.Patient.Zip = prefill.zip;
        this.TestRecord.Patient.CellPhone = prefill.cellPhone;

        if (prefill.dob?.length > 0) {
            let dateArray = prefill.dob.split("T")[0]?.split("-");

            if (dateArray?.length == 3) {
                this.TestRecord.Patient.DOB_Year = parseInt(dateArray[0]) as any;
                this.TestRecord.Patient.DOB_Month = parseInt(dateArray[1]) as any;
                this.TestRecord.Patient.DOB_Day = parseInt(dateArray[2]) as any;
            }
        }

        this.TestRecord.PaymentAdditionalInput = prefill.paymentCustom;
    }

    @Validations()
    validations() {
        let validationObj = {
            TestRecord: {
                ID: { requiredIf: requiredIf(() => { return this.IsQuickTest || this.IsQuickTestPrecoded; }), alphaNum },

                Patient: {
                    FirstName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                    LastName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                    Address: { required, specialCharBlacklist, maxLength: maxLength(75) },
                    City: { required, specialCharBlacklist, maxLength: maxLength(75) },
                    State: { required, inList: ((field: string) => { return inList(field, this.$store.state.StateList); }) },
                    Zip: { required, maxLength: maxLength(5), minLength: minLength(5), numeric: numeric },
                    DOBValid: (val: PersonModel) => IsDOBValid(val.DOB_Month, val.DOB_Day, val.DOB_Year) === true,
                    DOB_Month: { required, between: between(1, 12) },
                    DOB_Day: { required, between: between(1, 31) },
                    DOB_Year: { required, between: between(1900, new Date().getFullYear()) },
                    Gender: { required, inList: ((field: string) => { return inList(field, this.GenderList); }) },
                    Ethnicity: { required, inList: ((field: string) => { return inList(field, this.$store.getters.EthnicityValuesList); }) },
                    Race: { required, inList: ((field: string) => { return inList(field, this.$store.getters.RaceValuesList); }) },
                    MedicalRecordNumber: { alphaNum },
                    CellPhone: { requiredIf: requiredIf(() => { return this.HasAcceptedSMSConsent && !this.IsPatientUnder18 }), phoneNumber, maxLength: maxLength(20) },
                    EmailAddress: { requiredIf: requiredIf(() => { return this.HasAcceptedEmailConsent && !this.IsPatientUnder18 }), specialCharBlacklist, email: emailCaseInsensitive, maxLength: maxLength(120) },
                },

                PaymentTypeConfigID: { requiredIf: requiredIf(() => { return this.$store.state.TestEntryData.PaymentTypeList.length > 0 }) },
                TestResults: { validTestResults: (vals: any[]) => { return this.IsPatientIntake || this.IsPatientIntakeQueue || this.IsPatientManagement || validTestResults(vals) } },
                PaymentAdditionalInput: { requiredIf: requiredIf(() => { return this.ShouldShowPaymentAdditionalInput && this.SelectedPaymentType.AdditionalInputRequired; }) },
                InsuranceList: {
                    $each: {
                        InsuranceName: { requiredIf: requiredIf(() => { return this.PaymentTypeRequiresInsurance }) },
                        InsuranceMemberID: { requiredIf: requiredIf(() => { return this.PaymentTypeRequiresInsurance }), specialCharBlacklist },
                    }
                },
                AOEAnswerList: {
                    $each: {
                        Answer: {
                            required: requiredIf((aoeAnswer) => { return this.CheckIfAOEAnswerIsRequired(aoeAnswer); }),
                            minValue: (answer: any, model: any) => {
                                var minVal = this.GetAOEQuestionMinValue(model);
                                var comparisonValue = this.GetAOEQuestionComparisonValue(model);
                                return minVal == null || comparisonValue == null || comparisonValue >= minVal;
                            },
                            maxValue: (answer: any, model: any) => {
                                var maxVal = this.GetAOEQuestionMaxValue(model)
                                var comparisonValue = this.GetAOEQuestionComparisonValue(model);
                                return maxVal == null || comparisonValue == null || comparisonValue <= maxVal;
                            },
                            specialCharBlacklist
                        }
                    }
                },
                ConsentSignatureImage: { requiredIf: requiredIf(() => { return this.RequiresHandSignature }) }
            },
            ConsentAgreement_Valid: { sameAs: (val: boolean) => val === true },
            GetDemoVerForPatientDOB: { sameAs: (val: DemoVerificationModel) => val == null || !val.Enforced },
            GetDemoVerForPatientGender: { sameAs: (val: DemoVerificationModel) => val == null || !val.Enforced }
        };

        //Add additional validation for precoded tests if configured
        if (this.IsQuickTestPrecoded) {
            let startsWithValue = this.$store.state.TestEntryData.BarcodeRequirement.StartsWith as string;
            let minimumLengthValue = this.$store.state.TestEntryData.BarcodeRequirement.MinimumLength as number;
            let maximumLengthValue = this.$store.state.TestEntryData.BarcodeRequirement.MaximumLength as number;

            if (startsWithValue != null && startsWithValue.length > 0) {
                (validationObj.TestRecord.ID as any).startsWith = startsWith(startsWithValue);
            }

            if (minimumLengthValue != null) {
                (validationObj.TestRecord.ID as any).minLength = minLength(minimumLengthValue);
            }

            if (maximumLengthValue != null) {
                (validationObj.TestRecord.ID as any).maxLength = maxLength(maximumLengthValue);
            }
        }

        if (this.TestRecord.Guardian != null) {
            (validationObj as any).TestRecord.Guardian = {
                FirstName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                LastName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                Address: { required, specialCharBlacklist, maxLength: maxLength(75) },
                City: { required, specialCharBlacklist, maxLength: maxLength(75) },
                State: { required, inList: ((field: string) => { return inList(field, this.$store.state.StateList); }) },
                Zip: { required, maxLength: maxLength(5), minLength: minLength(5), numeric: numeric },
                DOBValid: (val: PersonModel) => IsDOBValid(val.DOB_Month, val.DOB_Day, val.DOB_Year) === true,
                DOB_Month: { required, between: between(1, 12) },
                DOB_Day: { required, between: between(1, 31) },
                DOB_Year: { required, between: between(1900, new Date().getFullYear()) },
                Gender: { required, inList: ((field: string) => { return inList(field, this.GenderList); }) },
                CellPhone: { requiredIf: requiredIf(() => { return this.HasAcceptedSMSConsent && this.IsPatientUnder18 }), phoneNumber },
                EmailAddress: { requiredIf: requiredIf(() => { return this.HasAcceptedEmailConsent && this.IsPatientUnder18 }), specialCharBlacklist, email: emailCaseInsensitive },
            };
        }

        if (this.TestRecord.Guarantor != null && !this.IsGuarantorSameAsGuardian) {
            (validationObj as any).TestRecord.Guarantor = {
                FirstName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                LastName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                Address: { required, specialCharBlacklist, maxLength: maxLength(75) },
                City: { required, specialCharBlacklist, maxLength: maxLength(75) },
                State: { required, inList: ((field: string) => { return inList(field, this.$store.state.StateList); }) },
                Zip: { required, maxLength: maxLength(5), minLength: minLength(5), numeric: numeric },
                DOBValid: (val: PersonModel) => IsDOBValid(val.DOB_Month, val.DOB_Day, val.DOB_Year) === true,
                DOB_Month: { required, between: between(1, 12) },
                DOB_Day: { required, between: between(1, 31) },
                DOB_Year: { required, between: between(1900, new Date().getFullYear()) },
                Gender: { required, inList: ((field: string) => { return inList(field, this.GenderList); }) },
            };
        }

        return validationObj;
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    get IsQuickTest() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.QuickTest;
    }

    get IsQuickTestPrecoded() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.QuickTestPrecoded;
    }

    get IsQuickTestRapidResult() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.QuickTestRapidResult;
    }

    get IsPatientIntake() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.PatientIntake;
    }

    get IsPatientIntakeQueue() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.PatientIntakeQueue;
    }

    get IsPatientManagement() {
        return this.$store.state.TestEntryData.TestMode == TestModeEnum.PatientManagement;
    }

    get DisplayReCaptcha(): boolean {
        return !this.$store.getters.IsSignedIn && this.$store.state.ReCaptchaKey != null && this.$store.state.ReCaptchaKey.length > 0 &&
            (this.IsQuickTestRapidResult || this.IsPatientIntake || this.IsPatientIntakeQueue || this.IsPatientManagement);
    }

    get PatientConnectOutreachMessage(): string {
        return "You will receive " +
            (this.HasAcceptedEmailConsent ? "an email " : "") +
            (this.HasAcceptedEmailConsent && this.HasAcceptedSMSConsent ? "and " : "") +
            (this.HasAcceptedSMSConsent ? "a text message " : "") +
            "to view your results in PatientXchange once they are processed.";
    }

    get ProfileSaveLabel() {
        return "Save as Profile" + (this.TestRecord.Guardian != null || this.TestRecord.Guarantor != null ? 's' : '');
    }

    get GetDemoVerForPatientDOB() {
        let dobString = this.TestRecord?.Patient != null ? this.TestRecord.Patient.DOB_Month + "/" + this.TestRecord.Patient.DOB_Day + "/" + this.TestRecord.Patient.DOB_Year : null;

        return GetVerificationToDisplay(DemoVerificationTypeEnum.DOB, this.$store.state.TestEntryData.DemoVerifications, dobString);
    }

    get GetDemoVerForPatientGender() {
        let genderString = this.TestRecord?.Patient?.Gender;

        return GetVerificationToDisplay(DemoVerificationTypeEnum.Gender, this.$store.state.TestEntryData.DemoVerifications, genderString);
    }

    get IsPaymentAdditionalInputLocked() {
        return this.Prefill?.paymentCustom?.length > 0;
    }

    ToggleIsGuarantorSameAsGuardian(forceBool: boolean): void {
        if (typeof forceBool === "boolean") {
            this.IsGuarantorSameAsGuardian = forceBool;
        } else {
            this.IsGuarantorSameAsGuardian = !this.IsGuarantorSameAsGuardian;
        }

        if (!this.IsGuarantorSameAsGuardian) {
            this.TestRecord.Guarantor = this.storedGuarantor != null ? this.storedGuarantor : new PersonModel();
        } else {
            if (this.HighestLevelInsuranceDataFillType == DataFillPersonTypeEnum.Guarantor)
                this.HighestLevelInsuranceDataFillType = null;
        }
    }

    ResetAOEAnswerArray(): void {
        this.TestRecord.AOEAnswerList = [];

        if (this.AOEQuestionList.length > 0) {
            this.AOEQuestionList.forEach((question) => {
                this.UpdateAOEAnswers({ QuestionID: question.QuestionID, Answer: question.AnswerType == 1 ? 'U' : null } as AOEAnswerModel);
            });
        }
    }

    ResetConsentArray(): void {
        this.TestRecord.ConsentAgreementList = [];

        if (this.ConsentConfigList.length > 0) {
            this.ConsentConfigList.forEach((cc) => {
                this.TestRecord.ConsentAgreementList.push({ ConsentConfigID: cc.ConsentConfigID, Text: cc.Text, ConsentDataType: cc.ConsentDataType, Accepted: false } as ConsentAgreementModel);
            });
        }

        this.TestRecord.ConsentSignatureImage = null;
    }

    CheckIfAOEAnswerIsRequired(aoeAnswer: AOEAnswerModel): boolean {
        let aoeQuestion = this.AOEQuestionList.find((aoeQ) => { return aoeQ.QuestionID == aoeAnswer.QuestionID; });

        if (aoeQuestion.ValidationList.findIndex((val) => { return val == AOEValidationEnum.Required; }) == -1) {
            return false;
        } else if (aoeQuestion.ParentQuestionID == null) {
            //The answer is required and is visible
            return true;
        } else {
            //Determine if the question is visible before enforcing validation
            return aoeQuestion.ParentQuestionAnswer == this.TestRecord.AOEAnswerList.find((aoeA) => { return aoeA.QuestionID == aoeQuestion.ParentQuestionID; }).Answer;
        }
    }

    GetAOEQuestionComparisonValue(aoeAnswer: AOEAnswerModel): number {
        if (aoeAnswer.Answer == null)
            return null

        let aoeConfig = this.AOEQuestionList.find((aoeC) => { return aoeC.QuestionID == aoeAnswer.QuestionID; });
        let currentTime = new Date();

        switch (aoeConfig.AnswerType) {
            case AnswerTypeEnum.Time:
                var dateValue = new Date(`${currentTime.toISOString().split('T')[0]} ${aoeAnswer.Answer}`).getTime();
                return Math.ceil((dateValue - currentTime.getTime()) / 60000);
            case AnswerTypeEnum.Date:
                var dateValue = new Date(aoeAnswer.Answer).getTime();
                return Math.ceil((dateValue - currentTime.getTime()) / 86400000);
            case AnswerTypeEnum.DateTime:
                var dateValue = new Date(aoeAnswer.Answer).getTime();
                return Math.ceil((dateValue - currentTime.getTime()) / 60000);
            default:
                return parseFloat(aoeAnswer.Answer)
        }
    }

    GetAOEQuestionMinValue(aoeAnswer: AOEAnswerModel): number {
        let aoeConfig = this.AOEQuestionList.find((aoeC) => { return aoeC.QuestionID == aoeAnswer.QuestionID; });
        if (aoeConfig != null && aoeConfig.EnforceNumericalLimits && aoeConfig.NumericalMin != null && StringHasValue(aoeAnswer.Answer))
            return aoeConfig.NumericalMin;
        else
            return null;
    }

    GetAOEQuestionMaxValue(aoeAnswer: AOEAnswerModel): number {
        let aoeConfig = this.AOEQuestionList.find((aoeC) => { return aoeC.QuestionID == aoeAnswer.QuestionID; });
        if (aoeConfig != null && aoeConfig.EnforceNumericalLimits && aoeConfig.NumericalMax != null && StringHasValue(aoeAnswer.Answer))
            return aoeConfig.NumericalMax;
        else
            return null;
    }

    SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        this.DisplaySupportLink = showSupportLink;
        Browser.SmoothScroll(0);
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
        this.DisplaySupportLink = false;
    }

    ScanPatientLicense() {
        this.InLicenseScanMode = true;
        this.DataFillPersonType = DataFillPersonTypeEnum.Patient;
    }

    ScanGuardianLicense() {
        this.InLicenseScanMode = true;
        this.DataFillPersonType = DataFillPersonTypeEnum.Guardian;
    }

    ScanGuarantorLicense() {
        this.InLicenseScanMode = true;
        this.DataFillPersonType = DataFillPersonTypeEnum.Guarantor;
    }

    ScanCancel() {
        this.InLicenseScanMode = false;
        this.DataFillPersonType = null;
    }

    FillInLicenseData(licenseData: any) {
        this.InLicenseScanMode = false;

        let personModel = null;
        switch (this.DataFillPersonType) {
            case DataFillPersonTypeEnum.Patient:
                personModel = this.TestRecord.Patient;
                break;
            case DataFillPersonTypeEnum.Guardian:
                personModel = this.TestRecord.Guardian;
                break;
            case DataFillPersonTypeEnum.Guarantor:
                personModel = this.TestRecord.Guarantor;
                break;
        }

        FillPatientWithLicenseData(personModel, licenseData, true);

        this.DataFillPersonType = null;
    }

    PreCodedBarcodeScan(value: string) {
        this.TestRecord.ID = value;
        this.InPreCodedBarcodeScanMode = false;
    }

    CaptureSignature(value: string) {
        this.TestRecord.ConsentSignatureImage = value;
        this.InSignatureMode = false;
    }

    SubmitTest() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        if (this.IsGuarantorSameAsGuardian) {
            //Copy over guardian info, except for email and cell phone
            this.TestRecord.Guarantor = JSON.parse(JSON.stringify(this.TestRecord.Guardian)) as PersonModel;
            this.TestRecord.Guarantor.CellPhone = null;
            this.TestRecord.Guarantor.EmailAddress = null;
        }

        //If we should not display insurance section for data entry, we should remove the linked info
        if (!this.PaymentTypeRequiresInsurance) {
            this.TestRecord.InsuranceList = [];
        }

        if (!this.ShouldShowPaymentTypeLicense) {
            this.TestRecord.DriverLicenseFrontImage = null;
            this.TestRecord.DriverLicenseBackImage = null;
        }

        if (!this.ShouldShowPaymentAdditionalInput) {
            this.TestRecord.PaymentAdditionalInput = null;
        }

        //Sanitize test id
        if (this.TestRecord.ID != null && this.TestRecord.ID.length > 0) {
            this.TestRecord.ID = this.TestRecord.ID.toUpperCase();
        }

        //Set patient signature
        this.TestRecord.Signature = this.IsPatientUnder18 ? this.GuardianFullName : this.PatientFullName;

        //Set Create Date
        this.TestRecord.CreateDate = new Date().toISOString();

        let uploadForm = CreateFormData(this.TestRecord, ["InsuranceCardFrontImage", "InsuranceCardBackImage", "ConsentSignatureImage", "DriverLicenseFrontImage", "DriverLicenseBackImage"]);

        if (this.IsGuarantorSameAsGuardian) {
            //Cleanup
            this.TestRecord.Guarantor = new PersonModel();
        }

        Browser.SmoothScroll(0);

        Ajax.POST_MULTIPART("/LabTest/SaveTestEntry",
            uploadForm,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    if (this.IsGuarantorSameAsGuardian) {
                        this.TestRecord.Guarantor = this.TestRecord.Guardian;
                    }

                    this.$router.push({ name: 'Test Entry Complete', params: { testRecord: this.TestRecord } as any });
                } else {
                    let errorResponse = result.Data != null ? result.Data : {} as TestEntryErrorResponseModel;

                    if (errorResponse.ErrorReason == TestEntryErrorEnum.InvalidField) {
                        this.SetFormMessage(`The ${errorResponse.FieldName} you provided is invalid.`, MessageSeverity.HIGH);
                    } else if (this.IsPatientIntake || this.IsPatientIntakeQueue || this.IsPatientManagement) {
                        this.SetFormMessage("There was an issue processing your request. Please try again or speak to an associate for assistance.", MessageSeverity.HIGH, true);
                    } else {
                        this.SetFormMessage("There was an issue processing your request. Please try again or contact support using the information provided in your test kit.", MessageSeverity.HIGH, true);
                    }
                }
            },
            () => {
                if (this.IsPatientIntake || this.IsPatientIntakeQueue || this.IsPatientManagement) {
                    this.SetFormMessage("There was an issue processing your request. Please try again or speak to an associate for assistance.", MessageSeverity.HIGH, true);
                } else {
                    this.SetFormMessage("There was an issue processing your request. Please try again or contact support using the information provided in your test kit.", MessageSeverity.HIGH, true);
                }
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    UpdateAOEAnswers(aoeAnswer: AOEAnswerModel) {
        //Don't fill in data unless the answer matches up with a question that currently exists in the AOE Question List
        if (this.AOEQuestionList.findIndex((aoeQuestion) => { return aoeQuestion.QuestionID == aoeAnswer.QuestionID; }) == -1) {
            return;
        }

        let recordedAnswer = this.TestRecord.AOEAnswerList.find((answer) => { return aoeAnswer.QuestionID == answer.QuestionID; });
        if (recordedAnswer == null) {
            this.TestRecord.AOEAnswerList.push(aoeAnswer);
        } else {
            recordedAnswer.Answer = aoeAnswer.Answer;
        }
    }

    ToggleConsent(consentID: number) {
        //Don't fill in data unless the we have the consent config
        if (this.ConsentConfigList.findIndex((cc) => { return cc.ConsentConfigID == consentID; }) == -1) {
            return;
        }

        let consentAgreement = this.TestRecord.ConsentAgreementList.find((agreement) => { return agreement.ConsentConfigID == consentID; });
        if (consentAgreement == null) {
            let consentConfig = this.ConsentConfigList.find((cc) => { return cc.ConsentConfigID == consentID; });
            this.TestRecord.ConsentAgreementList.push({ ConsentConfigID: consentID, Text: consentConfig.Text, ConsentDataType: consentConfig.ConsentDataType, Accepted: true } as ConsentAgreementModel);
        } else {
            consentAgreement.Accepted = !consentAgreement.Accepted;
        }
    }

    ScanLicenseCardFront() {
        this.CardSide = CardSideEnum.Front;
        this.InLicenseCameraMode = true;
    }

    ScanLicenseCardBack() {
        this.CardSide = CardSideEnum.Back;
        this.InLicenseCameraMode = true;
    }

    CancelLicenseCapture(imageData: string) {
        this.InLicenseCameraMode = false;
    }

    RemoveLicenseFront() {
        this.TestRecord.DriverLicenseFrontImage = null;
    }

    RemoveLicenseBack() {
        this.TestRecord.DriverLicenseBackImage = null;
    }

    CaptureDriverLicenseImage(imageData: string) {
        if (this.CardSide == CardSideEnum.Front) {
            this.TestRecord.DriverLicenseFrontImage = imageData;
        } else if (this.CardSide == CardSideEnum.Back) {
            this.TestRecord.DriverLicenseBackImage = imageData;
        }

        this.InLicenseCameraMode = false;
    }

    get SelectedPaymentType(): PaymentTypeConfigModel {
        return this.TestRecord.PaymentTypeConfigID != null ? (this.$store.state.TestEntryData.PaymentTypeList as PaymentTypeConfigModel[]).find((pt) => { return pt.PaymentTypeConfigID == this.TestRecord.PaymentTypeConfigID; }) : null;
    }

    get PaymentTypeRequiresInsurance() {
        return this.TestRecord != null && this.TestRecord.PaymentTypeConfigID != null && this.SelectedPaymentType.ShowInsuranceSection;
    }

    get ShouldShowPaymentTypeLicense() {
        return this.TestRecord != null && this.TestRecord.PaymentTypeConfigID != null && this.CanCaptureDriverLicense && this.SelectedPaymentType.ShowLicenseSection;
    }

    get ShouldShowPaymentAdditionalInput() {
        return this.TestRecord != null && this.TestRecord.PaymentTypeConfigID != null && this.SelectedPaymentType.ShowAdditionalInput;
    }

    get HasImageCaptureLabelOverride(): boolean {
        return this.SelectedPaymentType.ImageCaptureLabel != null && this.SelectedPaymentType.ImageCaptureLabel.length > 0;
    }

    get ImageCaptureLabel(): string {
        return this.HasImageCaptureLabelOverride ? this.SelectedPaymentType.ImageCaptureLabel : "Valid Photo ID Front";
    }

    get ShouldShowPaymentHyperlink() {
        return this.TestRecord != null && this.TestRecord.PaymentTypeConfigID != null && StringHasValue(this.SelectedPaymentType.HyperlinkURL);
    }

    get HyperlinkDisplayName() {
        return this.TestRecord != null && this.SelectedPaymentType != null ? StringHasValue(this.SelectedPaymentType.HyperlinkName) ? this.SelectedPaymentType.HyperlinkName : this.SelectedPaymentType.HyperlinkURL : null;
    }

    get PatientFullName() {
        return (this.TestRecord.Patient.FirstName != null ? this.TestRecord.Patient.FirstName : "") + " " +
            (this.TestRecord.Patient.MiddleName != null && this.TestRecord.Patient.MiddleName.length > 0 ? this.TestRecord.Patient.MiddleName + " " : "") +
            (this.TestRecord.Patient.LastName != null ? this.TestRecord.Patient.LastName : "");
    }

    get GuardianFullName() {
        return this.TestRecord.Guardian == null ? null : (this.TestRecord.Guardian.FirstName != null ? this.TestRecord.Guardian.FirstName : "") + " " +
            (this.TestRecord.Guardian.MiddleName != null && this.TestRecord.Guardian.MiddleName.length > 0 ? this.TestRecord.Guardian.MiddleName + " " : "") +
            (this.TestRecord.Guardian.LastName != null ? this.TestRecord.Guardian.LastName : "");
    }

    get IsPatientUnder18() {
        if (this.$store.state.TestEntryData == null || this.testRecord == null) {
            return false;
        } else if (this.CanUseGuardianGuarantor && this.TestRecord != null && IsDOBValid(this.TestRecord.Patient.DOB_Month, this.TestRecord.Patient.DOB_Day, this.TestRecord.Patient.DOB_Year)) {
            return moment().diff(this.TestRecord.Patient.DOB_Month + "/" + this.TestRecord.Patient.DOB_Day + "/" + this.TestRecord.Patient.DOB_Year, 'years') < 18;
        } else {
            return false;
        }
    }

    @Watch("IsPatientUnder18")
    HandleGuardianGuarantor(newVal: boolean) {
        if (newVal == true) {
            if (this.TestRecord.Guardian == null) {
                this.TestRecord.Guardian = this.storedGuardian != null ? this.storedGuardian : new PersonModel();
            }
            if (this.PaymentTypeRequiresInsurance && this.TestRecord.Guarantor == null) {
                this.TestRecord.Guarantor = new PersonModel();
                this.IsGuarantorSameAsGuardian = true;
            }
            this.TestRecord.Patient.CellPhone = null;
            this.TestRecord.Patient.EmailAddress = null;
        } else {
            this.HighestLevelInsuranceDataFillType = null;
            this.HighestLevelConsentDataFillType = null;
            this.TestRecord.Guardian = null;
            this.TestRecord.Guarantor = null;
            this.IsGuarantorSameAsGuardian = false;
        }
    }

    @Watch("PaymentTypeRequiresInsurance")
    HandlePrivateInsurance(newVal: boolean) {
        if (newVal == true) {
            if (this.IsPatientUnder18 && this.TestRecord.Guarantor == null) {
                this.TestRecord.Guarantor = new PersonModel();
                this.IsGuarantorSameAsGuardian = true;
            }
        } else {
            this.TestRecord.Guarantor = null;
            this.IsGuarantorSameAsGuardian = false;
        }
    }

    get CanUseGuardianGuarantor() {
        return this.$store.state.TestEntryData.FeatureList.indexOf(FeatureEnum.UseGuardianGuarantor) > -1;
    }

    get CanCaptureDriverLicense() {
        return this.$store.state.TestEntryData.FeatureList.indexOf(FeatureEnum.CaptureDriversLicense) > -1;
    }

    get CanFreeTypeInsurances() {
        return this.$store.state.TestEntryData.FeatureList.indexOf(FeatureEnum.CanFreeTypeInsurances) > -1;
    }

    //Returns true is validation for all required consent is agreed to
    get ConsentAgreement_Valid(): boolean {
        let isValid = true;

        this.ConsentConfigList.forEach((cc) => {
            if (cc.Required) {
                let agreement = this.TestRecord.ConsentAgreementList.find((a) => { return a.ConsentConfigID == cc.ConsentConfigID; });
                if (agreement == null || !agreement.Accepted) {
                    isValid = false;
                }
            }
        });

        return isValid;
    }

    get HasAcceptedEmailConsent(): boolean {
        if (this.TestRecord == null) {
            return false;
        }

        let emailConsent = this.ConsentConfigList.find((cc) => { return cc.ConsentDataType == ConsentDataTypeEnum.Email; });

        if (emailConsent == null) {
            return false;
        }

        let emailAgreement = this.TestRecord.ConsentAgreementList.find((ca) => { return ca.ConsentConfigID == emailConsent.ConsentConfigID; });

        if (emailAgreement == null || !emailAgreement.Accepted) {
            return false;
        } else {
            return true;
        }
    }

    get HasAcceptedSMSConsent(): boolean {
        if (this.TestRecord == null) {
            return false;
        }

        let smsConsent = this.ConsentConfigList.find((cc) => { return cc.ConsentDataType == ConsentDataTypeEnum.SMS; });

        if (smsConsent == null) {
            return false;
        }

        let smsAgreement = this.TestRecord.ConsentAgreementList.find((ca) => { return ca.ConsentConfigID == smsConsent.ConsentConfigID; });

        if (smsAgreement == null || !smsAgreement.Accepted) {
            return false;
        } else {
            return true;
        }
    }

    get RequiresHandSignature(): boolean {
        return this.ConsentConfigList.findIndex((cc) => { return cc.RequiresHandSignature; }) > -1;
    }

    get AdditionalBarcodeHelperText(): string {
        return this.$store.state.TestEntryData.BarcodeRequirement.Description != null && this.$store.state.TestEntryData.BarcodeRequirement.Description.length > 0 ? this.$store.state.TestEntryData.BarcodeRequirement.Description : "";
    }

    OpenResultUploadModal() {
        this.$modal.show('testResultUploadModal', {
            TestResults: this.TestRecord.TestResults
        });
    }

    UpdateTestResults(testResults: TestEntryResultModel[]) {
        //Only update the values given back by the modal (since we might have more in the test result array, entries with previous values)
        testResults.forEach((_tr) => {
            this.TestRecord.TestResults.find((tr) => { return tr.TestTypeID == _tr.TestTypeID }).Result = _tr.Result;
        });
    }

    OpenProfileSelectionModal(personType: DataFillPersonTypeEnum) {
        this.DataFillPersonType = personType;

        this.$modal.show('profileSelectionModal', { PersonID: null });
    }

    FillProfileData(profile: ProfileModel) {
        this.FillProfilePersonData(profile.Person);
        this.FillProfileInsuranceData(profile.InsuranceList);
        this.FillProfileConsentData(profile.EmailConsent, profile.SMSConsent);

        this.DataFillPersonType = null;
    }

    FillProfilePersonData(person: PersonModel) {
        switch (this.DataFillPersonType) {
            case DataFillPersonTypeEnum.Patient:
                this.TestRecord.Patient = person;
                break;
            case DataFillPersonTypeEnum.Guarantor:
                this.TestRecord.Guarantor = person;
                break;
            case DataFillPersonTypeEnum.Guardian:
                this.TestRecord.Guardian = person;
                break;
        }

    }

    FillProfileInsuranceData(insurances: InsuranceInfoModel[]) {
        if (!this.IsPatientUnder18 || this.DataFillPersonType >= this.HighestLevelInsuranceDataFillType) {
            this.TestRecord.InsuranceList = null; 

            this.HighestLevelInsuranceDataFillType = this.DataFillPersonType;
            this.TestRecord.InsuranceList = insurances; 

            if (this.TestRecord.InsuranceList.length == 0) {
                this.TestRecord.InsuranceList.push(new InsuranceInfoModel());
            } else {
                for (var i = 0; i < this.TestRecord.InsuranceList.length; i++) {
                    Vue.set(this.TestRecord.InsuranceList[i], 'ShowRemovalConfirmation', false);
                }
            }
        }
    }

    FillProfileConsentData(emailConsent: boolean, smsConsent: boolean) {
        if (!this.IsPatientUnder18 || (this.DataFillPersonType >= this.HighestLevelConsentDataFillType && this.DataFillPersonType != DataFillPersonTypeEnum.Guarantor)) {
            this.HighestLevelConsentDataFillType = this.DataFillPersonType;
            var consentsReview = this.TestRecord.ConsentAgreementList.filter((consent) => {return consent.ConsentDataType == ConsentDataTypeEnum.Email || consent.ConsentDataType == ConsentDataTypeEnum.SMS });
            consentsReview.forEach((x) => { x.Accepted = (emailConsent && x.ConsentDataType == ConsentDataTypeEnum.Email) || (smsConsent && x.ConsentDataType == ConsentDataTypeEnum.SMS); });
        }
    }
}
