











// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ResendEmailCodeModal from "@/components/modals/ResendEmailCodeModal.vue";

@Component({
    components: { ResendEmailCodeModal }
})
export default class EmailVerificationCodeInput extends Vue {
    @Prop() value!: string;
    @Prop() validationObject: boolean;
    @Prop() hasFormMessage: boolean;

    ShowResendCodeLink: boolean = false;
    ResendCodeLinkTimerID: number = null;
    ResentCodeLinkTimerTimeout: number = 10000; //10 seconds

    created() {
        
    }

    mounted() {
        this.StartTimer();
    }

    beforeDestroy() {
        if (this.ResendCodeLinkTimerID != null) {
            window.clearTimeout(this.ResendCodeLinkTimerID);
        }
    }

    OpenResendEmailCodeModal() {
        this.$modal.show('resendEmailCodeModal');
    }

    ResendCode() {
        this.StartTimer();
        this.$emit("ResendCode");
    }

    StartTimer() {
        this.ShowResendCodeLink = false;
        this.ResendCodeLinkTimerID = window.setTimeout(() => { this.ShowResendCodeLink = true; }, this.ResentCodeLinkTimerTimeout);
    }

    UpdateValue(event: any) {
        let newValue = event.target.value;

        this.$emit("input", newValue);
        this.$emit("change"); //Useful for tacking on other callbacks
    }
}
