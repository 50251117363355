export enum DOM_Event {
    Custom = "custom",
    KeyUp = "keyup",
    KeyDown = "keydown",
    MouseMove = "mousemove",
    MouseDown = "mousedown",
    DOMMouseScroll = "DOMMouseScroll",
    MouseWheel = "mousewheel",
    TouchMove = "touchmove",
    MSPointerMove = "MSPointerMove"
}

export enum DOM_Element {
    Custom = "custom",
    Body = "body",
    MainContent = "main",
    Document = "document"
}

export class DOMBinder {

    //This will bind an event listener to a specific DOM element after the specified event is triggered.
    //By passing in Custom for the event / element, the associated 'custom' parameters will be used instead; otherwise, they may be omitted or null.
    public static BindListener = (domEvent: DOM_Event, domElement: DOM_Element, eventListener: EventListener, customEvent?: string, customElement?: HTMLElement): void => {
        let element = domElement == DOM_Element.Custom ? customElement : domElement == DOM_Element.Document ? document : document.querySelector(domElement);

        if (element != null) {
            if (domEvent == DOM_Event.Custom) {
                element.addEventListener(customEvent, eventListener, false);
            } else {
                element.addEventListener(domEvent, eventListener, false);
            }
        }
    }

    //This will do the exact opposite of BindListener. It is called in the same way (with predefined and custom events / elements)
    public static RemoveListener = (domEvent: DOM_Event, domElement: DOM_Element, eventListener: EventListener, customEvent?: string, customElement?: HTMLElement): void => {
        let element = domElement == DOM_Element.Custom ? customElement : document.querySelector(domElement);

        if (element != null) {
            if (domEvent == DOM_Event.Custom) {
                element.removeEventListener(customEvent, eventListener, false);
            } else {
                element.removeEventListener(domEvent, eventListener, false);
            }
        }
    }
}