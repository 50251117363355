










































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { maxLength, minLength, numeric, required } from 'vuelidate/lib/validators';
import { emailCaseInsensitive, inList, specialCharBlacklist } from '@/utils/CustomValidations';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import Browser from '@/utils/Browser';
import { LoginDataModel, LoginResponseModel, PersonModel } from '@/models/Common';
import BubbleWizard from "@/components/BubbleWizard.vue";
import EmailVerificationCodeInput from "@/components/inputs/EmailVerificationCodeInput.vue"
import FeatureButton from '@/components/testentry/FeatureButton.vue';
import FormMessage from "@/components/FormMessage.vue";
import NumberInput from '@/components/inputs/NumberInput.vue';
import PasswordChange from "@/components/PasswordChange.vue";
import ScannerOverlay from '@/components/modals/ScannerOverlay.vue';
import Tooltip from '@/components/Tooltip.vue';
import { ErrorReasonEnum, GenderEnum, MessageSeverity, ScannerModeEnum } from '@/models/Enums';
import { CreateAccountModel } from '@/models/AccountCreation';
import { FillPatientWithLicenseData, IsDOBValid } from '@/utils/GlobalFunctions';
import VueRecaptcha from 'vue-recaptcha';

@Component({
    components: { BubbleWizard, EmailVerificationCodeInput, FeatureButton, FormMessage, NumberInput, PasswordChange, ScannerOverlay, Tooltip, VueRecaptcha }
})
export default class NewAccount extends Vue {
    CreateAccountData: CreateAccountModel = new CreateAccountModel();
    Step: number = 1;
    ResubmitVerificationCodeRequired: boolean = false;
    ReCaptchaVerified: boolean = false;

    PasswordIsValid: boolean = false;
    FormMessage: string = null;
    FormMessageSeverityEnum: any = MessageSeverity;
    FormMessageSeverity: MessageSeverity = null;
    DisplaySupportLink: boolean = false;

    InLicenseScanMode: boolean = false;
    ScannerMode = ScannerModeEnum;

    created() {
        let patientGUID = this.$router.currentRoute.query.p as string;

        //Bounce user back to dashboard if they somehow got here when signed in
        if (this.$store.getters.IsSignedIn) {
            this.$router.push({ name: "Dashboard" });
        }

        if (patientGUID != null && patientGUID.length > 0) {
            this.CreateAccountData.PatientGuid = patientGUID;
        }
    }

    @Validations()
    validations() {
        let validationModel = null;

        switch (this.Step) {
            case 1:
                validationModel = {
                    CreateAccountData: {
                        Email: { required, specialCharBlacklist, email: emailCaseInsensitive, maxLength: maxLength(120) },
                    },
                    PasswordIsValid: { sameAs: (val: boolean) => val === true },
                };

                break;
            case 2:
                validationModel = {
                    CreateAccountData: {
                        Person: {
                            FirstName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                            MiddleName: { specialCharBlacklist, maxLength: maxLength(50) },
                            LastName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                            DOBValid: (val: PersonModel) => IsDOBValid(val.DOB_Month, val.DOB_Day, val.DOB_Year) === true,
                            DOB_Month: { required },
                            DOB_Day: { required },
                            DOB_Year: { required },
                            Address: { required, specialCharBlacklist, maxLength: maxLength(75) },
                            City: { required, specialCharBlacklist, maxLength: maxLength(75) },
                            State: { required, inList: ((field: string) => { return inList(field, this.$store.state.StateList); }) },
                            Zip: { required, maxLength: maxLength(5), minLength: minLength(5), numeric: numeric },
                        }
                    }
                };

                break;
            case 3:
                validationModel = {
                    CreateAccountData: {
                        EmailVerificationCode: { required, maxLength: maxLength(5) },
                    },
                };

                break;
        }

        return validationModel;
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    get DisplayReCaptcha(): boolean {
        return this.$store.state.ReCaptchaKey != null && this.$store.state.ReCaptchaKey.length > 0;
    }

    SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        this.DisplaySupportLink = showSupportLink;
        Browser.SmoothScroll(0);
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
        this.DisplaySupportLink = false;
    }

    ScanLicense() {
        this.InLicenseScanMode = true;
    }

    ScanCancel() {
        this.InLicenseScanMode = false;
    }

    FillInLicenseData(licenseData: any) {
        this.InLicenseScanMode = false;

        FillPatientWithLicenseData(this.CreateAccountData.Person, licenseData, false);
    }

    SendEmailVerificationCode(callback: Function) {
        return Ajax.POST("/App/RequestEmailVerificationCode",
            this.CreateAccountData.Email,
            callback != null ? callback : (result: AjaxResponse) => {
                //Default behavior if no function was passed in
                if (result.WasSuccess) {
                    this.$modal.show('messageModal', { Title: 'Sent', Message: "Your verification code has been sent. Please check your email.", CanClickToClose: false, DisplayOkayButton: true });
                    this.SetFormMessage(`We have sent a verification code to ${this.CreateAccountData.Email}. Please enter it below.`, MessageSeverity.LOW);
                    this.ResubmitVerificationCodeRequired = false;
                } else {
                    this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                }
            },
            () => {
                this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
            }) as Promise<any>;
    }

    LoginSetupNext() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        Ajax.POST("/App/CheckIfEmailExists",
            this.CreateAccountData.Email,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    if (result.Data as boolean === true) {
                        this.Step++;
                    } else {
                        this.SetFormMessage("An account already exists with the email provided. Please go back to the Login page and click Forgot Password to recover your account.", MessageSeverity.HIGH);
                    }
                } else {
                    this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                }
            },
            () => {
                this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
            });
    }

    DemoInfoNext() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        this.SendEmailVerificationCode((result: AjaxResponse) => {
            if (result.WasSuccess) {
                this.Step++;
                this.ResubmitVerificationCodeRequired = false;
                this.ReCaptchaVerified = false;
                this.SetFormMessage(`We have sent a verification code to ${this.CreateAccountData.Email}. Please enter it below.`, MessageSeverity.LOW);
            } else {
                this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
            }
        });
    }

    Submit() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        let requestModel = JSON.parse(JSON.stringify(this.CreateAccountData)) as CreateAccountModel;
        requestModel.Person.EmailAddress = requestModel.Email;

        Ajax.POST("/App/CreateAccount",
            requestModel,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let loginData: LoginDataModel = { AuthToken: result.AuthToken, LoginData: result.Data as LoginResponseModel } as LoginDataModel;

                    this.$emit('Login', loginData);
                } else {
                    let errorCode = result.Data as ErrorReasonEnum;

                    switch (errorCode) {
                        case ErrorReasonEnum.InvalidEmail:
                            this.SetFormMessage("The email you provided is invalid. Please go back and enter a proper email address.", MessageSeverity.HIGH);
                            break;
                        case ErrorReasonEnum.InvalidVerificationCode:
                            this.SetFormMessage("The verification code you entered is incorrect. Please try again.", MessageSeverity.HIGH);
                            break;
                        case ErrorReasonEnum.ResubmitVerificationCode:
                            this.SetFormMessage("Your verification code has expired. Please resubmit your request.", MessageSeverity.HIGH);
                            this.ResubmitVerificationCodeRequired = true;
                            this.CreateAccountData.EmailVerificationCode = null;
                            break;
                        case ErrorReasonEnum.AccountExists:
                            this.SetFormMessage("An account already exists with the email provided. Please go back to the Login page and click Forgot Password to recover your account.", MessageSeverity.HIGH);
                            break;
                        default:
                            this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                            break;
                    }
                }
            },
            () => {
                this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
            });
    }

    PreviousStep() {
        if (this.Step > 1) {
            this.ClearFormMessage();
            this.Step--;
        } else {
            this.$router.push({ name: 'Login' });
        }
    }
}
