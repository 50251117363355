


























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { PatientModel, PersonModel, ProductAlertModel, UserDataModel } from '@/models/Common';
import BackButton from '@/components/BackButton.vue';
import ImageZoomDisplay from '@/components/ImageZoomDisplay.vue';
import QRCode from '@/components/QRCode.vue';
import VaccineSelectionModal from '@/components/modals/VaccineSelectionModal.vue';
import { TestResultModel, TestResultsResponseModel } from '@/models/TestResults';
import { ProductTypeEnum, VaccineTypeEnum } from '@/models/Enums';
import { FileDownloadModel } from '@frhc/scripts/TS/FileModels';
import { ConvertAndOpenFile } from '@frhc/scripts/TS/Utilities';
import { LoadVaccineCards } from '@/utils/Services';

@Component({
    components: { BackButton, ImageZoomDisplay, QRCode, VaccineSelectionModal }
})
export default class TestResults extends Vue {
    @Prop() labResultID: number;
    @Prop() qrCode: string;

    TestResults: TestResultModel[] = [];
    HasMoreResults: boolean = false;
    TempTestResult: TestResultModel = null; //Used to hold the model for a test result the user deeplinked into that isn't a part of the loaded test list

    created() {
        let userData = (this.$store.state.UserData as UserDataModel);

        //Only load test results if we haven't loaded them before
        let initialTestResultLoadPromise: Promise<void> = this.TestResults == null || this.TestResults.length == 0 ? this.LoadTestResults(true) : null;

        //Only load vaccine cards if we haven't loaded them before
        let initialVaccineCardLoadPromise: Promise<void> = !userData.HasLoadedVaccineCardList ? LoadVaccineCards() : null;

        Promise.all([initialTestResultLoadPromise, initialVaccineCardLoadPromise]).then(() => {
            let selectedTest = null;
            if (this.labResultID != null) {
                selectedTest = this.TestResults.find(tr => { return tr.TestResultID == this.labResultID; });
            }

            //Deep link logic - if the test we are looking for isn't in our list, fetch the single test
            if ((this.labResultID != null ) && selectedTest == null) {
                this.LoadSingleTestResult(this.labResultID);
            } else if (this.labResultID != null) {
                //If we are deep linked to a result we have loaded, mark as viewed, the page logic will handle the rest for displaying result
                selectedTest.Viewed = true;
            }
        }).catch(() => {
            this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
        });
    }

    get HasResults(): boolean {
        return this.TestResults != null && this.TestResults.length > 0;
    }

    get SelectedTest(): TestResultModel {
        if (this.TempTestResult != null) { //Check temp result first in case this single result had to be loaded
            return this.TempTestResult;
        } else if (this.labResultID != null) {
            return this.TestResults.find(tr => { return tr.TestResultID == this.labResultID; });
        } else {
            return null;
        }
    }

    get IsViewingTestResultList(): boolean {
        return this.labResultID == null && this.qrCode == null;
    }

    get IsViewingTestResultDetails(): boolean {
        return this.labResultID != null && this.qrCode == null;
    }

    get IsViewingTestResultQRCode(): boolean {
        return this.labResultID != null && this.qrCode != null;
    }

    get SelectedTestPerson(): PersonModel {
        if (this.SelectedTest != null) {
            if (this.SelectedTest.PatientID != null) {
                let patient = (this.$store.state.UserData.PatientList as PatientModel[]).find((patient) => { return patient.PatientID == this.SelectedTest.PatientID; });
                return patient != null ? patient.Person : null;
            }
        } else {
            return null;
        }
    }

    get DataToEncode() {
        return this.$store.state.ReadiPassURL != null ? (this.$store.state.ReadiPassURL + 'Scanner?qrCode=' + this.qrCode) : this.qrCode;
    }

    LoadTestResults(clearCurrentData: boolean = false): Promise<void> {
        let lastTestIdsModel = {};

        if (this.TestResults != null || this.TestResults.length > 0) {
            let reversedTestResults = this.TestResults.slice(0).reverse();
            let lastLabResult = reversedTestResults.find(tr => { return tr.TestResultID != null });

            var lastTestResultID  = lastLabResult != null ? lastLabResult.TestResultID : null
        }

        if (clearCurrentData === true) {
            this.TestResults = [];
            this.HasMoreResults = false;
        }

        return Ajax.POST("/LabTest/FetchTestResults",
            { TestResultID: lastTestResultID },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let results = result.Data as TestResultsResponseModel;

                    //Loop through results and subtract from our meta count of "new results"
                    let newResultsViewed = 0;
                    results.TestResults.forEach(tr => {
                        if (tr.Viewed == false) {
                            newResultsViewed++;
                        }
                    });

                    if (newResultsViewed > 0) {
                        this.$store.commit('ReduceProductAlertCount', { ProductType: ProductTypeEnum.TestResults, Count: newResultsViewed } as ProductAlertModel);
                    }

                    //Now set the data for the page
                    this.TestResults.push(...results.TestResults);
                    this.HasMoreResults = results.HasMoreResults;
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    LoadSingleTestResult(testResultID: number): Promise<void> {
        return Ajax.POST("/LabTest/FetchSingleTestResult",
            testResultID,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let results = result.Data as TestResultModel;

                    //Decrement the meta count if this result was not viewed
                    if (results.Viewed == false) {
                        this.$store.commit('ReduceProductAlertCount', { ProductType: ProductTypeEnum.TestResults, Count: 1 } as ProductAlertModel);
                        results.Viewed = true;
                    }

                    //Now set the data for the page
                    this.TempTestResult = results;

                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    DownloadTestResultFile(testResultID: number, testResultFileID: number) {
        Ajax.POST("/LabTest/DownloadTestResult",
            { TestResultID: testResultID, TestResultFileID: testResultFileID },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    ConvertAndOpenFile(result.Data as FileDownloadModel);
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    GenerateQRCode(testResultID: number, vaccineCardID: number, force: boolean = false) {
        let vaccineCardList = (this.$store.state.UserData as UserDataModel).VaccineCardList;

        if (vaccineCardID == null && force === false && vaccineCardList != null && vaccineCardList.length > 0) {
            this.$modal.show('vaccineSelectionModal', { Person: this.SelectedTestPerson, TestResultID: testResultID, VaccineType: VaccineTypeEnum.COVID19 });
            return;
        }

        Ajax.POST("/LabTest/GenerateReadiPassQRCode",
            { TestResultID: testResultID, VaccineCardID: vaccineCardID },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                        this.$router.push({ path: '/testresults/labresult/' + this.labResultID + "/" + result.Data as string });
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    ViewDetails(testResult: TestResultModel) {
        testResult.Viewed = true;
        this.TempTestResult = null;

        if (testResult.TestResultID != null) {
            this.$router.push({ path: '/testresults/labresult/' + testResult.TestResultID });
        }
    }    
}
