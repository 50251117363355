

















































































import { Component, Vue } from "vue-property-decorator";
import { required, between, maxLength, minLength, numeric } from 'vuelidate/lib/validators';
import { inList, specialCharBlacklist } from '@/utils/CustomValidations';
import { Validations } from 'vuelidate-property-decorators';
import { VaccineCardModel, VaccineDoseModel } from '@/models/VaccineCards';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { CreateFormData } from '@frhc/scripts/TS/Utilities';
import Browser from '@/utils/Browser';
import { IDNamePairModel, PersonModel, ProfileModel, UserDataModel } from '@/models/Common';
import { CardSideEnum, GenderEnum, MessageSeverity, VaccineManufacturerEnum, VaccineTypeEnum } from '@/models/Enums';
import { ModalButtonModel, ModalButtonTypeEnum } from '@/models/Modals';
import CameraOverlay from '@/components/modals/CameraOverlay.vue';
import FormMessage from '@/components/FormMessage.vue';
import ImageZoomDisplay from '@/components/ImageZoomDisplay.vue';
import LoadingTransition from "@/components/LoadingTransition.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import FeatureButton from "@/components/testentry/FeatureButton.vue";
import PersonSection from "@/components/PersonSection.vue";
import ProfileSelectionModal from '@/components/modals/ProfileSelectionModal.vue';
import Tooltip from '@/components/Tooltip.vue';
import { CreateDictionaryFromEnum, IsDOBValid, SortVaccineDoseList } from '@/utils/GlobalFunctions';

@Component({
    components: { CameraOverlay, FeatureButton, FormMessage, ImageZoomDisplay, LoadingTransition, NumberInput, PersonSection, ProfileSelectionModal, Tooltip }
})
export default class NewVaccineCard extends Vue {
    VaccineCard: VaccineCardModel = new VaccineCardModel();
    GenderList: string[] = Object.keys(GenderEnum).map((key) => { return (GenderEnum as any)[key] as string; });
    VaccineTypeList: IDNamePairModel<number>[] = [];
    VaccineManufacturerList: IDNamePairModel<number>[] = [];

    CameraCardSide: CardSideEnum = null;
    CameraDoseIndex: number = null;
    InCameraMode: boolean = false;

    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;
    DisplaySupportLink: boolean = false;
    MessageSeverity: any = MessageSeverity;

    created() {
        this.VaccineTypeList = CreateDictionaryFromEnum(VaccineTypeEnum, this.$options.filters.FormatVaccineTypeForDisplay);
        this.VaccineManufacturerList = CreateDictionaryFromEnum(VaccineManufacturerEnum, this.$options.filters.FormatVaccineManufacturerForDisplay);
    }

    @Validations()
    validations() {
        return this.VaccineCard != null ? {
            VaccineCard: {
                VaccineType: { required },
                VaccineDoseList: {
                    $each: {
                        DOBValid: (val: VaccineDoseModel) => IsDOBValid(val.Vaccination_Month, val.Vaccination_Day, val.Vaccination_Year) === true,
                        VaccinationManufacturer: { required },
                        Vaccination_Month: { required, between: between(1, 12) },
                        Vaccination_Day: { required, between: between(1, 31) },
                        Vaccination_Year: { required, between: between(1900, new Date().getFullYear()) },
                    }
                },
                Patient: {
                    DOBValid: (val: PersonModel) => IsDOBValid(val.DOB_Month, val.DOB_Day, val.DOB_Year) === true,
                    FirstName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                    LastName: { required, specialCharBlacklist, maxLength: maxLength(50) },
                    Address: { required, specialCharBlacklist, maxLength: maxLength(75) },
                    City: { required, specialCharBlacklist, maxLength: maxLength(75) },
                    State: { required, inList: ((field: string) => { return inList(field, this.$store.state.StateList); }) },
                    Zip: { required, maxLength: maxLength(5), minLength: minLength(5), numeric: numeric },
                    DOB_Month: { required, between: between(1, 12) },
                    DOB_Day: { required, between: between(1, 31) },
                    DOB_Year: { required, between: between(1900, new Date().getFullYear()) },
                    Gender: { required, inList: ((field: string) => { return inList(field, this.GenderList); }) },
                },
            }
        } : {};
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
        this.DisplaySupportLink = false;
    }

    SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        this.DisplaySupportLink = showSupportLink;
        Browser.SmoothScroll(0);
    }

    AddDose() {
        this.VaccineCard.VaccineDoseList.push(new VaccineDoseModel());
    }

    RemoveDose(doseIndex: number) {
        this.VaccineCard.VaccineDoseList.splice(doseIndex, 1);
    }

    SaveVaccineCard(forceSave: boolean = false) {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        if (!forceSave && this.CheckForDuplicateCard()) {
            let message = "You already have a vaccine card for " + this.$options.filters.FormatPatientNameForDisplay(this.VaccineCard.Patient) + ". We recommend you add new doses to the existing card. Do you wish to proceed?";
            let buttonList = [
                { Type: ModalButtonTypeEnum.Cancel, Text: "Cancel", ClosesModal: true } as ModalButtonModel,
                { Type: ModalButtonTypeEnum.Button, Text: "Save", ClosesModal: true, Action: () => { this.SaveVaccineCard(true); } } as ModalButtonModel,
            ];
            
            this.$modal.show('interactiveModal', { Title: 'Duplicate Card', Message: message, CanClickToClose: false, IsLargeModal: false, ButtonList: buttonList });
            return;
        }

        let uploadForm = CreateFormData(this.VaccineCard, ["FrontImage", "BackImage"]);

        Browser.SmoothScroll(0);

        Ajax.POST_MULTIPART("/VaccineCard/SaveNewVaccineCard",
            uploadForm,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let serverVaccineCard = result.Data as VaccineCardModel;
                    this.VaccineCard.VaccineCardID = serverVaccineCard.VaccineCardID;
                    for (let i = 0; i < serverVaccineCard.VaccineDoseList.length; i++) {
                        this.VaccineCard.VaccineDoseList[i].VaccineDoseID = serverVaccineCard.VaccineDoseList[i].VaccineDoseID;
                    }

                    SortVaccineDoseList(this.VaccineCard.VaccineDoseList);

                    this.$store.commit("SaveVaccineCard", JSON.parse(JSON.stringify(this.VaccineCard)));
                    this.GoToVaccineCardsPage();
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    GoToVaccineCardsPage() {
        this.$router.push({ name: "Vaccine Cards" });
    }

    ScanVaccineCardFront(cameraDoseIndex: number) {
        this.CameraDoseIndex = cameraDoseIndex;
        this.CameraCardSide = CardSideEnum.Front;
        this.InCameraMode = true;
    }

    ScanVaccineCardBack(cameraDoseIndex: number) {
        this.CameraDoseIndex = cameraDoseIndex;
        this.CameraCardSide = CardSideEnum.Back;
        this.InCameraMode = true;
    }

    CaptureVaccineCardImage(imageData: string) {
        if (this.CameraCardSide == CardSideEnum.Front) {
            this.VaccineCard.VaccineDoseList[this.CameraDoseIndex].FrontImage = imageData;
        } else if (this.CameraCardSide == CardSideEnum.Back) {
                this.VaccineCard.VaccineDoseList[this.CameraDoseIndex].BackImage = imageData;
        }

        this.InCameraMode = false;
    }

    OpenProfileSelectionModal() {
        this.$modal.show('profileSelectionModal', { PersonID: null });
    }

    CheckForDuplicateCard() {
        return (this.$store.state.UserData as UserDataModel).VaccineCardList.findIndex(vc => {
            return vc.VaccineType == this.VaccineCard.VaccineType && vc.Patient.FirstName == this.VaccineCard.Patient.FirstName &&
                vc.Patient.LastName == this.VaccineCard.Patient.LastName && vc.Patient.DOB_Day == this.VaccineCard.Patient.DOB_Day &&
                vc.Patient.DOB_Month == this.VaccineCard.Patient.DOB_Month && vc.Patient.DOB_Year == this.VaccineCard.Patient.DOB_Year;
        }) > -1;
    }

    FillProfileData(profile: ProfileModel) {
        this.VaccineCard.Patient = profile.Person;
    }
}
