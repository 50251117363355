































































import { Component, Vue } from "vue-property-decorator";
import { required, between } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import { VaccineCardModel, VaccineDoseModel } from '@/models/VaccineCards';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { CreateFormData } from '@frhc/scripts/TS/Utilities';
import Browser from '@/utils/Browser';
import { IDNamePairModel } from '@/models/Common';
import { CardSideEnum, MessageSeverity, VaccineManufacturerEnum } from '@/models/Enums';
import CameraOverlay from '@/components/modals/CameraOverlay.vue';
import FeatureButton from "@/components/testentry/FeatureButton.vue";
import FormMessage from '@/components/FormMessage.vue';
import ImageZoomDisplay from '@/components/ImageZoomDisplay.vue';
import LoadingTransition from "@/components/LoadingTransition.vue";
import NumberInput from "@/components/inputs/NumberInput.vue";
import { CreateDictionaryFromEnum, IsDOBValid } from '@/utils/GlobalFunctions';

@Component({
    components: { CameraOverlay, FeatureButton, FormMessage, ImageZoomDisplay, LoadingTransition, NumberInput }
})
export default class NewVaccineDoseModal extends Vue {
    VaccineCardID: number = null;
    VaccineDose: VaccineDoseModel = null;
    VaccineManufacturerList: IDNamePairModel<number>[] = [];

    CameraCardSide: CardSideEnum = null;
    InCameraMode: boolean = false;

    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;
    DisplaySupportLink: boolean = false;

    created() {
        this.VaccineManufacturerList = CreateDictionaryFromEnum(VaccineManufacturerEnum, this.$options.filters.FormatVaccineManufacturerForDisplay);
    }

    @Validations()
    validations() {
        return this.VaccineDose != null ? {
            VaccineDose: {
                DOBValid: (val: VaccineDoseModel) => IsDOBValid(val.Vaccination_Month, val.Vaccination_Day, val.Vaccination_Year) === true,
                VaccinationManufacturer: { required },
                Vaccination_Month: { required, between: between(1, 12) },
                Vaccination_Day: { required, between: between(1, 31) },
                Vaccination_Year: { required, between: between(1900, new Date().getFullYear()) },
            }
        } : {};
    }

    Close() {
        this.$modal.hide('newVaccineDoseModal');
        this.VaccineCardID = null;
        this.VaccineDose = null;
    }

    beforeOpen(event: any) {
        this.ClearFormMessage();
        this.VaccineCardID = event.params.VaccineCardID;
        this.VaccineDose = new VaccineDoseModel();
        this.$v.$reset();
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
        this.DisplaySupportLink = false;
    }

    SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        this.DisplaySupportLink = showSupportLink;
        Browser.SmoothScroll(0);
    }

    SaveVaccineDose() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        let uploadForm = CreateFormData({ VaccineCardID: this.VaccineCardID, VaccineDoseList: [this.VaccineDose] }, ["FrontImage", "BackImage"]);

        Browser.SmoothScroll(0);

        Ajax.POST_MULTIPART("/VaccineCard/AddVaccineDose",
            uploadForm,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.VaccineDose.VaccineDoseID = (result.Data as VaccineCardModel).VaccineDoseList[0].VaccineDoseID;
                    this.$emit("AddDose", this.VaccineDose);
                    this.Close();
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    ScanVaccineCardFront() {
        this.CameraCardSide = CardSideEnum.Front;
        this.InCameraMode = true;
    }

    ScanVaccineCardBack() {
        this.CameraCardSide = CardSideEnum.Back;
        this.InCameraMode = true;
    }

    CaptureVaccineCardImage(imageData: string) {
        if (this.CameraCardSide == CardSideEnum.Front) {
            this.VaccineDose.FrontImage = imageData;
        } else if (this.CameraCardSide == CardSideEnum.Back) {
            this.VaccineDose.BackImage = imageData;
        }

        this.InCameraMode = false;
    }
}
