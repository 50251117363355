












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LoadingTransition extends Vue {
    @Prop() private isLoading!: boolean;
    @Prop() private margin!: boolean;
}
