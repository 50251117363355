
























import { Component, Vue } from 'vue-property-decorator';
import { AccountSectionEnum, ProductTypeEnum } from '../models/Enums';
import { Route, RouteConfig } from 'vue-router';
import { routes } from '@/router/index';
import { AppStateModel } from '@/store/index';
import { DeepLinkNavigation } from '@/utils/GlobalFunctions';
import NotificationBubble from '@/components/NotificationBubble.vue';

@Component({
    components: { NotificationBubble }
})
export default class Dashboard extends Vue {
    NavList: any[] = [];

    created() {

    }

    get NavItems(): RouteConfig[] {
        return routes.filter(r => { return r.meta.dashboardDisplay != null && (r.meta.requiresProduct == null || (this.$store.getters.AllEnabledProducts as ProductTypeEnum[]).some(product => product == r.meta.requiresProduct)); });
    }

    get HasFeaturesToEnable(): boolean {
        //TODO: Brian - Finish
        return false;
    }

    get HasBanners(): boolean {
        //TODO: Brian - Once we are ready to include banners again, uncomment code below
        return false;
        /*
        let bannerMessages = (this.$store.state as AppStateModel).UserData.BannerMessages;
        return bannerMessages != null && bannerMessages.length > 0;
        */
    }

    GetProductAlertCount(route: Route): number {
        let productType: ProductTypeEnum = route.meta != null ? route.meta.requiresProduct : null;
        let productAlertsList = (this.$store.state as AppStateModel).UserData.ProductAlerts;

        if (productType == null || productAlertsList == null || productAlertsList.length == 0) {
            return 0;
        } else {
            let productAlerts = productAlertsList.find(pa => { return pa.ProductType == productType; });
            return productAlerts == null ? 0 : productAlerts.Count;
        }
    }

    NavigateToAccountFeatures(): void {
        DeepLinkNavigation(this.$router, '/myaccount/' + AccountSectionEnum.Features);
    }
}
