




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { IDNamePairModel } from '@/models/Common';
import { HelpSectionEnum } from '@/models/Enums';
import { FAQModel, HelpConfigModel } from '@/models/Help';
import ContactSupport from '@/components/help/ContactSupportSection.vue';
import FAQ from '@/components/help/FAQSection.vue';
import SupportTicket from '@/components/help/SupportTicketSection.vue';

@Component({
    components: { ContactSupport, FAQ, SupportTicket }
})
export default class Help extends Vue {
    @Prop() selectedSection: HelpSectionEnum;

    SectionEnum: any = HelpSectionEnum;

    FAQList: FAQModel[] = [];
    TicketReasonList: IDNamePairModel<number>[] = [];

    created() {
        Ajax.GET("/Help/GetHelpConfig",
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let config = result.Data as HelpConfigModel;
                    this.FAQList = config.FAQList;
                    this.TicketReasonList = config.TicketReasonList;
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    private ChangeSection(section: HelpSectionEnum | null) {
        this.$router.push({ path: '/help/' + (section != null ? section : '') });
    }
}
