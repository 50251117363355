export enum AccountSectionEnum {
    Landing = null,
    Communication = 1,
    Security = 2,
    Features = 3,
    About = 4,
    PatientProfiles = 5
}

export enum HelpSectionEnum {
    ContactSupport = "contactsupport",
    FAQ = "faq",
    SupportTicket = "supportticket",
}

export enum MessageSeverity {
    POSITIVE = 1,
    QUESTION = 2,
    LOW = 3,
    MEDIUM = 4,
    HIGH = 5
}

export enum TestModeEnum {
    QuickTest = 1,
    QuickTestRapidResult = 2,
    PatientIntake = 3,
    PatientIntakeQueue = 4,
    QuickTestPrecoded = 5,
    PatientManagement = 6
}

export enum ProductTypeEnum {
    MessageCenter = 1,
    Surveys = 2,
    Payment = 4,
    Insurance = 8,
    LiveSupport = 16,
    Scheduling = 32,
    Estimates = 64,
    TestResults = 128
}

export enum TestActivationExceptionEnum { //TODO: Brian - Should we consolidate into ErrorReasonEnum?
    InvalidTestID = 1,
    AlreadySubmitted = 2
}

export enum AnswerTypeEnum {
    YNU = 1,
    Date = 2,
    TextField = 3,
    TextArea = 4,
    SelectList = 5,
    DateTime = 6,
    Time = 7,
    WeightGrams = 8,
    WeightOunces = 9,
    DateTime24hr = 10,
    Numerical = 11
}

export enum GenderEnum {
    Male = "M",
    Female = "F",
    Unknown = "U"
}

export enum CardSideEnum {
    Front = 1,
    Back = 2
}

export enum VaccineTypeEnum {
    COVID19 = 1
}

export enum VaccineManufacturerEnum {
    AstraZeneca = 1,
    BioNTech = 2,
    GlaxoSmithKline = 3,
    JohnsonAndJohnson = 4,
    Merck = 5,
    Moderna = 6,
    Novavax = 7,
    Pfizer = 8,
    Sanofi = 9,
    Other = 10
}

export enum TestEntryErrorEnum { //TODO: Brian - Should we consolidate into ErrorReasonEnum?
    InvalidField = 1
}

export enum FeatureEnum {
    UseGuardianGuarantor = 2,
    CaptureDriversLicense = 4,
    CanFreeTypeInsurances = 8
}

export enum TestResultEnum {
    Positive = "Positive",
    Negative = "Negative",
    Invalid = "Invalid"
}

export enum ConsentDataTypeEnum {
    None = 1,
    Email = 2,
    SMS = 3,
    HIPAA = 4
}

export enum UnitOfMeasureEnum {
    Grams = 1,
    Pounds = 2,
    Ounces = 3
}

export enum AOEValidationEnum {
    Required = 1,
}

export enum ErrorReasonEnum {
    UnableToProcess = 1,
    AccountLocked = 2,
    InvalidDOB = 3,
    InvalidCredentials = 4,
    InvalidVerificationCode = 5,
    ResubmitVerificationCode = 6,
    AppointmentUnavailable = 7,
    AccountExists = 8,
    AccountDoesNotExist = 9,
    InvalidEmail = 10,
    ResubmitResetGuid = 11,
    CommunicationBlocked = 12,
    InvalidLinkingZipName = 13
}

export enum ScannerModeEnum {
    Barcode = 1,
    License = 2,
    QRCode = 3
}

export enum ScanStatusEnum {
    Ready = 1,
    Processing = 2,
    Pass = 3,
    Fail = 4
}

export enum SelectionMode {
    Selection = 1,
    Edit = 2
}

export enum CommunicationTypeEnum {
    Email = 1,
    SMS = 2,
    Push = 3
}

export enum DemoVerificationOperatorEnum {
    GreaterThanEqualTo = 1,
    LessThanEqualTo = 2,
}

export enum DemoVerificationTypeEnum {
    DOB = 1,
    Gender = 2,
}

export enum BrowserPermissionEnum {
    Prompt = 'prompt', //there's no peristent permission registered, will be showing the prompt
    Granted = 'granted', //permission has already been granted, no prompt is shown
    Denied = 'denied', //permission has been denied
    NotQueryable = 'notQueryable' //This is not based on browser response. This is custom and was added for FF Browser
}