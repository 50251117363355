










import { Component, Prop, Vue } from 'vue-property-decorator';
import { SelectionMode } from '@/models/Enums';
import BackButton from '@/components/BackButton.vue';
import PasswordChange from '@/components/PasswordChange.vue';
import ProfileManagement from "@/components/ProfileManagement.vue";

@Component({
    components: { BackButton, PasswordChange, ProfileManagement }
})
export default class PatientProfiles extends Vue {
    ProfileSelectionMode: SelectionMode = SelectionMode.Selection;

    get IsProfileSelectionMode() {
        return this.ProfileSelectionMode == SelectionMode.Selection;
    }
}
