






// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: {  }
})
export default class Checkbox extends Vue {
    @Prop() label!: boolean;
    @Prop() isChecked!: boolean;
    @Prop() isLarge!: boolean;
    @Prop() isInvalid: boolean;
    @Prop() isDisabled: boolean;

    created() {

    }
}
