
















// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import QRCodeGenerator from 'qrcode';

@Component
export default class QRCode extends Vue {
    @Prop() dataToEncode: string;
    @Prop() fileName: string;
    @Prop() canDownload: boolean;

    Loading: boolean = false;
    DataURL: string = null;
    Size: number = null;

    mounted() {
        this.GenerateDataURL(null);
    }

    ChangeRenderSize() {
        this.Loading = true;

        window.setTimeout(() => { //Adding delay for loading text to appear
            this.GenerateDataURL(this.Size != null ? { width: this.Size } : {});
        }, 100);
    }

    GenerateDataURL(options: any) {
        QRCodeGenerator.toDataURL(this.dataToEncode, options != null ? options : {}, (error, url) => {
            if (error) { console.error(error); }
            if (url) { this.DataURL = url; }
            this.Loading = false;
        });
    }
}
