import { IDNamePairModel } from "./Common";

export let lowResCameraSettings = {
    "audio": false,
    "video": {
        "facingMode": "environment",
        "width": { "ideal": 1280 },
        "height": { "ideal": 720 }
    }
};

export let highResCameraSettings = {
    "audio": false,
    "video": {
        "facingMode": "environment",
        "width": { "ideal": 1920 },
        "height": { "ideal": 1080 }
    }
};

export let DSBRScannerSettings = {
    scanner: {
        frameTimeout: 100,
        barcodeTimeout: 1000,
        drawTimeout: 500,
        beep: false,
        //beepData: "/beep.mp3",
        barcodeAtPoint: false,
        key: null as string, //Dynamic fill in
    },
    viewport: {
        id: null as string, //Dynamic fill in
    },
    camera: {
        facingMode: "environment",
        resx: 1920,
        resy: 1080,
    },
    barcode: {
        barcodeTypes: [] as string[], //Dynamic fill in
    }
};

export let ethnicityList: IDNamePairModel<string>[] = [
    { ID: 'H', Name: 'Hispanic' },
    { ID: 'N', Name: 'Non-Hispanic' },
    { ID: 'U', Name: 'Unknown' },
];

export let raceList: IDNamePairModel<string>[] = [
    { ID: 'I', Name: 'Native American/Alaskan Native' },
    { ID: 'W', Name: 'White' },
    { ID: 'B', Name: 'Black' },
    { ID: 'A', Name: 'Asian/Pacific Islander' },
    { ID: 'M', Name: 'Multiracial' },
    { ID: 'O', Name: 'Other' },
    { ID: 'U', Name: 'Unknown' }
];

export let dbrLicenseKeyConfig = {
    "DCA": "Jurisdiction-specific vehicle class",
    "DBA": "Expiry Date",
    "DCS": "Last Name",
    "DAC": "First Name", //Most common
    "DCT": "First Name", //Only for certain states
    "DBD": "Issue Date",
    "DBB": "Birth Date",
    "DBC": "Gender",
    "DAY": "Eye Color",
    "DAU": "Height",
    "DAG": "Street",
    "DAI": "City",
    "DAJ": "State",
    "DAK": "Zip",
    "DAQ": "License Number",
    "DCF": "Document Discriminator",
    "DCG": "Issue Country",
    "DAH": "Street 2",
    "DAZ": "Hair Color",
    "DCI": "Place of birth",
    "DCJ": "Audit information",
    "DCK": "Inventory Control Number",
    "DBN": "Alias / AKA Family Name",
    "DBG": "Alias / AKA Given Name",
    "DBS": "Alias / AKA Suffix Name",
    "DCU": "Name Suffix",
    "DCE": "Physical Description Weight Range",
    "DCL": "Race / Ethnicity",
    "DCM": "Standard vehicle classification",
    "DCN": "Standard endorsement code",
    "DCO": "Standard restriction code",
    "DCP": "Jurisdiction-specific vehicle classification description",
    "DCQ": "Jurisdiction-specific endorsement code description",
    "DCR": "Jurisdiction-specific restriction code description",
    "DDA": "Compliance Type",
    "DDB": "Card Revision Date",
    "DDC": "HazMat Endorsement Expiration Date",
    "DDD": "Limited Duration Document Indicator",
    "DAW": "Weight(pounds)",
    "DAX": "Weight(kilograms)",
    "DDH": "Under 18 Until",
    "DDI": "Under 19 Until",
    "DDJ": "Under 21 Until",
    "DDK": "Organ Donor Indicator",
    "DDL": "Veteran Indicator",
    // old standard
    "DAA": "Customer Full Name",
    "DAB": "Customer Last Name",
    "DAE": "Name Suffix",
    "DAF": "Name Prefix",
    "DAL": "Residence Street Address1",
    "DAM": "Residence Street Address2",
    "DAN": "Residence City",
    "DAO": "Residence Jurisdiction Code",
    "DAR": "License Classification Code",
    "DAS": "License Restriction Code",
    "DAT": "License Endorsements Code",
    "DAV": "Height in CM",
    "DBE": "Issue Timestamp",
    "DBF": "Number of Duplicates",
    "DBH": "Organ Donor",
    "DBI": "Non-Resident Indicator",
    "DBJ": "Unique Customer Identifier",
    "DBK": "Social Security Number",
    "DBM": "Social Security Number",
    "DCH": "Federal Commercial Vehicle Codes",
    "DBR": "Name Suffix",
    "PAA": "Permit Classification Code",
    "PAB": "Permit Expiration Date",
    "PAC": "Permit Identifier",
    "PAD": "Permit IssueDate",
    "PAE": "Permit Restriction Code",
    "PAF": "Permit Endorsement Code",
    "ZVA": "Court Restriction Code",
    "DAD": "Middle Name"
};

export let stateList: string[] = [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VI",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NS",
    "NT",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT"
];