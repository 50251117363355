import { PersonModel } from "./Common";

export class CreateAccountModel {
    PatientGuid: string;
    Email: string;
    EmailVerificationCode: string;
    Password: string;
    Person: PersonModel;

    constructor() {
        this.PatientGuid = null;
        this.Email = null;
        this.EmailVerificationCode = null;
        this.Password = null;
        this.Person = new PersonModel();
    }
}