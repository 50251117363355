















// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { UnitOfMeasureEnum } from '@/models/Enums';
import NumberInput from '@/components/inputs/NumberInput.vue';

@Component({
    components: { NumberInput }
})
export default class WeightInput extends Vue {
    @Prop() weight: string;
    @Prop() weightUofM: UnitOfMeasureEnum;
    @Prop() isInvalid: boolean;
    @Prop() minWeight!: number;
    @Prop() maxWeight!: number;
    @Prop() customWarningMessage!: string;
    @Prop() displayWeightWarning!: boolean

    LocalWeight: number = null;
    LocalUofM: UnitOfMeasureEnum = UnitOfMeasureEnum.Grams;
    UnitOfMeasureEnum = UnitOfMeasureEnum;
    MinWeightInLocal: number = null;
    MaxWeightInLocal: number = null;

    IsToolTipDisplaying: boolean = false;
    InputWarningMessage: string = null;


    created() {
        if (this.minWeight != null) {
            this.MinWeightInLocal = parseFloat(this.ConvertWeight(this.weightUofM, this.LocalUofM, this.minWeight).toFixed(3));
        }

        if (this.maxWeight != null) {
            this.MaxWeightInLocal = parseFloat(this.ConvertWeight(this.weightUofM, this.LocalUofM, this.maxWeight).toFixed(3));
        }

        if (this.weight == null || isNaN(parseFloat(this.weight)) || this.weight.toString().length == 0) {
            this.LocalWeight = null;
            return;
        }

        let weightNumberValue = parseFloat(this.weight);
        let weightInOunces = 0;

        switch (this.weightUofM) {
            case UnitOfMeasureEnum.Ounces:
                weightInOunces = weightNumberValue;
                break;
            case UnitOfMeasureEnum.Grams:
                weightInOunces = weightNumberValue * 0.035274;
                break;
            case UnitOfMeasureEnum.Pounds:
                weightInOunces = weightNumberValue * 16;
                break;
        }

        switch (this.LocalUofM) {
            case UnitOfMeasureEnum.Ounces:
                this.LocalWeight = parseFloat(weightInOunces.toFixed(2));
                break;
            case UnitOfMeasureEnum.Grams:
                this.LocalWeight = parseFloat((weightInOunces * 28.3495).toFixed(2));
                break;
            case UnitOfMeasureEnum.Pounds:
                this.LocalWeight = parseFloat((weightInOunces * 0.0625).toFixed(2));
                break;
            default:
                this.LocalWeight = parseFloat((weightInOunces).toFixed(2));
                break;
        }
    }

    UpdateWeightType() {
        if (this.minWeight != null) {
            this.MinWeightInLocal = parseFloat(this.ConvertWeight(this.weightUofM, this.LocalUofM, this.minWeight).toFixed(3));
        }
        if (this.maxWeight != null) {
            this.MaxWeightInLocal = parseFloat(this.ConvertWeight(this.weightUofM, this.LocalUofM, this.maxWeight).toFixed(3));
        }
        this.UpdateWeight();
    }

    UpdateWeight() {
        this.InputWarningMessage = null;
        this.IsToolTipDisplaying = false;

        if (this.LocalWeight == null || isNaN(this.LocalWeight) || this.LocalWeight.toString().length == 0) {
            this.$emit("update", null);
            return;
        }

        if (this.MinWeightInLocal != null && this.LocalWeight < this.MinWeightInLocal) {
            this.InputWarningMessage = this.customWarningMessage != null && this.customWarningMessage.length > 0 ? this.customWarningMessage : "Weight Minimum : " + this.MinWeightInLocal + " " + this.UnitOfMeasureEnum[this.LocalUofM];
        }

        if (this.MaxWeightInLocal != null && this.LocalWeight > this.MaxWeightInLocal) {
            this.InputWarningMessage = this.customWarningMessage != null && this.customWarningMessage.length > 0 ? this.customWarningMessage : "Weight Maximum : " + this.MaxWeightInLocal + " " + this.UnitOfMeasureEnum[this.LocalUofM];
        }

        let localWeight = parseFloat(this.LocalWeight as any as string);

        let newWeight = this.ConvertWeight(this.LocalUofM, this.weightUofM, localWeight);

        this.$emit("update", newWeight.toFixed(2));
    }

    ConvertWeight(fromType: UnitOfMeasureEnum, toType: UnitOfMeasureEnum, weight: number) {
        if (weight == null)
            return;

        if (fromType == toType)
            return weight;

        let weightInOunces = 0;

        switch (fromType) {
            case UnitOfMeasureEnum.Ounces:
                weightInOunces = weight;
                break;
            case UnitOfMeasureEnum.Grams:
                weightInOunces = weight * 0.035274;
                break;
            case UnitOfMeasureEnum.Pounds:
                weightInOunces = weight * 16;
                break;
        }

        switch (toType) {
            case UnitOfMeasureEnum.Ounces:
                return weightInOunces;
            case UnitOfMeasureEnum.Grams:
                return weightInOunces * 28.3495;
            case UnitOfMeasureEnum.Pounds:
                return weightInOunces * 0.0625;
            default:
                return weightInOunces;
        }
    }

}
