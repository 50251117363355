




// @ is an alias to /src
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    components: { }
})
export default class VersionDisplay extends Vue {
    VersionDate: string = process.env.PACKAGE_VERSION;

    get DisplayVersionName(): string {
        return null;
    }
}
