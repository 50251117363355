













// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import NumberInput from '@/components/inputs/NumberInput.vue';

@Component({
    components: { NumberInput }
})
export default class DateInput extends Vue {
    @Prop() date!: string;
    @Prop() showPicker!: boolean;
    @Prop() isInvalid: boolean;

    Month: number = null;
    Day: number = null;
    Year: number = null;
    IsDatePickerSupported: boolean = false;

    created() {
        if (this.date != null) {
            let splitDate = this.date.split("/");

            if (splitDate.length == 3) {
                this.Month = parseInt(splitDate[0]);
                this.Day = parseInt(splitDate[1]);
                this.Year = parseInt(splitDate[2]);
            }
        }

        //Check if the browser supports a date picker - we can tell by whether the browser sanitizes date input data
        const input = document.createElement('input');
        input.type = 'date';
        input.value = 'invalid date value';
        this.IsDatePickerSupported = input.value !== 'invalid date value';
    }

    mounted() {

    }

    get DateFormattedForPicker(): string {
        return this.Year + "-" + this.Month + "-" + this.Day;
    }

    DatePickerUpdate(e: any): void {
        if (e.target.value == null || e.target.value.indexOf("-") == -1) {
            return;
        }

        let dateValueArray = (e.target.value as string).split("-");

        this.Year = parseInt(dateValueArray[0]);
        this.Month = parseInt(dateValueArray[1]);
        this.Day = parseInt(dateValueArray[2]);

        this.UpdateDate();
    }

    UpdateDate() {
        if (isNaN(parseInt(this.Month as any)) || isNaN(parseInt(this.Day as any)) || isNaN(parseInt(this.Year as any))) {
            this.$emit("update", null);
        } else {
            this.$emit('update', this.Month + "/" + this.Day + "/" + this.Year);
        }
    }
}
