



















import { Component, Vue } from "vue-property-decorator";
import { lowResCameraSettings } from "@/models/Config.ts";

@Component
export default class CameraOverlay extends Vue {
    SnapshotWidth: number = 1280;
    SnapshotHeight: number = 720;
    SnapshotTaken: boolean = false;

    created() {

    }

    mounted() {
        //Access Camera
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(lowResCameraSettings).then((stream) => {
                (this.$refs.LiveVideo as HTMLVideoElement).srcObject = stream;
                (this.$refs.LiveVideo as HTMLVideoElement).play();
            });
        }
    }

    beforeDestroy() {
        try {
            //Stop the camera streams when this component is destroyed
            let stream = (this.$refs.LiveVideo as HTMLVideoElement).srcObject as MediaStream;
            stream.getTracks().forEach(t => { t.stop(); });
        } catch (e) {
            alert(e);
        }
        
    }

    TakePhoto() {
        let stream = (this.$refs.LiveVideo as HTMLVideoElement).srcObject as MediaStream;
        let streamWidth = stream.getVideoTracks()[0].getSettings().width;
        let streamHeight = stream.getVideoTracks()[0].getSettings().height;

        //Draw snapshot
        (this.$refs.Snapshot as HTMLCanvasElement).width = streamWidth;
        (this.$refs.Snapshot as HTMLCanvasElement).height = streamHeight;
        (this.$refs.Snapshot as HTMLCanvasElement).getContext('2d').drawImage((this.$refs.LiveVideo as HTMLVideoElement), 0, 0, streamWidth, streamHeight);

        this.SnapshotTaken = true;
    }

    Cancel() {
        this.SnapshotTaken = true;
        window.setTimeout(() => { this.$emit('CameraCancel'); }, 100); //To fix mobile chrome bug
    }

    Undo() {
        this.SnapshotTaken = false;
    }

    AcceptPhoto() {
        let dataURL = (this.$refs.Snapshot as HTMLCanvasElement).toDataURL();
        this.$emit('SnapshotData', dataURL);
    }
}
