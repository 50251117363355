
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { FAQModel } from '@/models/Help';
import BackButton from '@/components/BackButton.vue';

@Component({
    components: { BackButton }
})
export default class FAQSection extends Vue {
    @Prop() faqList: FAQModel[];

    SearchText: string = "";

    get FilteredFAQList() {
        if (this.SearchText == null || this.SearchText.length == 0) {
            return this.faqList;
        } else {
            let terms = this.SearchText.split(" ").map(term => { return term.toLowerCase(); });

            return this.faqList.filter(faq => {
                return terms.every(term => { return faq.Question.toLowerCase().indexOf(term) > -1 || faq.Answer.toLowerCase().indexOf(term) > -1; })
            });
        }
    }
}
