


















import { Component, Vue } from "vue-property-decorator";
import { ModalButtonModel, ModalButtonTypeEnum } from '@/models/Modals';

@Component
export default class InteractiveModal extends Vue {
    Title: string = null;
    Message: string = null;
    CanClickToClose: boolean = false;
    IsLargeModal: boolean = false;
    ButtonList: ModalButtonModel[] = [];

    ModalButtonType: any = ModalButtonTypeEnum;

    Close() {
        this.$modal.hide('interactiveModal');
        this.Title = null;
        this.Message = null;
        this.CanClickToClose = false;
        this.IsLargeModal = false;
        this.ButtonList = [];
    }

    ExecuteButtonFunction(button: ModalButtonModel) {
        if (button.Action != null && typeof button.Action === "function") {
            button.Action();
        }

        if (button.ClosesModal === true) {
            this.Close();
        }
    }

    beforeOpen(event: any) {
        this.Title = event.params.Title;
        this.Message = event.params.Message;
        this.CanClickToClose = event.params.CanClickToClose;
        this.IsLargeModal = event.params.IsLargeModal;
        this.ButtonList = event.params.ButtonList;
    }
}
