















import { Component, Vue } from 'vue-property-decorator';
import { UserDataModel } from '@/models/Common';
import { VaccineCardModel } from '@/models/VaccineCards';
import { LoadVaccineCards } from '@/utils/Services';

@Component({
    components: { }
})
export default class VaccineCards extends Vue {
    VaccineCards: VaccineCardModel[] = [];

    created() {
        let userData = (this.$store.state.UserData as UserDataModel);
        let initialVaccineCardLoadPromise: Promise<void> = !userData.HasLoadedVaccineCardList ? LoadVaccineCards() : null;

        Promise.all([initialVaccineCardLoadPromise]).then(() => {
            this.VaccineCards = JSON.parse(JSON.stringify((this.$store.state.UserData as UserDataModel).VaccineCardList)) as VaccineCardModel[];
        }).catch(() => {
            this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
        });
    }

    get HasVaccineCards(): boolean {
        return this.VaccineCards != null && this.VaccineCards.length > 0;
    }

    DisplayLatestVaccinationDate(vaccineCard: VaccineCardModel) {
        if (vaccineCard.VaccineDoseList == null || vaccineCard.VaccineDoseList.length == 0) {
            return "";
        }
        let latestVaccineDose = vaccineCard.VaccineDoseList[0];
        return `${latestVaccineDose.Vaccination_Month}/${latestVaccineDose.Vaccination_Day}/${latestVaccineDose.Vaccination_Year}`;
    }

    NewVaccineCard(): void {
        this.$router.push({ name: "New Vaccine Card" });
    }

    ViewDetails(vaccineCard: VaccineCardModel) {
        this.$router.push({ path: '/vaccinecards/' + vaccineCard.VaccineCardID });
    }
}
