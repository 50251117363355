




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { UserDataModel } from '@/models/Common';
import { VaccineCardModel, VaccineDoseModel } from '@/models/VaccineCards';
import BackButton from '@/components/BackButton.vue';
import ImageZoomDisplay from '@/components/ImageZoomDisplay.vue';
import QRCode from '@/components/QRCode.vue';
import NewVaccineDoseModal from "@/components/modals/NewVaccineDoseModal.vue";
import RemovalWarningModal from "@/components/modals/RemovalWarningModal.vue";
import { SortVaccineDoseList } from '@/utils/GlobalFunctions';
import { LoadVaccineCards } from '@/utils/Services';

@Component({
    components: { BackButton, ImageZoomDisplay, NewVaccineDoseModal, QRCode, RemovalWarningModal }
})
export default class VaccineCards extends Vue {
    @Prop() vaccineCardID: number;

    SelectedVaccineCard: VaccineCardModel = null;

    created() {
        let userData = (this.$store.state.UserData as UserDataModel);
        let initialVaccineCardLoadPromise: Promise<void> = !userData.HasLoadedVaccineCardList ? LoadVaccineCards() : null;

        Promise.all([initialVaccineCardLoadPromise]).then(() => {
            let vaccineCard = (this.$store.state.UserData as UserDataModel).VaccineCardList.find(vc => { return vc.VaccineCardID == this.vaccineCardID; });
            this.SelectedVaccineCard = vaccineCard != null ? JSON.parse(JSON.stringify(vaccineCard)) as VaccineCardModel : null;

            if (this.SelectedVaccineCard != null && this.SelectedVaccineCard.HasImagesToLoad) {
                this.LoadImages(this.SelectedVaccineCard);
            }
        }).catch(() => {
            this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
        });
    }

    OpenRemoveVaccineCardModal(vaccineCard: VaccineCardModel) {
        let message = 'Are you sure you want to remove your <span class="bold"> ' +
            this.$options.filters.FormatVaccineTypeForDisplay(vaccineCard.VaccineType) +
            '</span> card for <span class="bold"> ' +
            this.$options.filters.FormatPatientNameForDisplay(vaccineCard.Patient) +
            '</span>';

        this.$modal.show('removalWarningModal', {
            Title: 'Please Confirm', Message: message, Callback: () => { this.RemoveVaccineCard(vaccineCard.VaccineCardID); }
        });
    }

    OpenNewVaccineDoseModal() {
        this.$modal.show('newVaccineDoseModal', {
            VaccineCardID: this.SelectedVaccineCard.VaccineCardID
        });
    }

    AddDose(vaccineDose: VaccineDoseModel) {
        this.SelectedVaccineCard.VaccineDoseList.unshift(vaccineDose);

        SortVaccineDoseList(this.SelectedVaccineCard.VaccineDoseList);

        this.$store.commit("SaveVaccineCard", JSON.parse(JSON.stringify(this.SelectedVaccineCard)));
    }

    RemoveVaccineCard(vaccineCardID: number): void {
        Ajax.POST("/VaccineCard/RemoveVaccineCard",
            vaccineCardID,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.$store.commit("RemoveVaccineCard", vaccineCardID);
                    this.$router.go(-1);
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    LoadImages(vaccineCard: VaccineCardModel) {
        Ajax.POST("/VaccineCard/FetchVaccineCardImages",
            vaccineCard.VaccineCardID,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    (result.Data as VaccineDoseModel[]).forEach(serverDose => {
                        let dose = vaccineCard.VaccineDoseList.find(d => { return d.VaccineDoseID == serverDose.VaccineDoseID; });
                        if (dose != null) {
                            dose.FrontImage = serverDose.FrontImage;
                            dose.BackImage = serverDose.BackImage;
                        }
                    });

                    vaccineCard.HasImagesToLoad = false;

                    this.$store.commit("SaveVaccineCard", JSON.parse(JSON.stringify(vaccineCard)));
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    GenerateQRCode(testResultID: number) {
        if (this.SelectedVaccineCard.QRCode != null && this.SelectedVaccineCard.QRCode.length > 0) {
            this.$router.push({ path: '/vaccinecards/' + this.vaccineCardID + "/" + this.SelectedVaccineCard.QRCode });
            return;
        }

        Ajax.POST("/VaccineCard/GenerateReadiPassQRCode",
            { TestResultID: null, VaccineCardID: testResultID },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.SelectedVaccineCard.QRCode = result.Data as string;
                    this.$store.commit("SaveVaccineCard", JSON.parse(JSON.stringify(this.SelectedVaccineCard)));

                    this.$router.push({ path: '/vaccinecards/' + this.vaccineCardID + "/" + this.SelectedVaccineCard.QRCode });
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }
}
