





















import { Component, Vue } from "vue-property-decorator";
import { MessageSeverity } from '@/models/Enums';
import FormMessage from '@/components/FormMessage.vue';

@Component({
    components: { FormMessage }
})
export default class RemovalWarningModal extends Vue {
    Title: string = null;
    Message: string = null;
    WarningMessage: string = null;
    ActionButtonText: string = "Remove";
    Data: any = null;
    MessageSeverityEnum = MessageSeverity;

    created() {
        
    }

    Close() {
        this.$modal.hide('removalWarningModal');
        this.Title = null;
        this.Message = null;
        this.WarningMessage = null;
        this.ActionButtonText = "Remove";
        this.Data = null;
    }

    Remove() {
        this.$emit('RemoveConfirmed', this.Data);
        this.Close();
    }

    beforeOpen(event: any) {
        this.Title = event.params.Title;
        this.Message = event.params.Message;
        this.WarningMessage = event.params.WarningMessage;
        this.ActionButtonText = event.params.ActionButtonText != null ? event.params.ActionButtonText : "Remove";
        this.Data = event.params.Callback;
    }
}
