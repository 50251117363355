














import { Component, Vue } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import BackButton from '@/components/BackButton.vue';
import TOSModal from '@/components/modals/TOSModal.vue';
import VersionDisplay from '@/components/VersionDisplay.vue';
import { LegalAgreementModel } from '@/models/TOS';

@Component({
    components: { BackButton, TOSModal, VersionDisplay }
})
export default class AboutSection extends Vue {

    FetchPrivacyPolicy() {
        Ajax.POST("/App/FetchPrivacyPolicy",
            null,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.$modal.show('messageModal', { Title: 'Privacy Policy', Message: result.Data as string, CanClickToClose: false, DisplayOkayButton: true });
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }

    FetchTOS() {
        Ajax.POST("/App/FetchTOS",
            null,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.$modal.show('tosModal', { TOSList: result.Data as LegalAgreementModel[], CanClickToClose: false, DisplayOkayButton: true });
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }
}
