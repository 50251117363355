










import { Component, Vue } from 'vue-property-decorator';
import BackButton from '@/components/BackButton.vue';
import ContactSupport from '@/components/ContactSupport.vue';

@Component({
    components: { BackButton, ContactSupport }
})
export default class ContactSupportSection extends Vue {

}
