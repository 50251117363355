












// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { InsuranceMasterModel, TestEntryConfigModel } from '@/models/TestEntry';
import Tooltip from '@/components/Tooltip.vue';


@Component({
    components: { Tooltip }
})
export default class InsuranceSelection extends Vue {
    @Prop() insuranceName!: string;
    @Prop() insuranceMasterID!: string;
    @Prop() isGuarantor!: string;
    @Prop() isInError!: number;
    @Prop() componentIndex!: number;
    @Prop() canFreeTypeInsurances!: boolean;
    @Prop() requireClientInfo!: boolean;
    InsuranceMasterList: InsuranceMasterModel[] = [];
    WaitingForIMLookup: boolean = false;
    ComponentID: string = null;
    FailedInsuranceName: string = null;

    created() {
        this.ComponentID = 'dlInsuranceMasters_' + new Date().getTime().toString() + this.componentIndex;

        if (this.insuranceName != null && this.insuranceName != '' && !this.canFreeTypeInsurances) {
            this.FetchInsuranceMasterList(this.insuranceName, true)
        }
    }

    UpdateValue(event: any) {
        if (this.FailedInsuranceName != null)
            this.FailedInsuranceName = null;

        //Update parent TP Name value
        this.$emit("updateInsuranceName", event.target.value);

        //Fetch autocomplete list
        this.FetchInsuranceMasterList(event.target.value);
    }

    OnBlurCheckValue(): void {
        if (this.InsuranceMasterList.find((im) => { return im.InsuranceName.toLowerCase() == this.insuranceName.toLowerCase() }) == null && !this.canFreeTypeInsurances) {
            this.$emit("updateInsuranceName", null);
            this.$emit("updateInsuranceData", null, null);
        }
    }

    FetchInsuranceMasterList(searchTerm: string, clearDataIfNoMatch: boolean = false) {
        if (searchTerm == null || searchTerm.length < 4) {
            this.InsuranceMasterList = [];
            this.$forceUpdate();
            return;
        }

        if (!this.WaitingForIMLookup) {
            this.WaitingForIMLookup = true;

            Ajax.POST('/Eligibility/GetInsuranceMasterList',
                { SearchTerm: searchTerm, LabXClientID: (this.$store.state.TestEntryData as TestEntryConfigModel)?.LabxchangeClientID, RequireClientInfo: this.requireClientInfo},
                (result: AjaxResponse) => {
                    if (result.WasSuccess) {
                        //Set the Insurance Master list and update component
                        this.InsuranceMasterList = result.Data as InsuranceMasterModel[];
                        this.WaitingForIMLookup = false;
                        this.$forceUpdate();

                        //Check if this is a match for any of the insurance masters
                        let selectedIM = this.InsuranceMasterList.find((im) => { return im.InsuranceName.toLowerCase() == searchTerm.toLowerCase() });
                        if (selectedIM != null) {
                            //Update parent IM InsuranceMasterID and TradingPartnerID values
                            this.$emit("updateInsuranceData", selectedIM.InsuranceMasterID, selectedIM.TradingPartnerID);
                        } else {
                            //Update parent IM
                            this.$emit("updateInsuranceData", null, null);
                            if (clearDataIfNoMatch) {
                                this.$emit("updateInsuranceName", null);
                                this.FailedInsuranceName = searchTerm;
                            }
                        }

                        //Check if the user changed the Insurance Name while we were search
                        if (this.insuranceName != null && this.insuranceName.length > 2 && searchTerm.toLowerCase() != this.insuranceName.toLowerCase()) {
                            this.FetchInsuranceMasterList(this.insuranceName);
                        }
                    } else {
                        console.log("The GetInsuranceMasterList ajax request returned but was not successful.");
                        this.InsuranceMasterList = [];
                        this.WaitingForIMLookup = false;
                        this.$forceUpdate();
                    }
                },
                () => {
                    console.log("The GetInsuranceMasterList ajax request returned but was not successful.");
                    this.InsuranceMasterList = [];
                    this.WaitingForIMLookup = false;
                    this.$forceUpdate();
                },
                this.$store.getters.GenerateAuthHeaders, false);
        }
    }
}
