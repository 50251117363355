import { VaccineCardModel } from "../models/VaccineCards";
import store from "../store";
import { Ajax, AjaxResponse } from "@frhc/scripts/TS/Ajax";

export function LoadVaccineCards(): Promise <void> {
    return Ajax.GET("/VaccineCard/GetVaccineCards",
        (result: AjaxResponse) => {
            if (result.WasSuccess) {
                store.commit("LoadVaccineCards", result.Data as VaccineCardModel[]);
            } else {
                throw new Error("WasSuccess false");
            }
        },
        (e: any) => {
            throw e;
        },
        store.getters.GenerateAuthHeaders
    );
}