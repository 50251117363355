














import { Component, Vue } from "vue-property-decorator";

@Component
export default class RefreshAppModal extends Vue {
    Refresh() {
        window.location.reload(true);
    }
}
