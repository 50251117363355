




















// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AOEAnswerModel, AOEQuestionModel } from '@/models/TestEntry';
import AOEQuestion from '@/components/testentry/AOEQuestion.vue';

@Component({
    components: { AOEQuestion }
})
export default class AOEQuestionSection extends Vue {
    @Prop() Answers!: AOEAnswerModel[];
    @Prop() Questions!: AOEQuestionModel[];
    @Prop() DisplayValidation!: boolean;
    @Prop() ValidationErrorList!: any[];

    created() {

    }

    CanShowDependantQuestion(parentQuestionID: number, parentQuestionAnswer: string) {
        if (parentQuestionID == null || parentQuestionAnswer == null) {
            return true; //Not a dependant question
        }

        let answer = this.Answers.find((a) => { return a.QuestionID == parentQuestionID; });
        return answer != null && answer.Answer == parentQuestionAnswer;
    }

    GetAnswer(questionID: number) {
        let answer = this.Answers.find((a) => { return a.QuestionID == questionID; });
        return answer != null ? answer.Answer : null;
    }

    Update(answerModel: AOEAnswerModel) {
        this.$emit('Update', answerModel);
    }
}
