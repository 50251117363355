






























import { Component, Vue } from "vue-property-decorator";
import { IDNamePairModel, PersonModel, UserDataModel } from '@/models/Common';
import { VaccineTypeEnum } from '@/models/Enums';
import { VaccineCardModel } from "@/models//VaccineCards";
import { CreateDictionaryFromEnum } from '@/utils/GlobalFunctions';

@Component({
    components: { }
})
export default class VaccineSelectionModal extends Vue {
    VaccineTypeList: IDNamePairModel<number>[] = [];

    VaccineType: VaccineTypeEnum = null;
    TestResultID: number = null;
    VaccineID: number = null;
    Person: PersonModel = null;

    created() {
        this.VaccineTypeList = CreateDictionaryFromEnum(VaccineTypeEnum, this.$options.filters.FormatVaccineTypeForDisplay);
    }

    get DisplayVaccineCardList(): VaccineCardModel[] {
        return this.VaccineType != null ? (this.$store.state.UserData as UserDataModel).VaccineCardList.filter(vc => { return vc.VaccineType == this.VaccineType; }) : [];
    }

    Close() {
        this.$modal.hide('vaccineSelectionModal');
        this.TestResultID = null;
        this.VaccineType = null;
        this.VaccineID = null;
        this.Person = null;
    }

    MakeSelection(share: boolean) {
        this.$emit('VaccineCardSelected', this.TestResultID, share ? this.VaccineID : null);
        this.Close();
    }

    beforeOpen(event: any) {
        this.TestResultID = event.params.TestResultID;
        this.Person = event.params.Person;
        this.VaccineType = event.params.VaccineType;
        this.VaccineID = null;

        //Try to preselect patient based on demo info
        if (this.Person != null) {
            let matchingVaccineCard = this.DisplayVaccineCardList.find(vc => {
                return vc.Patient.FirstName.toLowerCase() == this.Person.FirstName.toLowerCase() &&
                    vc.Patient.LastName.toLowerCase() == this.Person.LastName.toLowerCase() &&
                    vc.Patient.DOB_Day == this.Person.DOB_Day &&
                    vc.Patient.DOB_Month == this.Person.DOB_Month &&
                    vc.Patient.DOB_Year == this.Person.DOB_Year;
            });

            if (matchingVaccineCard != null) {
                this.VaccineID = matchingVaccineCard.VaccineCardID;
            }
        }
    }
}
