

















































// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AOEAnswerModel, AOESelectListOptionModel } from '@/models/TestEntry';
import { AnswerTypeEnum, MessageSeverity, UnitOfMeasureEnum } from '@/models/Enums';
import DateInput from '@/components/testentry/aoeInputs/DateInput.vue';
import WeightInput from '@/components/testentry/aoeInputs/WeightInput.vue';
import TimeInput from '@/components/testentry/aoeInputs/TimeInput.vue';
import DateTimeInput from '@/components/testentry/aoeInputs/DateTimeInput.vue';
import FormMessage from '@/components/FormMessage.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';

@Component({
    components: { DateInput, DateTimeInput, FormMessage, TimeInput, WeightInput, NumberInput }
})
export default class AOEQuestion extends Vue {
    @Prop() questionID!: number;
    @Prop() answerType!: number;
    @Prop() questionText!: string;
    @Prop() isSubQuestion!: boolean;
    @Prop() answerValue!: string;
    @Prop() selectListOptions: AOESelectListOptionModel[];
    @Prop() displayValidation!: boolean;
    @Prop() validationError!: any;
    @Prop() numericalMin!: number;
    @Prop() numericalMax!: number;
    @Prop() customWarningMessage!: string;

    AnswerType = AnswerTypeEnum;
    MessageSeverityEnum = MessageSeverity;
    UnitOfMeasureEnum = UnitOfMeasureEnum;

    InputWarningMessage: string = null;
    IsToolTipDisplaying: boolean = false;

    UsesCustomWarningMessage: boolean = this.customWarningMessage != null && this.customWarningMessage.length > 0;

    created() {

    }

    get ShouldShow24HourNotice(): boolean {
        if (this.answerType == AnswerTypeEnum.DateTime24hr && this.answerValue != null) {
            let dateTime = new Date(this.answerValue);
            dateTime.setHours(dateTime.getHours() + 24);

            return dateTime.getTime() <= Date.now();
        } else {
            return false;
        }
    }

    CheckTimeRestrictionsAndUpdate(value: string) {
        this.InputWarningMessage = null;
        this.UpdateValue(value);
        if (value != null && value.length > 0) {
            let currentTime = new Date();
            let dateValue = this.answerType == AnswerTypeEnum.Time ? new Date(`${currentTime.toISOString().split('T')[0]} ${value}`).getTime() : new Date(value).getTime();
            let dateDifference = Math.ceil((dateValue - currentTime.getTime()) / (this.answerType == AnswerTypeEnum.Date ? 86400000 : 60000));

            if (this.numericalMin != null && dateDifference < this.numericalMin)
                this.InputWarningMessage = this.UsesCustomWarningMessage ? this.customWarningMessage : `Please confirm accuracy of the ${this.GetFormattedAnswerType(this.answerType)} below (Should be after: ${this.GetInputWarningMessageTime(this.numericalMin - 1, currentTime)})`;
            else if (this.numericalMax != null && dateDifference > this.numericalMax)
                this.InputWarningMessage = this.UsesCustomWarningMessage ? this.customWarningMessage : `Please confirm accuracy of the ${this.GetFormattedAnswerType(this.answerType)} below (Should be before: ${this.GetInputWarningMessageTime(this.numericalMax + 1, currentTime)})`;
        }
    }

    GetFormattedAnswerType(answerType: AnswerTypeEnum) {
        switch (answerType) {
            case AnswerTypeEnum.Date:
                return 'date';
            case AnswerTypeEnum.Time:
                return 'time';
            default:
                return 'date and time';
        }
    }

    GetInputWarningMessageTime(timeDifference: number, currentTime: Date) {
        timeDifference = timeDifference * (this.answerType == AnswerTypeEnum.Date ? 86400000 : 60000)
        var targetDate = new Date(currentTime.getTime() + timeDifference);

        switch (this.answerType) {
            case AnswerTypeEnum.Date:
                return this.$options.filters.FormatDateForLocalDateDisplay(targetDate);
            case AnswerTypeEnum.Time:
                return this.$options.filters.FormatTimeForDisplay(targetDate);
            default:
                return this.$options.filters.FormatDateForLocalDateTimeDisplay(targetDate);
        };
    }

    Update(event: any) {
        this.UpdateValue(event.target.value);
    }

    UpdateNumber(value: number) {
        this.UpdateValue(value == null ? null : value.toString());
    }

    UpdateValue(val: string) {
        this.$emit('Update', { QuestionID: this.questionID, Answer: val } as AOEAnswerModel);
    }
}
