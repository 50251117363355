






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { LoginDataModel, LoginResponseModel, PasswordResetRequestModel } from '@/models/Common';
import Browser from '@/utils/Browser';
import { ErrorReasonEnum, MessageSeverity } from '@/models/Enums';
import FormMessage from '@/components/FormMessage.vue';
import LoadingTransition from "@/components/LoadingTransition.vue";
import PasswordChange from "@/components/PasswordChange.vue";

@Component({
    components: { FormMessage, LoadingTransition, PasswordChange }
})
export default class PasswordReset extends Vue {
    @Prop() resetCode: string;

    IsResetCodeValid: boolean = false;
    NewPassword: string = null;
    IsValid: boolean = false;

    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;
    DisplaySupportLink: boolean = false;

    created() {
        if (this.resetCode == null) {
            this.BackToLogin();
        } else {
            this.CheckPasswordResetCode();
        }
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    SetFormMessage(message: string, severity: MessageSeverity, showSupportLink: boolean = false): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
        this.DisplaySupportLink = showSupportLink;
        Browser.SmoothScroll(0);
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
        this.DisplaySupportLink = false;
    }

    BackToLogin() {
        this.$router.push({ name: "Login" });
    }

    CheckPasswordResetCode() {
        Ajax.POST("/App/CheckPasswordResetCode",
            this.resetCode,
            (result: AjaxResponse) => {
                this.IsResetCodeValid = result.WasSuccess;
            },
            () => {
                this.IsResetCodeValid = false;
            });
    }

    ResetPassword() {
        Ajax.POST("/App/ResetPassword",
            { ResetCode: this.resetCode, NewPassword: this.NewPassword } as PasswordResetRequestModel,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    let loginData: LoginDataModel = { AuthToken: result.AuthToken, LoginData: result.Data as LoginResponseModel } as LoginDataModel;

                    this.$emit('Login', loginData);
                } else {
                    let errorCode = result.Data as ErrorReasonEnum;

                    switch (errorCode) {
                        case ErrorReasonEnum.ResubmitResetGuid:
                            this.IsResetCodeValid = false;
                            break;
                        default:
                            this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
                            break;
                    }
                }
            },
            () => {
                this.SetFormMessage(this.$store.state.ProcessingErrorMessage, MessageSeverity.HIGH, true);
            });
    }
}
