






// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ImageZoomDisplay extends Vue {
    @Prop() ImageSource: number;
    @Prop() ImageMaxHeight: number;

    ExpandImage() {
        this.$modal.show('messageModal', { Title: '', Image: this.ImageSource, CanClickToClose: true, DisplayOkayButton: false, IsLargeModal: true });
    }
}
