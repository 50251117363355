








// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
@Component
export default class Tooltip extends Vue {
    @Prop() content: string;
    @Prop() isLarge: boolean;
    @Prop() imgSrc!: string;

    get ImageSrc(): string {
        return (this.imgSrc != null && this.imgSrc != '') ? this.imgSrc : require('@/assets/Question.svg');
    }
}
