import DSScannerModel from './DSScanner';

//Define all namespaces as interfaces for window here
declare global {
    interface Window {
        DSScanner: DSScannerModel;
        DEF_WASM_PATH: string;
    }
}

window.DSScanner = window.DSScanner || null;
window.DEF_WASM_PATH = window.DEF_WASM_PATH || null;