















import { Component, Vue } from "vue-property-decorator";
import { ClientMetaDataModel, UserDataModel } from "@/models/Common";
import { TestEntryDataModel } from "@/models/TestEntry";
import { AppStateModel } from "@/store";
import { StringHasValue } from "@/utils/GlobalFunctions";
import ContactSupport from '@/components/ContactSupport.vue';

@Component({
    components: { ContactSupport }
})
export default class SupportModal extends Vue {
    Close() {
        this.$modal.hide('supportModal');
    }

    beforeOpen(event: any) {

    }
}
