







// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DateInput from '@/components/testentry/aoeInputs/DateInput.vue';
import TimeInput from '@/components/testentry/aoeInputs/TimeInput.vue';

@Component({
    components: { DateInput, TimeInput }
})
export default class DateTimeInput extends Vue {
    @Prop() datetime!: string;
    @Prop() isInvalid: boolean;

    LocalDate: string = null;
    LocalTime: string = null;

    created() {
        if (this.datetime != null) {
            let splitDateTime = this.datetime.split(" ");
            this.LocalDate = splitDateTime.length > 0 ? splitDateTime[0] : null;
            this.LocalTime = splitDateTime.length > 1 ? splitDateTime[1] : null;
        }
    }

    UpdateDateTime() {
        if (this.LocalDate == null || this.LocalTime == null) {
            this.$emit("update", null);
        } else {
            this.$emit('update', this.LocalDate + " " + this.LocalTime);
        }
    }
}
