











// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PhoneNumberInput extends Vue {
    @Prop() value!: string;
    @Prop() maxLength!: number;
    @Prop() placeholder: string;
    @Prop() isInvalid: boolean;
    @Prop() isDisabled: string;

    UpdateValue(event: any) {
        let newValue = event.target.value as string;

        //Don't accept letters and special chars besides ()-+
        if (/[a-zA-Z\!\@\#\$\%\^\&\*\_\=\[\{\]\}\\\|\;\:\'\"\,\<\.\>\/\?\`\~]/.test(newValue)) {
            (this.$refs.pni as HTMLInputElement).value = this.value;
            return;
        }

        if (String(newValue).length <= this.maxLength) {
            this.$emit("input", newValue);
            this.$emit("change"); //Useful for tacking on other callbacks
        }

        this.$forceUpdate();
    }
}
