

















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DemoVerificationTypeEnum, GenderEnum } from '@/models/Enums';
import { PersonModel } from '@/models/Common';
import { TestEntryPrefill } from '@/models/TestEntry';


import NumberInput from '@/components/inputs/NumberInput.vue';
import Tooltip from '@/components/Tooltip.vue';
import DemoVerificationDisplay from '@/components/DemoVerificationDisplay.vue';


@Component({
    components: { DemoVerificationDisplay, NumberInput, Tooltip }
})
export default class PersonSection extends Vue {
    @Prop() person: PersonModel;
    @Prop() errorObj: any;
    @Prop() isPersonUnder18: boolean;
    @Prop() isPatient: boolean; //Used for display demo verifications (only displayed for patient)
    @Prop() hasAcceptedSMSConsent: boolean;
    @Prop() hasAcceptedEmailConsent: boolean;
    @Prop() hasFormMessage: boolean;
    @Prop() showContactSection: boolean;
    @Prop() showRaceAndEthnicitySection: boolean;
    @Prop() showPatientIDSection: boolean;
    @Prop() prefill: TestEntryPrefill;

    Gender: any = GenderEnum;
    DemoVerificationTypeEnum: any = DemoVerificationTypeEnum;

    UpdateValue(fieldName: string, e: any) {
        this.EmitUpdatedValue(fieldName, e.target.value);
    }

    EmitUpdatedValue(fieldName: string, value: any) {
        let updateObj = {};
        (updateObj as any)[fieldName] = value;
        this.$emit("UpdatePerson", updateObj);
    }

    get DOBString() {
        return this.person != null ? this.person.DOB_Month + "/" + this.person.DOB_Day + "/" + this.person.DOB_Year : null;
    }

    get IsFirstNameLocked() {
        return this.prefill?.firstName?.length > 0;
    }

    get IsLastNameLocked() {
        return this.prefill?.lastName?.length > 0;
    }

    get IsGenderLocked() {
        return this.prefill?.gender?.length > 0;
    }

    get IsAddressLocked() {
        return this.prefill?.address?.length > 0;
    }

    get IsCityLocked() {
        return this.prefill?.city?.length > 0;
    }

    get IsStateLocked() {
        return this.prefill?.state?.length > 0;
    }

    get IsZipLocked() {
        return this.prefill?.zip?.length > 0;
    }

    get IsDOBLocked() {
        if (this.prefill?.dob?.length > 0) {
            let dateArray = this.prefill.dob.split("T")[0]?.split("-");

            return dateArray?.length == 3;
        } else {
            return false;
        }
    }

    get IsCellPhoneLocked() {
        return this.prefill?.cellPhone?.length > 0;
    }
}
