




















import { Component, Vue } from 'vue-property-decorator';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import BackButton from '@/components/BackButton.vue';
import PasswordChange from '@/components/PasswordChange.vue';

@Component({
    components: { BackButton, PasswordChange }
})
export default class SecuritySection extends Vue {
    IsChangingPassword: boolean = false;
    NewPassword: string = null;
    IsPasswordValid: boolean = false;

    ToggleIsChangingPassword(): void {
        this.NewPassword = null;
        this.IsPasswordValid = false;
        this.IsChangingPassword = !this.IsChangingPassword;
    }

    ChangePassword(): void {
        Ajax.POST("/App/ChangePassword",
            this.NewPassword,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.ToggleIsChangingPassword();

                    this.$modal.show('messageModal', { Title: 'Saved', Message: "Your password has been changed.", CanClickToClose: false, DisplayOkayButton: true });
                } else {
                    this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: 'Error', IsSupportMessage: true, CanClickToClose: false, DisplayOkayButton: true });
            },
            this.$store.getters.GenerateAuthHeaders
        );
    }
}
