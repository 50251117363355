




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BackButton extends Vue {
    @Prop() beforeFunction: Function;
    @Prop() overrideFunction: Function;

    GoBack(): void {
        if (this.overrideFunction != null) {
            this.overrideFunction();
            return;
        }

        if (this.beforeFunction != null) { this.beforeFunction(); }
        this.$router.go(-1);
    }
}
