




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NotificationBubble extends Vue {
    @Prop() count: number;
    @Prop() displayBlock: boolean;
    @Prop() forceDisplay: boolean; //Display despite value being zero or less

    get ShouldDisplay() {
        return this.count > 0 || this.forceDisplay === true;
    }
}
