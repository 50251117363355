
































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CardSideEnum, FeatureEnum } from '@/models/Enums';
import { PersonModel } from '@/models/Common';
import { InsuranceInfoModel } from '@/models/TestEntry';
import { Validation } from "vuelidate";
import CameraOverlay from '@/components/modals/CameraOverlay.vue';
import FeatureButton from '@/components/testentry/FeatureButton.vue';
import ImageZoomDisplay from '@/components/ImageZoomDisplay.vue';
import InsuranceSelection from '@/components/inputs/InsuranceSelection.vue';

@Component({
    components: { CameraOverlay, FeatureButton, ImageZoomDisplay, InsuranceSelection }
})
export default class InsuranceListSection extends Vue {
    @Prop() private insuranceList!: InsuranceInfoModel[];
    @Prop() private insuranceValidationList!: Validation[];
    @Prop() private isGuarantorSameAsGuardian!: boolean;
    @Prop() private isPatientUnder18!: boolean;
    @Prop() private patient!: PersonModel;
    @Prop() private guarantor!: PersonModel;
    @Prop() private guardian!: PersonModel;
    @Prop() private allowEmptyList: PersonModel;
    @Prop() private requireClientInfo!: boolean;
    @Prop() private canFreeTypeInsurances: boolean;

    CardSide: CardSideEnum = null;
    CurrentInsuranceIndex: number = null;
    InInsuranceCameraMode: boolean = false;

    get AllInsurancesComplete() {
        for (var i = 0; i < this.insuranceList.length; i++) {
            if ((this.insuranceList[i].InsuranceMasterID == null && this.insuranceList[i].InsuranceTradingPartnerID == null && this.canFreeTypeInsurances == false) || this.insuranceList[i].InsuranceMemberID == null || this.insuranceList[i].InsuranceMemberID == "")
                return false;
        }
        return true;
    }

    get HasMultipleInsurances() {
        return this.insuranceList.length > 1;
    }

    InsuranceIsComplete(insurance: InsuranceInfoModel) {
        return (insurance.InsuranceMasterID != null || insurance.InsuranceTradingPartnerID != null) && insurance.InsuranceMemberID != null && insurance.InsuranceMemberID != "";
    }

    ScanInsuranceCardFront(index: number) {
        this.CurrentInsuranceIndex = index;
        this.CardSide = CardSideEnum.Front;
        this.InInsuranceCameraMode = true;
    }

    ScanInsuranceCardBack(index: number) {
        this.CurrentInsuranceIndex = index;
        this.CardSide = CardSideEnum.Back;
        this.InInsuranceCameraMode = true;
    }

    CancelInsuranceCapture(imageData: string) {
        this.InInsuranceCameraMode = false;
        this.CurrentInsuranceIndex = null;
    }

    CaptureInsuranceCardImage(imageData: string) {
        if (this.CardSide == CardSideEnum.Front) {
            this.$emit('UpdateInsuranceData', this.CurrentInsuranceIndex, null, { InsuranceCardFrontImage: imageData } as InsuranceInfoModel);
        } else if (this.CardSide == CardSideEnum.Back) {
            this.$emit('UpdateInsuranceData', this.CurrentInsuranceIndex, null, { InsuranceCardBackImage: imageData } as InsuranceInfoModel);
        }

        this.InInsuranceCameraMode = false;
        this.CurrentInsuranceIndex = null;
    }

    UpdateInsuranceName(index: number, value: string) {
        this.$emit('UpdateInsuranceData', index, null, { InsuranceName: value } as InsuranceInfoModel);
    }

    UpdateInsuranceData(index: number, insMasterID: number, tpID: number) {
        this.$emit('UpdateInsuranceData', index, null, { InsuranceMasterID: insMasterID, InsuranceTradingPartnerID: tpID } as InsuranceInfoModel);
    }

    UpdateInsuranceMemberID(index: number, value: string) {
        this.$emit('UpdateInsuranceData', index, null, { InsuranceMemberID: value } as InsuranceInfoModel);
    }

    RemoveInsuranceFront(index: number) {
        this.$emit('UpdateInsuranceData', index, null, { InsuranceCardFrontImage: null, InsuranceCardFrontImageFileStorageID: null } as InsuranceInfoModel);
    }

    RemoveInsuranceBack(index: number) {
        this.$emit('UpdateInsuranceData', index, null, { InsuranceCardBackImage: null, InsuranceCardBackImageFileStorageID: null } as InsuranceInfoModel);
    }

    AddNewInsurance() {
        this.$emit('UpdateInsuranceData', this.insuranceList.length, null, null);
    }

    ChangeInsuranceCOB(index: number, newCOB: number) {
        this.$emit('UpdateInsuranceData', index, newCOB, null);
    }

    RemoveInsurance(index: number) {
        this.$emit('UpdateInsuranceData', index, null, null);
    }
}
