












import { Component, Vue } from 'vue-property-decorator';
import { AppStateModel } from "@/store";
import BackButton from '@/components/BackButton.vue';
import { CommunicationTypeEnum } from '@/models/Enums';
import { CommunicationPreferencesModel } from '@/models/Preferences';
import CommPreference from '@/components/CommPreference.vue';

@Component({
    components: { BackButton, CommPreference }
})
export default class CommunicationSection extends Vue {
    Email: string = null;
    CellPhone: string = null;
    CommunicationPreferences: CommunicationPreferencesModel = null;
    CommunicationTypeEnum: any = CommunicationTypeEnum;

    created() {
        let userData = (this.$store.state as AppStateModel).UserData;
        this.Email = userData.MembershipPerson.Person.EmailAddress;
        this.CellPhone = userData.MembershipPerson.Person.CellPhone;
        this.CommunicationPreferences = JSON.parse(JSON.stringify(userData.CommunicationPreferences)) as CommunicationPreferencesModel;
    }

    SetEmailPreference(preferenceValue: boolean) {
        this.CommunicationPreferences.SendEmail = preferenceValue;
        this.SavePreferences();
    }

    SetCellPhonePreference(preferenceValue: boolean, cellPhoneNumber: string) {
        this.CommunicationPreferences.SendTexts = preferenceValue;
        this.SavePreferences();

        if (cellPhoneNumber != null && cellPhoneNumber.length > 0) {
            this.CellPhone = cellPhoneNumber;
            this.SaveCellPhone();
        }
    }

    SavePreferences() {
        this.$store.commit('SetCommunicationPreferences', this.CommunicationPreferences);
    }

    SaveCellPhone() {
        this.$store.commit('SetCellPhone', this.CellPhone);
    }
}
