import Vue from "vue";
import { DirectiveBinding } from "vue/types/options";
import { VNode } from "vue/types/umd";

class FieldValidationBindingModel {
    fvm: any; //Field validation model
    canDisplayMessage: boolean;
    isInputGroup: boolean;
}

const executeValidationChecks = (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
    let validationDisplayDiv: HTMLDivElement = null;
    let params = binding.value as FieldValidationBindingModel;

    if (params.fvm.$error) {
        el.classList.add("invalid");
    } else {
        el.classList.remove("invalid");
    }

    if (binding.arg === "parent") {
        validationDisplayDiv = el.parentElement.nextSibling as HTMLDivElement;
    } else {
        validationDisplayDiv = el.nextSibling as HTMLDivElement;
    }

    if (validationDisplayDiv != null && params.canDisplayMessage) {
        if (params.fvm.specialCharBlacklist === false) {
            validationDisplayDiv.textContent = "^ ~ \\ & | are not allowed";
            validationDisplayDiv.style.display = "block";
        } else if (params.fvm.phoneNumber === false) {
            validationDisplayDiv.textContent = "Invalid Phone Number";
            validationDisplayDiv.style.display = "block";
        } else if (params.fvm.email === false) {
            validationDisplayDiv.textContent = "Invalid Email";
            validationDisplayDiv.style.display = "block";
        } else if (params.fvm.alphaNum === false) {
            validationDisplayDiv.textContent = "Alphanumeric Only";
            validationDisplayDiv.style.display = "block";
        } else if (params.fvm.minLength === false) {
            let minLengthParam = params.fvm.$params.minLength.min;
            validationDisplayDiv.textContent = minLengthParam != null ? "Minimum Length of " + minLengthParam : "Minimum Length Not Met";
            validationDisplayDiv.style.display = "block";
        } else if (params.fvm.maxLength === false) {
            let maxLengthParam = params.fvm.$params.maxLength.max;
            validationDisplayDiv.textContent = maxLengthParam != null ? "Maximum Length of " + maxLengthParam : "Maximum Length Exceeded";
            validationDisplayDiv.style.display = "block";
        } else if (params.fvm.startsWith === false) {
            let startsWithParam = params.fvm.$params.startsWith.prefix;
            validationDisplayDiv.textContent = "Must Start with Prefix: " + startsWithParam;
            validationDisplayDiv.style.display = "block";
        } else {
            validationDisplayDiv.style.display = "none";
        }
    } else {
        validationDisplayDiv.style.display = "none";
    }
};

//v-field-validation:[parent]="{ fvm: $v.path.to.model, canDisplayMessage: true/false }"
Vue.directive("field-validation", {
    inserted: (el, binding, vnode) => {
        let validationElement = document.createElement("div");
        validationElement.className = "inputValidationError";
        validationElement.style.display = "none";

        //Insert validation message to adjacent to parent if directive argument specifies
        if (binding.arg === "parent") {
            el.parentElement.insertAdjacentElement("afterend", validationElement);
        } else {
            el.insertAdjacentElement("afterend", validationElement);
        }

        if ((binding.value as FieldValidationBindingModel).isInputGroup) {
            el.classList.add("inputGroup");
        }

        executeValidationChecks(el, binding, vnode);
    },
    update: executeValidationChecks
});