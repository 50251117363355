export class LinkAccountModel {
    PatientGuid: string;
    DOB_Month: string;
    DOB_Day: string;
    DOB_Year: string;
    Zip: string;
    Lastname: string;

    constructor() {
        this.PatientGuid = null;
        this.DOB_Month = null;
        this.DOB_Day = null;
        this.DOB_Year = null;
        this.Zip = null;
        this.Lastname = null;
    }
}