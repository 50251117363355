import { PersonModel } from "./Common";
import { VaccineManufacturerEnum, VaccineTypeEnum } from "./Enums";

export class VaccineCardModel {
    VaccineCardID: number;
    VaccineType: VaccineTypeEnum;
    VaccineDoseList: VaccineDoseModel[];
    HasImagesToLoad: boolean;
    Patient: PersonModel;

    //The following is filled in by the UI via ajax calls. We store the data here as a cache
    QRCode: string;

    constructor() {
        this.VaccineCardID = null;
        this.VaccineType = VaccineTypeEnum.COVID19;
        this.VaccineDoseList = [new VaccineDoseModel()];
        this.HasImagesToLoad = false; //Starts as false because new vaccine models have all their images loaded as part of the new vaccine process
        this.Patient = new PersonModel();

        this.QRCode = null;
    }
}

export class VaccineDoseModel {
    VaccineDoseID: number;
    VaccinationManufacturer: VaccineManufacturerEnum;
    VaccinationLotNumber: string;
    Vaccination_Month: string;
    Vaccination_Day: string;
    Vaccination_Year: string;

    //The following is filled in by the UI via ajax calls. We store the data here as a cache
    FrontImage: string;
    BackImage: string;

    constructor() {
        this.VaccineDoseID = null;
        this.VaccinationManufacturer = null;
        this.VaccinationLotNumber = null;
        this.Vaccination_Month = null;
        this.Vaccination_Day = null;
        this.Vaccination_Year = null;

        this.FrontImage = null;
        this.BackImage = null;
    }
}