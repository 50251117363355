










import { Component, Vue } from 'vue-property-decorator';
import LoadingTransition from "@/components/LoadingTransition.vue";

@Component({
    components: { LoadingTransition }
})
export default class TestEntryLanding extends Vue {

    created() {
        if (this.$store.getters.IsSignedIn) {
            this.$router.push({ path: this.$router.currentRoute.path + '/verify', query: this.$router.currentRoute.query });
        }
    }

    NavigateSignIn(): void {
        //Login with deeplink in hash back to this page once they are finished
        this.$router.push({ path: '/', hash: this.$router.currentRoute.fullPath });
    }

    NavigateCreateAccount(): void {
        //New Account with deeplink in hash back to this page once they are finished
        this.$router.push({ path: '/newaccount', hash: this.$router.currentRoute.fullPath });
    }

    NavigateAsGuest(): void {
        let currentPath = this.$router.currentRoute.path;

        if (currentPath[currentPath.length - 1] == "/") {
            currentPath = currentPath.substring(0, currentPath.length - 1);
        }

        //Continue as guest
        this.$router.push({ path: currentPath + '/verify', query: this.$router.currentRoute.query });
    }
}
