import { ProductTypeEnum } from './Enums';
import { CommunicationPreferencesModel } from './Preferences';
import { InsuranceInfoModel } from './TestEntry';
import { LegalAgreementModel } from './TOS';
import { VaccineCardModel } from './VaccineCards';

export class ConfigDataModel {
    ReCaptchaKey: string;
    SessionWarningTimeout: number;
    SessionTerminateTimeout: number;
    SupportPhoneNumber: string;
    SupportEmailAddress: string;
    InsightsInstrumentationKey: string;
    PublicVapidKey: string;
    DataSymbolBarcodeReaderKey: string;
    DefaultEmailAddress: string;
    DefaultSMSNumber: string;
    ReadiPassURL: string;
}

export class LoginDataModel {
    AuthToken: string;
    LoginData: LoginResponseModel;
}

export class LoginResponseModel {
    DependentFirstName: string; //Only populated when there is an account to link after logging in

    MembershipPerson: MembershipPerson;
    PatientList: PatientModel[];
    ProfileList: ProfileModel[];
    ClientList: ClientMetaDataModel[];
    ProductAlerts: ProductAlertModel[];
    BannerMessages: BannerMessageModel[];
    TermsOfServiceList: LegalAgreementModel[];
    CommunicationPreferences: CommunicationPreferencesModel;
}

export class UserDataModel {
    DependentFirstName: string; //Only populated when there is an account to link after logging i
    MembershipPerson: MembershipPerson;
    PatientList: PatientModel[];
    ProfileList: ProfileModel[];
    ClientList: ClientMetaDataModel[];
    ProductAlerts: ProductAlertModel[];
    BannerMessages: BannerMessageModel[];
    TermsOfServiceList: LegalAgreementModel[];
    CommunicationPreferences: CommunicationPreferencesModel;

    VaccineCardList: VaccineCardModel[];
    HasLoadedVaccineCardList: boolean;

    constructor(loginResponse: LoginResponseModel) {
        this.DependentFirstName = loginResponse.DependentFirstName;
        this.MembershipPerson = loginResponse.MembershipPerson;
        this.PatientList = loginResponse.PatientList;
        this.ProfileList = loginResponse.ProfileList;
        this.ClientList = loginResponse.ClientList;
        this.ProductAlerts = loginResponse.ProductAlerts;
        this.BannerMessages = loginResponse.BannerMessages;
        this.TermsOfServiceList = loginResponse.TermsOfServiceList;
        this.CommunicationPreferences = loginResponse.CommunicationPreferences;


        this.VaccineCardList = [];
        this.HasLoadedVaccineCardList = false;
    }
}

export class MembershipPerson {
    MembershipID: number;
    Person: PersonModel;
}

export class ProfileModel
{
    Person: PersonModel;
    InsuranceList: InsuranceInfoModel[];
    EmailConsent: boolean;
    SMSConsent: boolean;

    constructor() {
        this.Person = new PersonModel();
        this.EmailConsent = false;
        this.SMSConsent = false;
        this.InsuranceList = [];
    }
}

export class PersonModel {
    PersonID: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    DOB_Month: string;
    DOB_Day: string;
    DOB_Year: string;
    Gender: string;
    Ethnicity: string;
    Race: string;
    CellPhone: string;
    EmailAddress: string;

    constructor() {
        this.PersonID = null;
        this.FirstName = null;
        this.MiddleName = null;
        this.LastName = null;
        this.Address = null;
        this.City = null;
        this.State = null;
        this.Zip = null;
        this.DOB_Month = null;
        this.DOB_Day = null;
        this.DOB_Year = null;
        this.Gender = null;
        this.Ethnicity = null;
        this.Race = null;
        this.CellPhone = null;
        this.EmailAddress = null;
    }
}

export class PatientModel {
    PatientID: number;
    PatientGuid: string;
    Person: PersonModel;
}

export class ClientMetaDataModel {
    ID: number;
    Name: string;
    LatestEncounterDate: string;
    SupportEmailAddress: string;
    SupportPhoneNumber: string;
    EnabledProducts: ProductTypeEnum[];
}

export class BannerMessageModel {
    ClientID: number;
    Text: string;
    Url: string;
}

export class ProductAlertModel {
    ProductType: ProductTypeEnum;
    Count: number;
}

export class PasswordResetRequestModel {
    ResetCode: string;
    NewPassword: string;
}

export class RefreshLoginDataRequestModel {
    PatientGuid: string;
}

export class TabModel {
    ID: number|string;
    Name: string;
}

export class IDNamePairModel<T> {
    ID: T;
    Name: string;
}