













import { Component, Prop, Vue } from 'vue-property-decorator';
import BackButton from '@/components/BackButton.vue';
import QRCode from '@/components/QRCode.vue';

@Component({
    components: { BackButton, QRCode }
})
export default class VaccineCards extends Vue {
    @Prop() vaccineCardID: number;
    @Prop() qrCode: string;

    created() {
        //TODO: Brian - Add fetching?
    }

    get DataToEncode() {
        return this.$store.state.ReadiPassURL != null ? (this.$store.state.ReadiPassURL + 'Scanner?qrCode=' + this.qrCode) : this.qrCode;
    }
}
