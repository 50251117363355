


























import { Component, Vue } from "vue-property-decorator";
import { TestEntryResultModel } from '@/models/TestEntry';
import { TestResultEnum } from '@/models/Enums';
import LoadingTransition from "@/components/LoadingTransition.vue";

@Component({
    components: { LoadingTransition }
})
export default class TestResultUploadModal extends Vue {
    TestResults: TestEntryResultModel[] = [];
    TestResultEnum: any = TestResultEnum;

    created() {

    }

    Close() {
        this.$modal.hide('testResultUploadModal');
        this.TestResults = [];
    }

    ToggleTestResult(testTypeID: number, value: TestResultEnum): void {
        let testResult = this.TestResults.find((tr) => { return tr.TestTypeID == testTypeID; });
        testResult.Result = testResult.Result == value ? null : value;
    }

    UploadTestResult() {
        this.$emit('UpdateTestResults', this.TestResults);
        this.Close();
    }

    beforeOpen(event: any) {
        this.TestResults = JSON.parse(JSON.stringify(event.params.TestResults)) as TestEntryResultModel[];
    }
}
