

















import { Component, Vue } from 'vue-property-decorator';
import BackButton from '@/components/BackButton.vue';
import Browser from '@/utils/Browser';
import { MessageSeverity } from '@/models/Enums';
import FormMessage from "@/components/FormMessage.vue";
import Checkbox from "@/components/inputs/Checkbox.vue";

@Component({
    components: { BackButton, Checkbox, FormMessage }
})
export default class FeaturesSection extends Vue {
    //PWA
    IsInPWA: boolean = false;
    BrowserIsChromium: boolean = false;
    BrowserIsSafari: boolean = false;
    HasInstallPrompt: boolean = false;

    MessageSeverity: any = MessageSeverity;

    created() {
        this.IsInPWA = window.matchMedia('(display-mode: standalone)').matches;
        this.BrowserIsChromium = Browser.IsBrowser_Chromium();
        this.BrowserIsSafari = Browser.IsBrowser_Safari();
        this.HasInstallPrompt = Browser.PWAInstallPrompt != null;
    }

    get IsPWAInstalled(): boolean {
        return this.IsInPWA || (this.BrowserIsChromium && !this.HasInstallPrompt);
    }

    RequestPWAInstall(): void {
        if (this.HasInstallPrompt) {
            Browser.RequestPWAInstallation().then(isInstalled => {
                this.HasInstallPrompt = !isInstalled;
                this.IsInPWA = window.matchMedia('(display-mode: standalone)').matches; //Recheck if we are in the PWA
            })
        }
    }
}
