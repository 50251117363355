

























// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MessageSeverity } from '@/models/Enums';
import FormMessage from '@/components/FormMessage.vue';

@Component({
    components: { FormMessage }
})
export default class Signature extends Vue {
    @Prop() requiresHandSignature!: boolean;
    @Prop() signature!: string;
    @Prop() signatureImage!: string;
    @Prop() currentDate!: string;
    @Prop() isInvalid!: boolean;
    @Prop() isDisabled!: string;

    MessageSeverity: any = MessageSeverity;

    OpenSignatureCapture(): void {
        this.$emit('OpenSignatureCapture');
    }
}
