








// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import NumberInput from '@/components/inputs/NumberInput.vue';

@Component({
    components: { NumberInput }
})
export default class WeightInput extends Vue {
    @Prop() time!: string;
    @Prop() isInvalid: boolean;

    Hours: number = null;
    Minutes: number = null;

    created() {
        if (this.time != null) {
            let timeArray = this.time.split(":");

            if (timeArray.length == 2) {
                this.Hours = parseInt(timeArray[0]);
                this.Minutes = parseInt(timeArray[1]);
            }
        }
    }

    UpdateTime() {
        if (isNaN(parseInt(this.Hours as any)) || isNaN(parseInt(this.Minutes as any))) {
            this.$emit("update", null);
        } else {
            this.$emit("update", this.Hours + ":" + this.Minutes + ":00");
        }
    }
}
