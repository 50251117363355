import { BrowserPermissionEnum } from '../models/Enums';

export default class Browser {
    private static UserAgent: string = window.navigator != null && window.navigator.userAgent != null ? window.navigator.userAgent.toLowerCase() : "";
    public static PWAInstallPrompt: Event = null;

    private static Initialize = (() => {
        if (window.navigator != null && 'serviceWorker' in window.navigator) {
            //Store the prompt
            window.addEventListener('beforeinstallprompt', (e) => {
                //The event from this listener isn't in TS yet. Look here for API: https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent

                //Prevent browser install browser
                e.preventDefault();

                //Stash the event so it can be triggered later.
                Browser.PWAInstallPrompt = e;
            });
        }
    })();

    public static SmoothScroll(topPosition: number): void {
        var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
        if (isSmoothScrollSupported) {
            window.scrollTo({ top: topPosition, behavior: 'smooth' });
        } else {
            window.scrollTo(0, 0);
        }
    }

    public static IsPlatform_IOS(): boolean {
        return !!navigator.platform && /iPad|iPhone|iPod|MacIntel/.test(navigator.platform);
    }

    public static IsBrowser_Chromium(): boolean {
        return Browser.UserAgent.indexOf('chrome') > -1;
    }

    public static IsBrowser_Safari(): boolean {
        return Browser.UserAgent.indexOf('safari') > -1 && Browser.UserAgent.indexOf('chrome') == -1 && Browser.UserAgent.indexOf('crios') == -1;
    }

    public static async HasCamera(): Promise<boolean> {
        let md = navigator.mediaDevices;

        if (!md || !md.enumerateDevices) {
            return false;
        }

        return (await md.enumerateDevices()).some(device => 'videoinput' === device.kind);
    }

    public static CheckCameraPermission(): Promise<BrowserPermissionEnum> {
        return new Promise((resolve) => {
            if (!navigator || !navigator.permissions) {
                resolve(BrowserPermissionEnum.NotQueryable);
            }

            navigator.permissions.query({ name: 'camera' }).then(result => {
                resolve(result.state as BrowserPermissionEnum);
            }).catch(e => {
                resolve(BrowserPermissionEnum.NotQueryable);
            });
        });
    }

    public static RequestCameraPermission(): Promise<BrowserPermissionEnum> {
        return new Promise((resolve) => {
            if (!navigator || !navigator.mediaDevices) {
                resolve(BrowserPermissionEnum.NotQueryable);
            }

            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    stream.getTracks().forEach(stream => { stream.stop(); }); //stop the streams so the camera is not in use.
                    resolve(BrowserPermissionEnum.Granted);
                })
                .catch((error) => {
                    if ((error.name == 'NotAllowedError')) {
                        resolve(BrowserPermissionEnum.Denied);
                    } else if (error.name == 'PermissionDismissedError') {
                        resolve(BrowserPermissionEnum.Prompt);
                    }
                });
        });
    }

    public static RequestPWAInstallation(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            //Show the system prompt
            (Browser.PWAInstallPrompt as any)["prompt"](); //TODO: Brian - Not in TS yet

            //Wait for the user to respond to the prompt
            (Browser.PWAInstallPrompt as any)["userChoice"].then((choiceResult: any) => { //TODO: Brian - Not in TS yet
                //Browser.PWAInstallPrompt = null;

                resolve(choiceResult.outcome === 'accepted');
            });
        });
    }
}