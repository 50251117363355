











// @ is an alias to /src
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ConsentAgreementModel, ConsentConfigModel } from '@/models/TestEntry';
import { FileDownloadModel } from '@frhc/scripts/TS/FileModels';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import Checkbox from '@/components/inputs/Checkbox.vue';
import { ConvertAndOpenFile } from '@frhc/scripts/TS/Utilities';

@Component({
    components: { Checkbox }
})
export default class PatientConsentSection extends Vue {
    @Prop() testID: string;
    @Prop() activationCode: string;
    @Prop() testGuid: string;
    @Prop() consentAgreementList!: ConsentAgreementModel[];
    @Prop() consentConfigList!: ConsentConfigModel[];
    @Prop() isInError: boolean;

    created() {

    }

    IsConsented(consentConfigID: number) {
        let consent = this.consentAgreementList.find((c) => { return c.ConsentConfigID == consentConfigID; });
        return consent != null ? consent.Accepted : false;
    }

    OpenFormFile(formID: number) {
        Ajax.POST("/LabTest/DownloadConsentForm",
            { TestID: this.testID, ActivationCode: this.activationCode, TestGuid: this.testGuid, FileID: formID },
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    ConvertAndOpenFile(result.Data as FileDownloadModel);
                } else {
                    this.$modal.show('messageModal', { Title: "Error", Message: "There was an issue retrieving the requested file.", CanClickToClose: false, DisplayOkayButton: true });
                }
            },
            () => {
                this.$modal.show('messageModal', { Title: "Error", Message: "There was an issue retrieving the requested file.", CanClickToClose: false, DisplayOkayButton: true });
            },
            null
        );
    }

    ToggleConsent(consentID: number) {
        //Have parent flip the flag for this consent id
        this.$emit('ToggleConsent', consentID);
    }
}
