






































import { Component, Vue } from "vue-property-decorator";
import { Validations } from 'vuelidate-property-decorators';
import { maxLength, required } from 'vuelidate/lib/validators';
import { emailCaseInsensitive, specialCharBlacklist } from '@/utils/CustomValidations';
import { Ajax, AjaxResponse } from '@frhc/scripts/TS/Ajax';
import { MessageSeverity } from '@/models/Enums';
import FormMessage from "@/components/FormMessage.vue";
import LoadingTransition from "@/components/LoadingTransition.vue";

@Component({
    components: { FormMessage, LoadingTransition }
})
export default class ForgotPasswordModal extends Vue {
    Email: string = null;
    RequestSent: boolean = false;
    RequestError: boolean = false;
    FormMessage: string = null;
    FormMessageSeverity: MessageSeverity = null;

    created() {
        
    }

    @Validations()
    validations() {
        return {
            Email: { required, specialCharBlacklist, email: emailCaseInsensitive, maxLength: maxLength(120) },
        }
    }

    get CanSendForgotPasswordRequest(): boolean {
        return this.Email != null && this.Email.length > 0;
    }

    get HasFormMessage() {
        return this.FormMessage != null && this.FormMessage.length > 0;
    }

    SetFormMessage(message: string, severity: MessageSeverity): void {
        this.FormMessage = message;
        this.FormMessageSeverity = severity;
    }

    ClearFormMessage(): void {
        this.FormMessage = null;
        this.FormMessageSeverity = null;
    }

    Close() {
        this.$modal.hide('forgotPasswordModal');
        this.Email = null;
        this.RequestSent = false;
        this.RequestError = false;
    }

    SendForgotPasswordRequest() {
        this.ClearFormMessage();

        this.$v.$touch();

        if (this.$v.$anyError) {
            this.SetFormMessage("Please correct the issues below", MessageSeverity.HIGH);
            return;
        }

        this.Email = this.Email.trim();

        Ajax.POST("/App/ForgotPassword",
            this.Email,
            (result: AjaxResponse) => {
                if (result.WasSuccess) {
                    this.RequestSent = true;
                } else {
                    this.RequestError = true;
                }
            },
            () => {
                this.RequestError = true;
            });
    }

    private OpenSupportModal() {
        this.$modal.show('supportModal');
    }

    beforeOpen(event: any) {
        this.Email = event.params.Email;
        this.ClearFormMessage();
        this.$v.$reset();
    }
}
