








import { Component, Vue } from "vue-property-decorator";

@Component
export default class NetworkBanner extends Vue {
    IsOnline: boolean = true;
    DisplayBanner: boolean = false;
    DisplayBannerTimerID: number = 0;

    mounted() {
        this.IsOnline = navigator != null ? navigator.onLine : true;

        window.addEventListener("online", this.HandleNetworkChange);
        window.addEventListener("offline", this.HandleNetworkChange);
    }

    HandleNetworkChange(event: any) {
        let originalStatus = this.IsOnline;

        this.IsOnline = navigator != null ? navigator.onLine : true;

        //Only trigger on change (in case the same network event gets triggered twice in a row)
        if (originalStatus != this.IsOnline) {

            //We set display to false and delay setting it to true to trigger re-animation.
            this.DisplayBanner = false;

            window.setTimeout(() => {
                this.DisplayBanner = true;

                window.clearTimeout(this.DisplayBannerTimerID);
                this.DisplayBannerTimerID = window.setTimeout(() => { this.DisplayBanner = false; }, 4000);
            });
        }
    }

}
